import React, { useContext } from 'react'
import { ContextoAlmacen } from '../../contexts/Almacen/AlmacenContext'
import TabAdminPos from './components/Tab/TabAdminPos'
import styles from './AdminPosiciones.module.css'
import BarraLateral from '../Almacen/components/BarraLateral/BarraLateral'

const AdminPosiciones = () => {
  const { warehouse } = useContext(ContextoAlmacen)

  return (
    <div className={`${styles.container} ${warehouse.dark ? styles.containerDark : styles.containerWhite} `}>
      <div>
        <TabAdminPos />
      </div>
      <BarraLateral all={false} />
    </div>
  )
}

export default AdminPosiciones