import { createAsyncThunk } from "@reduxjs/toolkit"
import { getAutonomoCargaConfig, getAutonomoDescargaConfig } from "../../../../services/services"

export const getConfigCargaThunk = createAsyncThunk('autonomo/getCargaConfig',
    async () => {
        return await getAutonomoCargaConfig()
    })

export const getConfigDescargaThunk = createAsyncThunk('autonomo/getDescargaConfig',
    async () => {
        return await getAutonomoDescargaConfig()
    })