import { ResponsivePie } from "@nivo/pie";
import { useSelector, useDispatch} from 'react-redux'
import {getDataMovimientosThunk} from '../redux/slices/movimientosSlice/thunk/getDataMovimientosThunk'
import { useContext, useEffect } from "react";
import {commonProperties, generateOptionGroups } from "../../../utils/dashboard";
import { ContextoAlmacen } from "../../../contexts/Almacen/AlmacenContext";
import { tokens } from "../../../theme";
import CenteredMetric from "./CenteredMetric";



const PieMovements = () => {

    const { warehouse } = useContext(ContextoAlmacen);
    const colors = tokens(warehouse.dark);

    const agregarColorSegunMovimientoPie = (arr = [], campo) =>{
        if (arr.length === 0) {
            return;
        }
        
        let color;
      
        arr.forEach(elemento => {
      
            switch (elemento[campo]) {
                case "entrada":
                    color = colors.verdes[300]
                    break;
        
                case "salida":
                  color = colors.verdes[200]
                break;
        
                case "reubicaciones":
                  color = colors.blueAccent[300]
                break;

                case "rechazo" || "rechazado":
                  color = colors.red[400]
                break;
            
                default:
                    //console.log("agregarColorSegunMovimientoPie: no existe el elemento " + arr.campo)
                break;
            }    
      
            elemento["color"] = color;
        });
      
        return arr;
    }

  const dispatch = useDispatch();
   
  useEffect(() => {
    dispatch(getDataMovimientosThunk()) //eslint-disable-next-line
  }, [])
  
  

  const  historial = useSelector((state) => state.movimientosData.data);
  //Filtramos los movimientos que no vamos a utilizar
  const historialFiltrado = historial.filter((h) => h.operation !== "guardado" && h.operation !== "trans" && h.operation !== "posicion entrada" && h.operation !== "edicion manual" );

  let historialPie = generateOptionGroups(historialFiltrado, "operation");
  historialPie = agregarColorSegunMovimientoPie(historialPie, "id")

  return (
    <ResponsivePie
     {...commonProperties}
        arcLinkLabel={d => `${d.id} (${d.formattedValue})`}
        activeInnerRadiusOffset={commonProperties.activeOuterRadiusOffset}
        innerRadius={0.8}
        data={historialPie || []}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        activeOuterRadiusOffset={11}
        borderWidth={1}
        colors={d => d.data.color}
        colorBy="index"
        theme={{
         tooltip: {
          color: colors.grey[100],
          container:{
            background: colors.primary[900]
          }
         },
          legends: {
            text: {
              fill: colors.grey[100],
            },
          },
       
        }}
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]} 
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        //enableArcLinkLabels={false}
        arcLinkLabelsTextColor={colors.grey[100]}
        arcLinkLabelsOffset={-12}
        arcLinkLabelsDiagonalLength={36}
        arcLinkLabelsStraightLength={32}
        arcLinkLabelsThickness={3}
        arcLinkLabelsColor={{ from: 'color', modifiers: [] }} 
        enableArcLabels={false}
        arcLabelsRadiusOffset={0.15}
        arcLabelsSkipAngle={7}
         legends={[
          {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 1,
              translateY: 73,
              itemWidth: 147,
              itemHeight: 10,
              itemsSpacing: 0,
              symbolSize: 22,
              itemDirection: 'left-to-right',
          }
      ]}
    />  
  );
};

export default PieMovements;