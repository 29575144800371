import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import AdminPosicionesTable from '../Table/AdminPosicionesTable'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { texts } from '../../../../i18n'
import AdminSKU from '../../AdminSKU'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const TabAdminPos = () => {
  const [value, setValue] = useState(0)
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='tablas' textColor='inherit'>
          <Tab label={texts[lang]['MENU_ADM']} {...a11yProps(0)} />
          <Tab label={texts[lang]['MENU_ADM_SKU']} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AdminPosicionesTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AdminSKU />
      </TabPanel>
    </>
  )
}

export default TabAdminPos
