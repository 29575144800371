import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { tokens } from '../../../theme'
import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext'
import { ImEnter, ImExit } from 'react-icons/im'
import { ChangeCircleSharp, StopCircle, Summarize } from '@mui/icons-material'
import { texts } from '../../../i18n'
const StatBoxMoves = ({ titulo, entradas, salidas, reub, rechazos, total }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  const colors = tokens(warehouse.dark)
  return (
    <Box width='100%' m='0 30px'>
      <Box display='column'>
        <Typography variant='h4' sx={{ color: colors.verdes[200], textAlign: 'center' }} marginBottom='4px'>
          {titulo}
        </Typography>

        <Box display='flex' sx={{ textAlign: 'center' }} gap='6px'>
          <Typography flex='3' variant='h5' sx={{ color: colors.verdes[300], mr: 2 }} gap='5px'>
            <Typography variant='h5' sx={{ color: colors.verdes[300] }}>
              {texts[lang]['ICON_TEXTS_E']}
            </Typography>
            <ImEnter />
            <Typography variant='h5' sx={{ color: colors.primary[100] }} mt='5px'>
              {entradas}
            </Typography>
          </Typography>
          <Typography flex='3' variant='h5' sx={{ color: colors.verdes[300], mr: 2 }}>
            <Typography variant='h5' sx={{ color: colors.verdes[300] }}>
              {texts[lang]['ICON_TEXTS_S']}
            </Typography>
            <ImExit />
            <Typography variant='h5' sx={{ color: colors.primary[100] }} mt='5px'>
              {salidas}
            </Typography>
          </Typography>
          <Typography flex='3' variant='h5' sx={{ color: colors.verdes[300], mr: 2 }}>
            <Typography variant='h5' sx={{ color: colors.verdes[300] }}>
              {texts[lang]['ICON_TEXTS_R']}
            </Typography>
            <ChangeCircleSharp />
            <Typography variant='h5' sx={{ color: colors.primary[100] }} mt='5px'>
              {reub}
            </Typography>
          </Typography>
          <Typography flex='3' variant='h5' sx={{ color: colors.verdes[300], mr: 2 }}>
            <Typography variant='h5' sx={{ color: colors.verdes[300] }}>
              {texts[lang]['ICON_TEXTS_RJ']}
            </Typography>
            <StopCircle />
            <Typography variant='h5' sx={{ color: colors.primary[100] }} mt='5px'>
              {rechazos}
            </Typography>
          </Typography>
          <Typography flex='3' variant='h5' sx={{ color: colors.verdes[300], mr: 2 }}>
            <Typography variant='h5' sx={{ color: colors.verdes[300] }}>
              Total
            </Typography>
            <Summarize />
            <Typography variant='h5' sx={{ color: colors.primary[100] }} mt='5px'>
              {total}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default StatBoxMoves
