import { Box } from '@mui/material'
import PieSlots from '../../components/PieSlots'
import Header from '../../components/Header'
import PieMovements from '../../components/PieMovements'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { texts } from '../../../../i18n'

const Pie = ({ estado }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  return (
    <>
      <Box m='20px'>
        {estado ? (
          <>
            <Header title={texts[lang]['TITLE_GRAP_C_F']} subtitle={texts[lang]['SUBT_GRAP_C_F']} />
            <PieSlots />
          </>
        ) : (
          <>
            <Header title='Movimientos' subtitle='Visualização completa dos movimentos' />
            <PieMovements />
          </>
        )}
      </Box>
    </>
  )
}

export default Pie
