import { createSlice } from "@reduxjs/toolkit"
import { getPalletsThunk } from "./thunk/getPalletsThunk"


const adminPosSlice = createSlice({
    name: 'admin-posiciones',
    initialState: {
        request: {
            requestId: null,
            loading: 'idle',
            error: null
        },
        pallets: null

    },
    reducers: {
    },

    extraReducers: builder => {
        // Get and Set Positions Object 
        builder.addCase(getPalletsThunk.fulfilled, (state, action) => {
            const { requestId } = action.meta
            if (state.request.loading === 'pending' && state.request.currentRequestId === requestId) {
                state.request.loading = 'idle'
                state.pallets = action.payload
            }
        })
        builder.addCase(getPalletsThunk.pending, (state, action) => {
            if (state.request.loading === 'idle') {
                state.request.loading = 'pending'
                state.request.currentRequestId = action.meta.requestId
            }
        })
        builder.addCase(getPalletsThunk.rejected, (state, action) => {
            const { requestId } = action.meta
            if (state.request.loading === 'pending' && state.request.currentRequestId === requestId) {
                state.request.loading = 'idle'
                state.request.error = action.error
                state.request.currentRequestId = undefined
            }
        })

    }

})



export const { setValueDays, setLeft, setRight } = adminPosSlice.actions
export default adminPosSlice.reducer