import React from 'react'
import { useContext, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { Alert, IconButton } from '@mui/material'
import { RemoveCircle } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { getFilterDataThunk, getFilterDateThunk } from '../../redux/slices/filters/thunk/filterThunk'
import { resetFilters } from '../../redux/slices/filters/filtersSlice'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import styles from './Filtros.module.css'
import { texts } from '../../../../i18n'

const Filtros = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  const [filtroElegido, setFiltroElegido] = useState('')
  const dispatch = useDispatch()
  const { filter, error } = useSelector((state) => state.filter)

  // ------------ SET FILTER -----------------
  const setFilter = (e) => {
    const property = e.target.value
    if (property) {
      setFiltroElegido(property)
      if (property === 'vencimiento') {
        return dispatch(getFilterDateThunk({ type: 'dias_hasta', prop: property }))
      }
      if (property === 'ingreso') {
        return dispatch(getFilterDateThunk({ type: 'dias_desde', prop: property }))
      }
      setFiltroElegido(property)
      dispatch(getFilterDataThunk({ type: 'clave_color', prop: property }))
    } else {
      setFiltroElegido('')
      dispatch(resetFilters())
    }
  }

  return (
    <>
      <Form.Select
        size='sm'
        style={{ width: 'fit-content' }}
        onChange={setFilter}
        className={warehouse.dark && styles.formDark}
      >
        <option value=''> {texts[lang]['LAT_FILTRO_S']}</option>
        <option value='producto'> {texts[lang]['LAT_FILTRO_P']}</option>
        <option value='vencimiento'>{texts[lang]['LAT_FILTRO_V']}</option>
        <option value='ingreso'>{texts[lang]['LAT_FILTRO_I']}</option>
      </Form.Select>
      <div className={styles.detalleFiltro}>
        <p>
          {filtroElegido && filter?.type !== 'custom_color'
            ? texts[lang]['LAT_FILTRO_ACT'] + ' ' + filtroElegido
            : filter?.type === 'custom_color'
            ? ''
            : texts[lang]['LAT_FILTRO_SIN_ACT']}
        </p>
        {filter?.colors?.length > 0 &&
          filter.colors.map((el, i) => (
            <div key={i} className={styles.itemFiltro}>
              <div
                className={`${styles.muestraColor} ${warehouse.dark && styles.muestraColorDark}`}
                style={{
                  backgroundColor: el.color,
                }}
              ></div>
              <div>{el.valor}</div>
            </div>
          ))}
        {filter?.type === 'custom_color' && (
          <div className={styles.itemFiltro}>
            <span>{texts[lang]['LAT_FILTRO_E']}</span>
            <IconButton size='small' color='inherit' aria-label='remove' onClick={setFilter}>
              <RemoveCircle sx={{ fontSize: 20 }} />
            </IconButton>
          </div>
        )}
      </div>
      {error && <Alert severity='warning'>{error.message}</Alert>}
    </>
  )
}

export default Filtros
