import { Save } from '@mui/icons-material'
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useState, useContext } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalConfirm from '../../../../components/ModalConfirm/ModalConfirm'
import { getConfigCargaThunk, getConfigDescargaThunk } from '../../redux/slices/configuracion/thunk/getConfigCargaThunk'
import { updateAutonomoCargaConfig, updateAutonomoDescargaConfig } from '../../services/services'
import DaysSwitch from './DaysSwitch'
import TransferList from './TransferList'
import {texts} from '../../../../i18n.js'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext.jsx'

const ReglaGeneral = () => {
  const [openModal, setOpenModal] = useState(false)
  const config = useSelector((state) => state.configuracion_autonomo)
  const { active, date_props, download_config, download_exit, ingreso } = config
  const dispatch = useDispatch()
  const [value, setValue] = useState(download_config)
  const [exit, setExit] = useState(download_exit)
  const [valuePalletIngreso, setValuePalletIngreso] = useState(ingreso)

  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse


  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleChangePalletIngreso = (e) => {
    setValuePalletIngreso(e.target.value)
  }

  useEffect(() => {
    dispatch(getConfigCargaThunk())
    dispatch(getConfigDescargaThunk())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setValue(download_config)
    setExit(download_exit)
    setValuePalletIngreso(ingreso)
    // eslint-disable-next-line
  }, [download_config, config])

  const guardarAutonomo = async () => {
    try {
      const regla = active.map((r) => {
        return { property: r.accessorKey, days: date_props.value, ingreso: valuePalletIngreso }
      })
      const saveAutonomo = await updateAutonomoCargaConfig(regla)
      const saveAutonomoDescarga = await updateAutonomoDescargaConfig({ config: value, exit: exit })
      if (saveAutonomo.status === 'Success' && saveAutonomoDescarga.status === 'Success') {
        setOpenModal(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Stack direction={'column'} justifyContent='space-between' gap='2rem'>
      <Stack direction={'row'} gap='2rem'>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '3rem', marginTop: '1rem' }}>
          <div>
            <h4>{texts[lang]['CONFIG_AUTONOMO_CONFIG_CARGAS']}</h4>
            <p>{texts[lang]['CONFIG_AUTONOMO_CONFIG_REGLAS']}</p>
          </div>
          <FormControl>
            <FormLabel id='demo-controlled-radio-buttons-group' sx={{ fontWeight: 'bold' }}>
            {texts[lang]['CONFIG_AUTONOMO_TIPOPALLETS']}
            </FormLabel>
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={valuePalletIngreso}
              onChange={handleChangePalletIngreso}
            >
              <Stack direction={'row'}>
                <FormControlLabel value='deposito' control={<Radio />} label={texts[lang]['CONFIG_AUTONOMO_DEPOSITO']} />
                <FormControlLabel value='tunel' control={<Radio />} label={texts[lang]['CONFIG_AUTONOMO_TUNEL']}/>
              </Stack>
            </RadioGroup>
          </FormControl>
          <TransferList />
          <ModalConfirm
            open={openModal}
            setOpen={setOpenModal}
            title={texts[lang]['CONFIG_AUTONOMO_MODALCONFIRM_TITLE']}
            confirm={guardarAutonomo}
          >
            {texts[lang]['CONFIG_AUTONOMO_MODALCONFIRM_MSG']}
          </ModalConfirm>
        </div>
        <div>
          <h4>{texts[lang]['CONFIG_AUTONOMO_DESCARGA']}</h4>
          <p>{texts[lang]['CONFIG_AUTONOMO_DESCARGA_TEXT']}</p>
          <Box sx={{ marginTop: '3rem' }}>
            <Stack direction='column' gap={'1rem'}>
              <FormControl>
                <FormLabel id='demo-controlled-radio-buttons-group' sx={{ fontWeight: 'bold' }}>
                {texts[lang]['CONFIG_AUTONOMO_DESCARGA_PRIORIDAD']}
                </FormLabel>
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel value='fefo' control={<Radio />} label='FEFO' />
                  <FormControlLabel value='movimientos' control={<Radio />} label={texts[lang]['TAB_MOV']} />
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel id='demo-controlled-radio-buttons-group' sx={{ fontWeight: 'bold' }}>
                {texts[lang]['ICON_TEXTS_S']}
                </FormLabel>
                <RadioGroup
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={exit}
                  onChange={(e) => setExit(e.target.value)}
                >
                  <FormControlLabel value='2' control={<Radio />} label={texts[lang]['CONFIG_AUTONOMO_DESCARGA_LINEA']} />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Box>
        </div>
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DaysSwitch />
        <Button variant='contained' startIcon={<Save />} onClick={() => setOpenModal(true)}>
        {texts[lang]['EDIT_BOX_EDIT_G']}
        </Button>
      </div>
    </Stack>
  )
}

export default ReglaGeneral
