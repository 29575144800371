import React from 'react'
import styles from './ColorPicker.module.css'


const ColorPicker = ({ default_color, setColor }) => {
  return (
    <input
      type='color'
      id='exampleColorInput'
      className={styles.colorPicker}
      defaultValue={default_color}
      title='Seleccione su Color'
      onChange={(e) => setColor(e.target.value)}
    />
  )
}

export default ColorPicker
