import React from 'react'
import Pie from '../pie'
import Bar from '../bar'
import { Box } from '@mui/material'
import StatBox from '../../components/StatBox'
import ProductList from '../../components/ProductList'
import { useDispatch, useSelector } from 'react-redux'
import { cambiarFormato, capacidadEstadistica, productosCantidades } from '../../../../utils/dashboard'
import { useContext } from 'react'
import { BsGrid3X3GapFill } from 'react-icons/bs'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { useEffect } from 'react'
import { tokens } from '../../../../theme'
import { getPositionsThunk } from '../../../Almacen/redux/slices/positions/thunk/positionsThunk'
import { DoNotTouchOutlined, GridOff } from '@mui/icons-material'
import ProductBox from '../../components/ProductBox'
import Header from '../../components/Header'
import { texts } from '../../../../i18n'

const CapacidadDashboard = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  const colors = tokens(warehouse.dark)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPositionsThunk())
    return
    // eslint-disable-next-line
  }, [])

  const posiciones = useSelector((state) => state.positions.positions)

  //Convertimos el array clave-valor en array normal
  const posiciones2 = cambiarFormato(posiciones || [])

  let productos = productosCantidades(posiciones2)

  let capacidadDatos = capacidadEstadistica(posiciones2)

  window.addEventListener('error', (e) => {
    if (
      e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
      e.message === 'ResizeObserver loop limit exceeded'
    ) {
      e.stopImmediatePropagation()
    }
  })

  return (
    <Box m='20px' width='100%'>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap='20px'>
        <Box gridColumn='span 12' display='flex'>
          <Header title={texts[lang]['TITLE_CAP']} subtitle={texts[lang]['SUBT_CAP']} />
        </Box>
        {/* ROW 1 */}
        <Box
          gridColumn='span 3'
          backgroundColor={colors.primary[400]}
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='140px'
        >
          <StatBox
            title={capacidadDatos?.posicionesLibre || 0}
            subtitle={texts[lang]['STAT_BOX_D']}
            progress={capacidadDatos?.progresoLibre || 0}
            increase={capacidadDatos?.porcentajeLibre || 0}
            icon={<BsGrid3X3GapFill color={colors.verdes[200]} size='28px' />}
            hoverText={texts[lang]['H_STAT_BOX_D']}
            isCapacidad
          />
        </Box>
        <Box
          gridColumn='span 3'
          backgroundColor={colors.primary[400]}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <StatBox
            title={capacidadDatos?.posicionesOcupadas || 0}
            subtitle={texts[lang]['STAT_BOX_O']}
            progress={capacidadDatos?.progresoOcupado || 0}
            increase={capacidadDatos?.porcentajeOcupado || 0}
            icon={<GridOff sx={{ color: colors.verdes[200], fontSize: '32px' }} />}
            hoverText={texts[lang]['H_STAT_BOX_O']}
            isCapacidad
          />
        </Box>
        <Box
          gridColumn='span 3'
          backgroundColor={colors.primary[400]}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <StatBox
            title={capacidadDatos?.posicionesInhabilitadas || 0}
            subtitle={texts[lang]['STAT_BOX_I']}
            progress={capacidadDatos?.progresoInhabilitado || 0}
            increase={capacidadDatos?.porcentajeInhabilitado || 0}
            icon={<DoNotTouchOutlined sx={{ color: colors.verdes[200], fontSize: '35px' }} />}
            hoverText={texts[lang]['H_STAT_BOX_I']}
            isCapacidad
          />
        </Box>

        <Box
          gridColumn='span 3'
          backgroundColor={colors.primary[400]}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <ProductBox cantidadTotal={productos?.length} hoverText={texts[lang]['H_PRO_BOX_TITLE']} />
        </Box>

        {/* ROW 2 */}

        <Box gridColumn='span 6' backgroundColor={colors.primary[400]} justifyContent='center'>
          <Pie estado={true} />
        </Box>

        <Box gridColumn='span 6' backgroundColor={colors.primary[400]} justifyContent='center'>
          <Bar />
        </Box>
        {/* ROW 3 */}
        <Box
          gridColumn='span 12'
          backgroundColor={colors.primary[400]}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <ProductList productos={productos} />
        </Box>
      </Box>
    </Box>
  )
}

export default CapacidadDashboard
