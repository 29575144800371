import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDownloadOrdersConfirm, getDownloadOrdersHistory } from "../../../../services/services";


export const getConfirmOrdersThunk = createAsyncThunk('confirm_orders/getAll',
    async () => {
        return { lista: await getDownloadOrdersConfirm() }
    })

export const getHistoryOrdersThunk = createAsyncThunk('history/getAll',
    async () => {
        return { lista: await getDownloadOrdersHistory() }
    })