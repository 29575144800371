import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { Box, DialogContent, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import styles from './EditBox.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { closeEditBox } from '../../redux/slices/positionClickSlice/positionClickSlice'
import EditBoxForm from './EditBoxForm/EditBoxForm'
import EditPosicion from './EditPosicion/EditPosicion'
import { texts } from '../../../../i18n'

export default function EditBox() {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  const edit = useSelector((state) => state.positionClick.edit)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeEditBox())
  }

  // ----------- TABS -----------------------
  const [ventana, setVentana] = useState(1)
  const [value, setValue] = useState('one')
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={edit.display}
      style={{ top: '13vh', maxHeight: '80vh', right: '20vw' }}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
    >
      <DialogContent style={{ overflow: 'hidden', padding: '0' }}>
        <div className={warehouse.dark && styles.editDark}>
          <DialogTitle style={{ width: '18vw' }}>
            {texts[lang]['EDIT_BOX_TITLE']} - {edit.content?.id}
          </DialogTitle>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={warehouse.dark && styles.titleDark}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor='inherit'
              indicatorColor='primary'
              aria-label='secondary tabs example'
            >
              <Tab value='one' label={texts[lang]['EDIT_BOX_TAB_E']} onClick={() => setVentana(1)} />
              <Tab value='two' label={texts[lang]['EDIT_BOX_TAB_C']} onClick={() => setVentana(2)} />
            </Tabs>
          </Box>

          {ventana === 1 && <EditBoxForm {...edit.content} style={{ padding: '1rem' }} />}
          {ventana === 2 && <EditPosicion {...edit.content} style={{ padding: '1rem', maxHeight: '20vh' }} />}
        </div>
      </DialogContent>
    </Dialog>
  )
}
