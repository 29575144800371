import { createSlice } from "@reduxjs/toolkit";
import { createFilterFromProperty } from "../../../../../utils/colorFunctions";
import { getFilterDataThunk, getFilterDateThunk } from "./thunk/filterThunk";

const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        filter: localStorage.getItem('filter') !== null ? JSON.parse(localStorage.getItem('filter')) : null,
        loading: 'idle',
        currentRequestId: undefined,
        error: null,
    },
    reducers: {
        resetFilters(state) {
            state.filter = null
            localStorage.setItem('filter', JSON.stringify(state.filter))
        },
        addCustomFilter(state, action) {
            state.filter = {
                array_data: action.payload.array_data,
                color_selected: action.payload.color_selected,
                type: action.payload.type
            }
            localStorage.setItem('filter',  JSON.stringify(state.filter))
        },
    },
    extraReducers:
        builder => {
            //Get filter data (type: clave_color)
            builder.addCase(getFilterDataThunk.fulfilled, (state, action) => {
                const { requestId, arg } = action.meta
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle'
                    const colors = createFilterFromProperty(action.payload)
                    state.filter = { type: 'clave_color', colors: colors, property: arg.prop }
                    localStorage.setItem('filter', JSON.stringify(state.filter))

                    state.currentRequestId = undefined
                }
            })

            builder.addCase(getFilterDataThunk.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            builder.addCase(getFilterDataThunk.rejected, (state, action) => {
                const { requestId } = action.meta
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })
            // Get filter date (type: dias_hasta, dias_desde)
            builder.addCase(getFilterDateThunk.fulfilled, (state, action) => {
                const { requestId, arg } = action.meta
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle'
                    state.filter = { type: arg.type, date_max: action.payload, property: arg.prop }
                    localStorage.setItem('filter', JSON.stringify(state.filter))
                    state.currentRequestId = undefined
                }
            })

            builder.addCase(getFilterDateThunk.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            builder.addCase(getFilterDateThunk.rejected, (state, action) => {
                const { requestId } = action.meta
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })
        }
})

export const { resetFilters, addCustomFilter } = filterSlice.actions
export default filterSlice.reducer