import React, { useContext } from 'react';
import {Box} from "@mui/material";
import {tokens} from "../../../theme";
import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext';

const ProgressCircle = ({progress = "0.75", size = "70"}) => {

  const { warehouse } = useContext(ContextoAlmacen);
  const colors = tokens(warehouse.dark);
  const angle = progress *360;

  return (
    <Box
      sx={{
        background: `radial-gradient(${colors.primary[400]} 50%, transparent 56%),
            conic-gradient(transparent 0deg ${angle}deg, ${colors.verdes[200]} ${angle}deg 360deg),
            ${colors.verdes[300]}`,
        borderRadius: "50%",
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  )
}

export default ProgressCircle