import { useContext, useMemo, useState } from 'react'
import MaterialReactTable from 'material-react-table'
import { useEffect } from 'react'
import { CircularProgress, Stack } from '@mui/material'
import moment from 'moment'
import 'moment/locale/es'

import styles from '../Descarga/Descarga.module.css'
import { ContextoAlmacen } from '../../contexts/Almacen/AlmacenContext'
import { getMachineLogs } from './services/services'
import BarraLateral from '../Almacen/components/BarraLateral/BarraLateral'
import { translate_table } from '../Almacen/components/Busqueda/Translate'
import { texts } from '../../i18n'

const MachineLogs = () => {
  const { warehouse } = useContext(ContextoAlmacen)

  return (
    <div className={`${styles.container} ${warehouse.dark ? styles.containerDark : styles.containerWhite} `}>
      <div style={{ width: '100%' }}>
        <Tabla />
      </div>
      <BarraLateral all={false} />
    </div>
  )
}

const Tabla = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  moment().locale('es')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getData = async () => {
      const data = (await getMachineLogs(lang)) || []
      data?.map((log) => {
        return (log.time = moment(log.time).format('MMMM DD YYYY, h:mm:ss a'))
      })
      setData(data)
      setLoading(false)
    }

    getData()
    //eslint-disable-next-line
  }, [lang])

  const colors = {
    Error: 'red',
    Warning: 'orange',
  }

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: 'user',
        header: texts[lang]['COLUMN_USER'],
      },
      {
        accessorKey: 'machine',
        header: texts[lang]['COLUMN_MACHINE'],
      },
      {
        accessorKey: 'code',
        header: texts[lang]['COLUMN_CODE'],
      },
      {
        accessorKey: 'type',
        header: texts[lang]['COLUMN_TYPE'],
        Cell: ({ cell }) => <div style={{ background: colors[cell.getValue()] }}>{cell.getValue()}</div>,
      },
      {
        accessorKey: 'time',
        header: texts[lang]['COLUMN_TIME'],
      },
    ], //eslint-disable-next-line
    []
  )

  return (
    <Stack direction={'row'} justifyContent='center'>
      {loading ? (
        <Stack height='100%' justifyContent='center' alignItems='center'>
          <CircularProgress size={170} />
        </Stack>
      ) : (
        <MaterialReactTable
          columns={columns}
          data={data || []}
          localization={translate_table}
          muiTableContainerProps={{
            sx: { height: '75vh', backgroundColor: warehouse.dark ? '#292929' : '#fff', width: '95vw' },
          }}
          enableStickyHeader
          initialState={{
            density: 'compact',
            pagination: {
              pageIndex: 0,
              pageSize: 100,
            },
          }}
          muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
        />
      )}
    </Stack>
  )
}

export default MachineLogs
