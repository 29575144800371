import React, { useContext } from 'react'
import { Alert, IconButton } from '@mui/material'
import { Info, RemoveCircle } from '@mui/icons-material'
import styles from './OperacionAutomatica.module.css'
import { ContextoData } from '../../../../contexts/Data/DataContext'
import { useDispatch, useSelector } from 'react-redux'
import { clearPositions } from '../../redux/slices/operation/operationSlice'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { texts } from '../../../../i18n'

const OperacionAutomatica = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  const operacion = useSelector((state) => state.operation)
  const { origen, destino } = operacion
  const posiciones = useSelector((state) => state.positions.positions)
  const { data_format } = useContext(ContextoData)
  const dispatch = useDispatch()
  const dataPosicion = posiciones && posiciones[origen]
  const removePositions = () => {
    dispatch(clearPositions())
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <div>
            {texts[lang]['LAT_OPERA_O']}: <span> {origen} </span>
          </div>
          <div>
            {texts[lang]['LAT_OPERA_D']}: <span>{destino}</span>
          </div>
        </div>
        {origen && destino && (
          <IconButton size='small' color='inherit' aria-label='remove' onClick={removePositions}>
            <RemoveCircle sx={{ fontSize: 20 }} />
          </IconButton>
        )}
      </div>
      <div className={styles.infoPosicion}>
        {dataPosicion ? (
          <Alert
            icon={<Info fontSize='inherit' color='custom' />}
            sx={{
              backgroundColor: warehouse.dark ? '#356161 !important' : '',
              color: warehouse.dark ? '#fff' : '',
            }}
          >
            {texts[lang]['LAT_OPERA_INF_T']}:
            <ul>
              {data_format.map((column, key) => (
                <li key={key}>
                  {column.header}: {dataPosicion[column.accessorKey]}
                </li>
              ))}
            </ul>
          </Alert>
        ) : (
          <Alert
            icon={<Info fontSize='inherit' color='custom' />}
            sx={{
              backgroundColor: warehouse.dark ? '#356161 !important' : '#deecf3 !important',
              color: warehouse.dark ? '#fff' : '',
            }}
          >
            {texts[lang]['LAT_OPERA_SELEC']}
          </Alert>
        )}
      </div>
    </>
  )
}

export default OperacionAutomatica
