import { useContext, useEffect, useState } from 'react'
import { AccordionContext } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import Card from 'react-bootstrap/Card'
import { BsFillCaretDownFill } from 'react-icons/bs'
import { HiOutlineFilter } from 'react-icons/hi'
import { FiDownload } from 'react-icons/fi'
import styles from './Acordions.module.css'
import OperacionAutomatica from '../OperacionAutomatica/OperacionAutomatica'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import Filtros from '../Filtros/Filtros'
import { texts } from '../../../../i18n'

function CustomToggle({ children, eventKey, dark }) {
  const { activeEventKey } = useContext(AccordionContext)
  const decoratedOnClick = useAccordionButton(eventKey)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(eventKey !== activeEventKey ? false : true)
  }, [activeEventKey, eventKey])

  return (
    <button
      type='button'
      onClick={decoratedOnClick}
      style={{ border: 'none', background: 'none' }}
      className={`${styles.botonTogle} ${dark && styles.botonTogleDark}`}
    >
      <BsFillCaretDownFill transform={open ? 'rotate(180)' : ''} />
    </button>
  )
}

function Acordions(props) {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  return (
    <Accordion
      defaultActiveKey='1'
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Card className={`${styles.allCard} ${warehouse.dark && styles.allCardDark}`}>
        <Card.Header className={`${styles.headerCard} ${warehouse.dark && styles.headerCardDark}`}>
          {texts[lang]['LAT_FILTRO_T']}
          <HiOutlineFilter />
        </Card.Header>
        <Accordion.Collapse eventKey='0'>
          <Card.Body className={styles.bodyCard}>
            <Filtros />
          </Card.Body>
        </Accordion.Collapse>
        <div className={`${styles.contenedorBotonTogle} ${warehouse.dark && styles.contenedorBotonTogleDark}`}>
          <CustomToggle dark={warehouse.dark} eventKey='0'></CustomToggle>
        </div>
      </Card>

      <Card className={`${styles.allCard} ${warehouse.dark && styles.allCardDark}`}>
        <Card.Header className={`${styles.headerCard} ${warehouse.dark && styles.headerCardDark}`}>
          {texts[lang]['LAT_OPERA_T']}
          <FiDownload />
        </Card.Header>
        <Accordion.Collapse eventKey='1'>
          <Card.Body>
            <OperacionAutomatica />
          </Card.Body>
        </Accordion.Collapse>
        <div className={`${styles.contenedorBotonTogle} ${warehouse.dark && styles.contenedorBotonTogleDark}`}>
          <CustomToggle dark={warehouse.dark} eventKey='1'>
            Click me!
          </CustomToggle>
        </div>
      </Card>
    </Accordion>
  )
}

export default Acordions
