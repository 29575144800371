import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import useSocket from '../../custom-hooks/useSocket'

const CttoError = ({ error }) => {
  return (
    <Snackbar open={error?.display} sx={{ position: 'initial' }}>
      <Alert severity={error?.clase || 'success'} sx={{ width: '100%' }}>
        <p dangerouslySetInnerHTML={{ __html: error?.msg }}></p>
      </Alert>
    </Snackbar>
  )
}

const CttoAlerts = ({ alert, setAlert }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setAlert({ ...alert, display: false })
  }

  return (
    <Snackbar open={alert?.display} onClose={handleClose} sx={{ position: 'initial' }}>
      <Alert severity={alert?.clase || 'success'} onClose={handleClose} sx={{ width: '100%' }}>
        <p dangerouslySetInnerHTML={{ __html: alert?.msg }}></p>
      </Alert>
    </Snackbar>
  )
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export default function AlertCtto2() {
  const [alert, setAlert] = React.useState()
  const [error, setError] = React.useState()

  const socket = useSocket()

  React.useEffect(() => {
    socket.on('state:toast-error', (newMsg) => {
      //console.log(newMsg)
      setError(newMsg)
    })
    socket.on('state:toast', (newMsg) => {
      setAlert(newMsg)
    })
    // eslint-disable-next-line
  }, [socket])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        gap: '10px',
        bottom: '50px',
      }}
    >
      <CttoError error={error} />
      <CttoAlerts alert={alert} setAlert={setAlert} />
    </div>
  )
}
