import React from 'react'
import { Box, ThemeProvider, createTheme } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { tokens } from '../../../theme'
import Header from '../components/Header'
import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext'
import { useContext } from 'react'
import { esES } from '@mui/material/locale'
import { traduccciones } from '../../../utils/dashboard'
import { texts } from '../../../i18n'

const ProductList = (props) => {
  const { productos } = props
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  const colors = tokens(warehouse.dark)

  //Le pasamos este tema vacio al provider de mui que envuelve a la grilla en este componente, para pisar al provider principal
  const theme = createTheme({}, esES)

  const columns = [
    { field: 'sku', headerName: texts[lang]['TABLE_PROD_COLUMN_S'], flex: 2, align: 'left' },
    {
      field: 'producto',
      headerName: texts[lang]['TABLE_PROD_COLUMN_P'],
      flex: 3,
    },
    {
      field: 'cantidad',
      headerName: texts[lang]['TABLE_PROD_COLUMN_N'],
      flex: 2,
      align: 'left',
    },
    {
      field: 'unidades',
      headerName: texts[lang]['TABLE_PROD_COLUMN_U'],
      flex: 2,
      align: 'left',
    },
  ]

  return (
    <Box m='25px' width='95%'>
      <Header title={texts[lang]['TITLE_PROD_TABLE']} subtitle={texts[lang]['SUBT_PROD_TABLE']} />

      <Box
        m='30px 0 0 0'
        height='75vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#47817F',
            color: '#F9F9F9',
            borderBottom: 'none',
          },
          ' & .MuiSvgIcon-root ': {
            fill: colors.grey[100],
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: '#47817F',
            color: '#F9F9F9',
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
          '& .MuiTablePagination-root': {
            backgroundColor: '#47817F',
            color: '#F9F9F9',
            pageSize: 10,
          },
          '& .MuiDataGrid-cell': {
            color: colors.grey[100],
          },
          '& .MuiList-root': {
            color: '#47817F',
            backgroundColor: '#47817F !important',
            fill: 'red',
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={productos || []}
            getRowId={(row) => row.sku}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            localeText={traduccciones}
            initialState={{
              pagination: { paginationModel: { pageSize: 15 } },
            }}
            pageSizeOptions={[15, 30, 45]}
          />
        </ThemeProvider>
      </Box>
    </Box>
  )
}

export default ProductList
