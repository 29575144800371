import { Box } from '@mui/material'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { texts } from '../../../../i18n'
import BarSlots from '../../components/BarSlots'
import Header from '../../components/Header'

const Bar = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  return (
    <Box m='20px'>
      <Header title={texts[lang]['TITLE_GRAP_C_L']} subtitle={texts[lang]['SUBT_GRAP_C_L']} />
      <BarSlots />
    </Box>
  )
}

export default Bar
