
// ------------------- Constructor de Posiciones. ------------

const statusText = {
    0: 'Free',
    1: 'Ocuppied',
    2: 'Blocked',
    3: 'Disabled',
}

export class Positions {
    constructor(position) {
        this.id = position.id
        this.estado = position.status
        this.status = statusText[position.status]
        this.tipo_celda = position.comment || 'bajo'
        this.lpn = position['loadUnit.lpn']
        this.cantidad = position['loadUnit.qty']
        this.sku = position['loadUnit.sku']
        this.ingreso = position['loadUnit.createdAt']?.slice(0, 10)
        this.fecha_pallet = position['loadUnit.pallet_date']?.slice(0, 10)
        this.mayor_elaboracion = position['loadUnit.earliest_production_date']?.slice(0, 10)
        this.menor_elaboracion = position['loadUnit.lastest_production_date']?.slice(0, 10)
        this.vencimiento = position['loadUnit.earliest_expiration_date']?.slice(0, 10)
        this.menor_vencimiento = position['loadUnit.lastest_expiration_date']?.slice(0, 10)
        this.producto = position['loadUnit.description']
        this.market_code = position['loadUnit.market_code'] || "Sin Información"
        this.market_description = position['loadUnit.market_description'] || "Sin Información"
        this.weight = position['loadUnit.weight']
        this.net_weight = position['loadUnit.net_weight'] || 'Sin Información'
        this.gross_weight = position['loadUnit.gross_weight'] || 'Sin Información'
        this.tipo_pallet = position['loadUnit.pallet_type'] || 'Sin Información'
    }
}

export const positionsAdapter = (positions) => {
    const positions_object = {};
    for (const pos of positions) {
        positions_object[pos.id] = new Positions(pos)
    }
    return positions_object
}
// ----------------- ************************ ---------------

// --------------- Adapter Filtros -----------------------------
export const filterAdapter = (response, prop) => {
    if (prop === 'vencimiento') {
        return response.result.slice(-1)[0]
    }
    if (prop === 'ingreso') {
        return response.result[0]
    }

    return response.result
}
// Adapter de parametros a enviar por query
export const getFilterAdapter = (prop) => {
    const adapt = {
        producto: 'description',
        cliente: 'area',
        vencimiento: 'earliest_expiration_date',
        ingreso: 'createdAt',
    }
    return adapt[prop]
}
// ----------------- ************************ ---------------


// ---------- Adapter POST Unidad de Carga Loadunit ----------------

export const loadUnitAdapter = (data) => {
    return {
        ...data,
        slot_id: data.slotId,
        lpn: data.lpn,
        status: "saved"
    }
}

export const putLoadUnitAdapter = (data, id) => {
    if (id) {
        return {
            "lpn": data.lpn,
            "slot_id": id
        }
    }
}


// ----------------- ************************ ---------------
