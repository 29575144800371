import { createSlice } from "@reduxjs/toolkit";

const positionClickSlice = createSlice({
    name: 'position-click',
    initialState: {
        info: {
            display: false,
            content: null
        },
        edit: { display: false, content: null }
    },

    reducers: {
        setInfo(state, action) {
            state.info = {
                display: true,
                content: action.payload,
            }
        },
        closeInfoBox(state, action) {
            state.info = {
                display: false,
                content: null,
            }
        },
        setEdit(state, action) {
            state.edit = {
                display: true,
                content: action.payload
            }
        },
        closeEditBox(state, action) {
            state.edit = {
                display: false,
                content: null
            }
        }
    }
})

export const { setInfo, setEdit, closeInfoBox, closeEditBox } = positionClickSlice.actions
export default positionClickSlice.reducer