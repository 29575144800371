import { Add, Check, Delete } from '@mui/icons-material'
import { Autocomplete, Button, Stack, TextField, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { translate_table } from '../../../Almacen/components/Busqueda/Translate'
import { translate_table_pr } from '../../../Almacen/components/Busqueda/Translate_pr'
import { getPositionsThunk } from '../../../Almacen/redux/slices/positions/thunk/positionsThunk'
import { deleteItemPrevList, setPrevList } from '../../redux/slices/lista/listaDescargaSlice'
import MaterialReactTable from 'material-react-table'
import ModalConfirm from '../../../../components/ModalConfirm/ModalConfirm'
import { createListBySKU } from '../../services/services'
import { texts } from '../../../../i18n'

const CreateDownloadOrders = () => {
  const { positions } = useSelector((state) => state.positions)

  const dispatch = useDispatch()
  const { register, getValues } = useForm()

  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  

  // eslint-disable-next-line
  useEffect(() => dispatch(getPositionsThunk()), [])

  // Convertimos el objeto positios a un array de objetos SKU y Productos no repetidos
  const options =
    (positions &&
      [
        ...new Set(
          Object.values(positions)
            .filter(({ estado, sku }) => estado && sku)
            .map(({ sku, producto }) => JSON.stringify({ sku, producto }))
        ),
      ].map((obj) => {
        const { sku, producto } = JSON.parse(obj)
        return { label: producto, sku }
      })) ||
    []

  const [value, setValue] = useState(options[0])

  // ----------- ADD TO LIST -------------------------

  const addLoadUnits = async () => {
    const { cantidad_pallets, cantidad_cajas } = getValues()
    const lista = [
      {
        qty: +cantidad_cajas,
        sku: value.sku,
        producto: value.label,
        lu_qty: +cantidad_pallets,
      },
    ]
    dispatch(setPrevList(lista))
  }
  // --------------------------------------------------------

  return (
    <Stack direction={'column'} mt='2rem'>
      <Stack direction={'row'} gap='1rem' alignItems={'center'}>
        <Autocomplete
          disablePortal
          id='combo-box-demo'
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue)
          }}
          options={options}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label={texts[lang]['DESCARGA_ORDERS_LABEL_PRODUCT']} />}
        />
        <TextField
          id='outlined-basic'
          label='SKU'
          type='number'
          value={value?.sku}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id='outlined-basic'
          label={texts[lang]['DESCARGA_ORDERS_LABEL_PALLETS']}
          variant='outlined'
          type='number'
          defaultValue={1}
          {...register('cantidad_pallets')}
        />
        <TextField
          id='outlined-basic'
          label={texts[lang]['DESCARGA_ORDERS_LABEL_CAJAS']}
          variant='outlined'
          type='number'
          defaultValue={1}
          {...register('cantidad_cajas')}
        />
        <div>
          <Button variant='contained' startIcon={<Add />} onClick={addLoadUnits}>
          {texts[lang]['DESCARGA_ORDERS_BUTTON_ANADIR']}
          </Button>
        </div>
      </Stack>
      <Box mt={'1rem'}>
        <TableDownloadOrders />
      </Box>
    </Stack>
  )
}

export default CreateDownloadOrders

const TableDownloadOrders = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { prev_list } = useSelector((state) => state.lista_descarga)
  const dispatch = useDispatch()
  const { lang } = warehouse

  let tableTranslate;

  const translations = {
    ES: translate_table,
    PT: translate_table_pr
  };
  
  tableTranslate = translations[lang] || '';


  const columns = [
    {
      accessorKey: 'producto',
      header: texts[lang]['DESCARGA_ORDERS_LABEL_PRODUCT'],
    },
    {
      accessorKey: 'sku',
      header: 'SKU',
    },
    {
      accessorKey: 'lu_qty',
      header: texts[lang]['DESCARGA_ORDERS_LABEL_PALLETS'],
    },
    {
      accessorKey: 'qty',
      header: texts[lang]['DESCARGA_ORDERS_LABEL_CAJAS'],
    },
  ]

  const [rowSelection, setRowSelection] = useState({})
  const [open, setOpen] = useState(false)
  const [errorMsg, setError] = useState(false)
  // ----------- DELETE ITEMS ----------------------------
  const handleDelete = () => {
    const items = Object.keys(rowSelection).map((item) => prev_list[item])
    if (items.length === 0) return
    dispatch(deleteItemPrevList(items))
    setRowSelection({}) // RESTORE STATE
  }

  const confirmList = async () => {
    const items = Object.keys(rowSelection).map((item) => prev_list[item])
    if (items.length === 0) return setError('Debe seleccionar lineas para confirmar')

    try {
      const response = await createListBySKU(items)
      console.log(response)
      if (response.status === 'Success') {
        setError(false)
        setOpen(false)
        dispatch(deleteItemPrevList(items))
        setRowSelection({})
      }
      if (response.status === 'Error') {
        setError(response.error)
      }
    } catch (error) {
      console.log(error)
      setError(error.message)
    }
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={prev_list || []}
      localization={tableTranslate}
      enableRowSelection
      state={{ rowSelection }}
      onRowSelectionChange={setRowSelection}
      muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
      muiTableContainerProps={{
        sx: { height: '50vh', backgroundColor: warehouse.dark ? '#292929' : '#fff', maxWidth: '95vw' },
      }}
      muiToolbarAlertBannerProps={{
        sx: { color: warehouse?.dark === true && '#ffffff', backgroundColor: warehouse?.dark === true && '#356161' },
      }}
      initialState={{
        density: 'compact',
        pagination: {
          pageIndex: 0,
          pageSize: 50,
        },
      }}
      defaultColumn={{
        maxSize: 400,
        minSize: 80,
      }}
      enableColumnResizing
      enableStickyHeader
      enableStickyFooter
      columnResizeMode='onChange' //default is "onEnd"
      // --------------- RENDER FOOTER TABLE BUTTONS -----------------------
      renderBottomToolbarCustomActions={() => (
        <div>
          <Stack direction={'row'} gap='1rem'>
            <Tooltip title= {texts[lang]['DESCARGA_ORDERS_BUTTON_CONFIRM_TOOLTIP']} arrow placement='top'>
              <Button onClick={() => setOpen(true)} variant='contained' startIcon={<Check />}>
              {texts[lang]['DESCARGA_BOTTONS_CONFIRM']}
              </Button>
            </Tooltip>
            <Tooltip title={texts[lang]['DESCARGA_ORDERS_BUTTON_DELETE_TOOLTIP']} arrow placement='top'>
              <Button onClick={handleDelete} variant='contained' color='error' startIcon={<Delete />}>
              {texts[lang]['DESCARGA_BOTTONS_DELETE']}
              </Button>
            </Tooltip>
          </Stack>
          <ModalConfirm
            open={open}
            setOpen={setOpen}
            confirm={confirmList}
            errorMsg={errorMsg}
            title={texts[lang]['DESCARGA_MODAL_TITLE_CONFIRM_ORDER']}
            setErrorMsg={setError}
          >
            {texts[lang]['DESCARGA_BOTTONS_MODALCONFIRM']}
          </ModalConfirm>
        </div>
      )}
      // --------- ************* ----------------
    />
  )
}
