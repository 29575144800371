import React, { useContext, useEffect, useRef } from 'react'
import Grilla from './components/Grilla/Grilla'
import styles from './Almacen.module.css'
import PanelControl from './components/PanelControl/PanelControl'
import { ContextoAlmacen } from '../../contexts/Almacen/AlmacenContext'
import BarraInferior from './components/BarraInferior/BarraInferior'
import useSocket from '../../custom-hooks/useSocket'
import FloatInformation from './components/FloatInformation/FloatInformation'
import { useSelector } from 'react-redux'

const Almacen = ({ firstRender }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const socket = useSocket()
  const nivel_actual = useSelector((state) => state.gridView.actualLevel)
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    } else {
      socket.emit('action:actualizar-socket')
    }
    return () => socket.off('action:actualizar-socket')
    // eslint-disable-next-line
  }, [socket])

  const grillaRef = useRef(null)

  return (
    <div className={styles.container}>
      <div ref={grillaRef} className={`${styles.grilla} ${warehouse.dark ? styles.grillaDark : ''}`}>
        <FloatInformation refGrilla={grillaRef}>
          <h5>Nivel {nivel_actual}</h5>
        </FloatInformation>
        <Grilla />
      </div>

      <div className={`${styles.barraInf} ${warehouse.dark && styles.barraInfDark}`}>
        <BarraInferior />
      </div>

      <div className={!warehouse.dark ? styles.panel : styles.panelDark}>
        <PanelControl />
      </div>
    </div>
  )
}

export default Almacen
