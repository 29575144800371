import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as TransSvg } from './../../../../assets/imgs/transelevador-01.svg'
import { ReactComponent as ShuttleSvg } from './../../../../assets/imgs/shuttle-02.svg'
import { ReactComponent as ShuttleSvgPallet } from './../../../../assets/imgs/shuttle-02-pallet-2.svg'
import { useState } from 'react'
import useSocket from '../../../../custom-hooks/useSocket'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { useDispatch, useSelector } from 'react-redux'
import { setPalletCuna } from '../../redux/slices/operation/operationSlice'

const WrapperGroup = styled.div`
  position: absolute;
  transition: all 0.1s linear;
  transform: translate(${(props) => props.x}px);
`
const WrapperShuttle = styled.div`
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 100px;
  transition: all 0.3s linear;
  transform: translateY(${(props) => props.z}px);
`
const Wrapper = styled.div`
  position: relative;
`

const Transelevador = () => {
  const { transRef } = useContext(ContextoAlmacen)
  const { pallet_cuna } = useSelector((state) => state.operation)
  const [moveX, setMoveX] = useState(0)
  const [moveZ, setMoveZ] = useState(0)
  const dispatch = useDispatch()
  const socket = useSocket()
  // ACA ESTARIA LA FUNCION DENTRO DE CADA SOCKET QUE PARSEE x_value a px 0 siempre es el origen de los dos ejes MIN MAX X 0 a 1370px   MIN MAX Z  -360px  360px
  useEffect(() => {
    socket.on('state:transelevador-x', ({ x_actual = 0, x_offset = 0 }) => {
      const value = (+x_offset / 2000 + +x_actual) * 72 - 177
      setMoveX(value)
    })

    socket.on('state:transelevador', (value) => {
      dispatch(setPalletCuna(value))
    })

    socket.on('state:transelevador-z', ({ z_actual = 0, z_side = 0, z_offset = 0 }) => {
      const value = (+z_offset / 2000 + +z_actual) * 56.5 * (+z_side === 0 ? -1 : 1)
      setMoveZ(value)
    })

    return () => {
      socket.off('state:transelevador-x')
      socket.off('state:transelevador-z')
      socket.off('state:transelevador')
    } //eslint-disable-next-line
  }, [socket])

  return (
    <WrapperGroup ref={transRef} x={moveX}>
      <Wrapper>
        <TransSvg width='160px' />
      </Wrapper>
      <WrapperShuttle z={moveZ}>
        {pallet_cuna ? <ShuttleSvgPallet width='50px' /> : <ShuttleSvg width='50px' />}
      </WrapperShuttle>
    </WrapperGroup>
  )
}

export default Transelevador
