import { createSlice } from "@reduxjs/toolkit";
import { getDataMovimientosThunk } from "./thunk/getDataMovimientosThunk";

const movimientosSlice = createSlice({
    name: 'movimientosSlice',
    initialState: {
        data: [],
        dataAnalytics: [],
        loading: 'idle',
        currentRequestId: undefined,
        error: null,
    },
    reducers: {

    },
    extraReducers:
        builder => {

            builder.addCase(getDataMovimientosThunk.fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle'
                    state.data = action.payload
                    state.currentRequestId = undefined
                }
            })

            builder.addCase(getDataMovimientosThunk.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending'
                    state.currentRequestId = action.meta.requestId
                }
            })
            builder.addCase(getDataMovimientosThunk.rejected, (state, action) => {
                const { requestId } = action.meta
                if (state.loading === 'pending' && state.currentRequestId === requestId) {
                    state.loading = 'idle'
                    state.error = action.error
                    state.currentRequestId = undefined
                }
            })
        }
})

// export const { resetFilters, addCustomFilter } = movimientosSlice.actions
export default movimientosSlice.reducer