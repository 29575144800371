import styled from 'styled-components'

export const BoxDraw = styled.div.attrs((props) => ({
  style: {
    background: props.color,
    cursor: !props.$cursor ? 'pointer' : '',
    boxShadow : props.selected ? 'rgb(167 203 224) 0px 0px 0px 8px' : ''
  },
}))`
  z-index: 20;
  width: 40px;
  height: 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  color: #fff;
  font-weight: 300;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: lightblue !important;
  }
`
