import { createSlice } from "@reduxjs/toolkit";
import { getPositionsThunk } from "./thunk/positionsThunk";

const positionsSlice = createSlice({
    name: 'positions',
    initialState: {
        positions: null,
        loading: 'idle',
        currentRequestId: undefined,
        error: null,
    },


    extraReducers: builder => {
        //Get and Set Positions Object 
        builder.addCase(getPositionsThunk.fulfilled, (state, action) => {
            const { requestId } = action.meta
            if (state.loading === 'pending' && state.currentRequestId === requestId) {
                state.loading = 'idle'
                state.positions = action.payload
                state.currentRequestId = undefined
            }
        })
        builder.addCase(getPositionsThunk.pending, (state, action) => {
            if (state.loading === 'idle') {
                state.loading = 'pending'
                state.currentRequestId = action.meta.requestId
            }
        })
        builder.addCase(getPositionsThunk.rejected, (state, action) => {
            const { requestId } = action.meta
            if (state.loading === 'pending' && state.currentRequestId === requestId) {
                state.loading = 'idle'
                state.error = action.error
                state.currentRequestId = undefined
            }
        })
    }
})

export default positionsSlice.reducer