import { Circle } from '@mui/icons-material'
import React, { createContext } from 'react'
import { useContext } from 'react'
import { texts } from '../../i18n'
import { ContextoAlmacen } from '../Almacen/AlmacenContext'
export const ContextoData = createContext()

export const DataContextProvider = ({ children }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  // Colors Table
  const colors = {
    completado: '#039538',
    transito: '#f87928',
    'en cola': '#607d8b',
    cancelado: '#73797e',
    pendiente: '#d9d20c',
  }

  const STATE = {
    // ----------------- FORMATO DE DATOS TABLA BUSQUEDA E INFO BOX -----------------

    data_format: [
      {
        accessorKey: 'id',
        header: texts[lang]['DATA_F_P'],
      },
      {
        accessorKey: 'status',
        header: texts[lang]['DATA_F_S'],
      },
      {
        accessorKey: 'lpn',
        header: texts[lang]['DATA_F_L'],
      },
      {
        accessorKey: 'sku',
        header: texts[lang]['DATA_F_SK'],
      },
      {
        accessorKey: 'producto',
        header: texts[lang]['DATA_F_D'],
      },
      {
        accessorKey: 'market_code',
        header: texts[lang]['DATA_F_CM'],
      },
      {
        accessorKey: 'market_description',
        header: texts[lang]['DATA_F_DN'],
      },
      {
        accessorKey: 'fecha_pallet',
        header: texts[lang]['DATA_F_FP'],
      },
      {
        accessorKey: 'ingreso',
        header: texts[lang]['DATA_F_I'],
      },
      {
        accessorKey: 'mayor_elaboracion',
        header: texts[lang]['DATA_F_PA'],
      },
      {
        accessorKey: 'menor_elaboracion',
        header: texts[lang]['DATA_F_PR'],
      },
      {
        accessorKey: 'vencimiento',
        header: texts[lang]['DATA_F_VA'],
      },
      {
        accessorKey: 'menor_vencimiento',
        header: texts[lang]['DATA_F_VR'],
      },
      {
        accessorKey: 'cantidad',
        header: texts[lang]['DATA_F_C'],
      },
      {
        accessorKey: 'weight',
        header: texts[lang]['DATA_F_PT'],
      },
      {
        accessorKey: 'net_weight',
        header: texts[lang]['DATA_F_PL'],
      },
      {
        accessorKey: 'gross_weight',
        header: texts[lang]['DATA_F_PB'],
      },
      {
        accessorKey: 'tipo_pallet',
        header: texts[lang]['DATA_F_TP'],
      },
    ],
    // ----------------- FORMATO DE DATOS TABLA MOVIMIENTOS -----------------

    data_format_movements: [
      {
        accessorKey: 'id',
        header: texts[lang]['DATA_F_ID'],
      },
      {
        accessorKey: 'operation',
        header: texts[lang]['DATA_F_O'],
      },
      {
        accessorKey: 'lpn',
        header: texts[lang]['DATA_F_L'],
      },
      {
        accessorKey: 'sku',
        header: texts[lang]['DATA_F_SK'],
      },
      {
        accessorKey: 'description',
        header: texts[lang]['DATA_F_D'],
      },
      {
        accessorKey: 'market_code',
        header: texts[lang]['DATA_F_CM'],
      },
      {
        accessorKey: 'market_description',
        header: texts[lang]['DATA_F_DN'],
      },
      {
        accessorKey: 'createdAt',
        header: texts[lang]['DATA_F_I'],
      },
      {
        accessorKey: 'updatedAt',
        header: texts[lang]['DATA_F_U'],
      },
      {
        accessorKey: 'pallet_date',
        header: texts[lang]['DATA_F_FP'],
      },
      {
        accessorKey: 'earliest_production_date',
        header: texts[lang]['DATA_F_PA'],
      },
      {
        accessorKey: 'lastest_production_date',
        header: texts[lang]['DATA_F_PR'],
      },
      {
        accessorKey: 'lastest_expiration_date',
        header: texts[lang]['DATA_F_VA'],
      },
      {
        accessorKey: 'earliest_expiration_date',
        header: texts[lang]['DATA_F_VR'],
      },
      {
        accessorKey: 'qty',
        header: texts[lang]['DATA_F_C'],
      },
      {
        accessorKey: 'weight',
        header: texts[lang]['DATA_F_PT'],
      },
      {
        accessorKey: 'net_weight',
        header: texts[lang]['DATA_F_PL'],
      },
      {
        accessorKey: 'gross_weight',
        header: texts[lang]['DATA_F_PB'],
      },
      {
        accessorKey: 'current_slot',
        header: texts[lang]['DATA_F_CS'],
      },
      {
        accessorKey: 'destiny_slot',
        header: texts[lang]['DATA_F_DS'],
      },
      {
        accessorKey: 'observation',
        header: texts[lang]['DATA_F_OB'],
      },
    ],
    // ----------------- FORMATO DE DATOS TABLA DESCARGA -----------------

    data_format_descarga: [
      {
        accessorKey: 'id',
        header: texts[lang]['DATA_F_DES_POSICION'],
      },
      {
        accessorKey: 'lpn',
        header: texts[lang]['DATA_F_DES_LPN'],
      },
      {
        accessorKey: 'sku',
        header: texts[lang]['DATA_F_DES_SKU'],
      },
      {
        accessorKey: 'producto',
        header: texts[lang]['DATA_F_DES_PRODUCTO'],
      },
      {
        accessorKey: 'ingreso',
        header: texts[lang]['DATA_F_DES_INGRESO'],
      },
      {
        accessorKey: 'vencimiento',
        header: texts[lang]['DATA_F_DES_VENCIMIENTO'],
      },

      {
        accessorKey: 'cantidad',
        header: texts[lang]['DATA_F_DES_BULTOS'],
      },
      {
        accessorKey: 'tipo_pallet',
        header: texts[lang]['DATA_F_DES_PALLET'],
      },
    ],
    // ----------------- FORMATO DE DATOS TABLA DESCARGA CONFIRMADA -----------------

    data_format_descarga_confirm: [
      {
        accessorKey: 'n_orden',
        header: texts[lang]['DATA_F_DES_HIS_ORDEN'],
        maxSize: 120,
      },
      {
        accessorKey: 'estado',
        header: texts[lang]['DATA_F_DES_HIS_STATE'],
        Cell: ({ cell }) => (
          <div style={{ display: 'flex', alingItems: 'center', gap: '15px' }}>
            <Circle sx={{ color: colors[cell.getValue()] }} />
            {cell.getValue()}
          </div>
        ),
      },
      {
        accessorKey: 'data_origin',
        header: texts[lang]['DATA_F_DES_HIS_ORIGIN'],
        maxSize: 100,
      },
      {
        accessorKey: 'prioridad_de_descarga',
        header: texts[lang]['DATA_F_DES_HIS_PRIORIDAD'],
        maxSize: 100,
      },
      {
        accessorKey: 'posicion',
        header: texts[lang]['DATA_F_DES_POSICION'],
      },
      {
        accessorKey: 'lpn',
        header: 'LPN',
      },
      {
        accessorKey: 'sku',
        header: 'SKU',
      },
      {
        accessorKey: 'producto',
        header: texts[lang]['DATA_F_DES_PRODUCTO'],
      },
      {
        accessorKey: 'ingreso',
        header: texts[lang]['DATA_F_DES_INGRESO'],
      },
      {
        accessorKey: 'cantidad',
        header: texts[lang]['DATA_F_DES_BULTOS'],
      },
    ],

    // ----------------- FORMATO DE DATOS TABLA HISTORICO DESCARGA -----------------

    data_format_descarga_history: [
      {
        accessorKey: 'n_orden',
        header: texts[lang]['DATA_F_DES_HIS_ORDEN'],
        maxSize: 120,
      },
      {
        accessorKey: 'estado',
        header: texts[lang]['DATA_F_DES_HIS_STATE'],
        Cell: ({ cell }) => (
          <div style={{ display: 'flex', alingItems: 'center', gap: '15px' }}>
            <Circle sx={{ color: colors[cell.getValue()] }} />
            {cell.getValue()}
          </div>
        ),
      },
      {
        accessorKey: 'data_origin',
        header: texts[lang]['DATA_F_DES_HIS_ORIGIN'],
        maxSize: 100,
      },
      {
        accessorKey: 'prioridad_de_descarga',
        header: texts[lang]['DATA_F_DES_HIS_PRIORIDAD'],
        maxSize: 100,
      },
      {
        accessorKey: 'posicion',
        header: texts[lang]['DATA_F_DES_POSICION'],
      },
      {
        accessorKey: 'lpn',
        header: 'LPN',
      },
      {
        accessorKey: 'sku',
        header: 'SKU',
      },
      {
        accessorKey: 'producto',
        header: texts[lang]['DATA_F_DES_PRODUCTO'],
      },
      {
        accessorKey: 'ingreso',
        header: texts[lang]['DATA_F_DES_INGRESO'],
      },

      {
        accessorKey: 'cantidad',
        header: texts[lang]['DATA_F_DES_BULTOS'],
      },
    ],
    // -------------------- FORMATO DE DATOS TABLA PALLETS ADMINISTRADOR DE POSICIONES -------------
    data_format_pallets: [
      {
        accessorKey: 'id',
        header: texts[lang]['DATA_F_P'],
        Cell: ({ cell }) => cell.getValue() ?? 'sin informacion',
      },
      {
        accessorKey: 'estado',
        header: texts[lang]['DATA_F_S'],
        Cell: ({ cell }) => cell.getValue() ?? 'sin informacion',
      },
      {
        accessorKey: 'lpn',
        header: texts[lang]['DATA_F_L'],
      },
      {
        accessorKey: 'sku',
        header: texts[lang]['DATA_F_SK'],
      },
      {
        accessorKey: 'producto',
        header: texts[lang]['DATA_F_D'],
      },
      {
        accessorKey: 'market_code',
        header: texts[lang]['DATA_F_CM'],
      },
      {
        accessorKey: 'market_description',
        header: texts[lang]['DATA_F_DN'],
      },
      {
        accessorKey: 'ingreso',
        header: texts[lang]['DATA_F_I'],
      },
      {
        accessorKey: 'fecha_pallet',
        header: texts[lang]['DATA_F_FP'],
      },
      {
        accessorKey: 'mayor_elaboracion',
        header: texts[lang]['DATA_F_PA'],
      },
      {
        accessorKey: 'menor_elaboracion',
        header: texts[lang]['DATA_F_PR'],
      },
      {
        accessorKey: 'vencimiento',
        header: texts[lang]['DATA_F_VA'],
      },
      {
        accessorKey: 'menor_vencimiento',
        header: texts[lang]['DATA_F_VR'],
      },
      {
        accessorKey: 'cantidad',
        header: texts[lang]['DATA_F_C'],
      },
      {
        accessorKey: 'weight',
        header: texts[lang]['DATA_F_PT'],
      },
      {
        accessorKey: 'net_weight',
        header: texts[lang]['DATA_F_PL'],
      },
      {
        accessorKey: 'gross_weight',
        header: texts[lang]['DATA_F_PB'],
      },
      {
        accessorKey: 'tipo_pallet',
        header: texts[lang]['DATA_F_TP'],
      },
    ],

    data_format_SKU: [
      {
        accessorKey: 'description',
        header: texts[lang]['COLUM_LABEL'],
      },
      {
        accessorKey: 'sku',
        header: texts[lang]['COLUM_SKU'],
      },
    ],
  }

  return <ContextoData.Provider value={STATE}>{children}</ContextoData.Provider>
}

export default DataContextProvider
