import React, { useContext } from 'react'
import Transelevador from '../Transelevador/Transelevador'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { ReglaDraw } from './Regla.styled'

const Regla = () => {
  const { warehouse } = useContext(ContextoAlmacen)

  const calles = [...Array(warehouse.calles)]

  return (
    <>
      <ReglaDraw cantCalles={calles.length}>
        <Transelevador />
        {calles.map((item, i) => (
          <div style={{ width: '40px', textAlign: 'center', height: 'inherit' }} key={i}>
            {warehouse.orientacion === 'derecha' ? calles.length - i : i + 1}
          </div>
        ))}
      </ReglaDraw>
    </>
  )
}

export default Regla
