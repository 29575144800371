import moment from "moment/moment"

export const returnDaysFromDate = (date) => {
    let array_date = date?.split("/").reverse()
    let date_format = new Date(array_date)
    let date_ms = date_format.getTime()
    let current_date = Date.now()
    let diferencia_dias = Math.round(((date_ms - current_date) / 86400000))
    return isNaN(diferencia_dias) ? null : diferencia_dias
}


export const retencionCalc = (date, endDate) => {
    if (!date) return
    let start = moment(date)
    let end = endDate ? moment(endDate) : moment()
    const diferenciaHoras = end.diff(start, 'hours');
    const cantidadDias = Math.floor(diferenciaHoras / 24);
    const cantidadHoras = diferenciaHoras % 24;
    return `${cantidadDias} dias ${cantidadHoras} hs`
}

