import { useEffect, useState } from 'react'
import styles from '../../../../../index.module.css'
import { colorAndDaysFrom, colorAndDaysUpTo } from '../../../../../utils/colorFunctions'

const useColor = (id, posiciones, nivel_actual, filtro) => {
    const [color, setColor] = useState('')
    const [days, setDays] = useState('')

    useEffect(() => {
        setColor(styles.empty_box)
        setDays("")
        if (!posiciones || !posiciones[id]) return;
        if (posiciones[id]?.estado === 3) {
            setColor(styles.box_anulado)
            return
        }
        if (posiciones[id]?.estado === 2) {
            setColor(styles.box_bloqueado)
            return
        }
        if (posiciones[id]?.estado === 0) {
            setColor(styles.empty_box)
            return
        }

        switch (filtro?.type) {
            case 'clave_color':
                let item = posiciones[id]?.[filtro.property]
                let col = filtro.colors.find(e => e.valor === item)
                setColor(col?.color || styles.primary)
                break;
            case 'dias_hasta':
                let [color_hasta, dias_hasta] = colorAndDaysUpTo(posiciones[id]?.[filtro.property], filtro.date_max)
                setColor(color_hasta)
                setDays(dias_hasta)
                break
            case 'dias_desde':
                let [color_desde, dias_desde] = colorAndDaysFrom(posiciones[id]?.[filtro.property], filtro.date_max)
                setColor(color_desde)
                setDays(dias_desde)
                break
            case 'custom_color':
                if (!filtro.array_data) return
                let found = filtro.array_data.find(_id => _id === id)
                found && setColor(filtro.color_selected)
                break;
            default:
                if (posiciones[id]?.estado === 0) setColor(styles.empty_box)
                if (posiciones[id]?.estado === 1) setColor(styles.primary)
                if (posiciones[id]?.estado === 3) setColor(styles.box_anulado)
                break;
        }
        // eslint-disable-next-line
    }, [filtro, nivel_actual, posiciones])

    return { color, days }

}
export default useColor
