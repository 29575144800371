import React, { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'

const ErrorOrSuccessMsg = (props) => {
  const { type, msg, display } = props
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(display)
  }, [props, display])

  if (show)
    return (
      <Alert
        variant={type === 'error' ? 'danger' : 'success'}
        onClose={() => setShow(false)}
        dismissible
        style={{ marginTop: '20px', wordBreak: 'break-word' }}
      >
        {msg}
      </Alert>
    )

  return <></>
}

export default ErrorOrSuccessMsg
