import { ResponsiveBar } from '@nivo/bar'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { cambiarFormatoBar, generateOptionGroupsLevels } from '../../../utils/dashboard'
import { getPositionsThunk } from '../../Almacen/redux/slices/positions/thunk/positionsThunk'
import { tokens } from '../../../theme'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext'
import { Box } from '@mui/material'

const BarSlots = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const colors = tokens(warehouse.dark)
  const dispatch = useDispatch()
  const posiciones = useSelector((state) => state.positions.positions)
  //Convertimos el array clave-valor en array normal
  const posiciones2 = cambiarFormatoBar(posiciones)
  //Formato necesario para el grafico
  const posicionesConvertidas = generateOptionGroupsLevels(posiciones2)
  //Lista de colores personalizada
  const colorArr = [colors.verdes[300], colors.verdes[200], colors.verdes[100]]

  useEffect(() => {
    dispatch(getPositionsThunk()) //eslint-disable-next-line
  }, [])

  return (
    <Box height='500px'>
      <ResponsiveBar
        data={posicionesConvertidas || []}
        keys={['Libre', 'Ocupado', 'Inhabilitado']}
        indexBy='nivel'
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={colorArr}
        theme={{
          tooltip: {
            color: colors.grey[100],
            container: {
              background: colors.primary[900],
            },
          },
          axis: {
            domain: {
              line: {
                stroke: colors.grey[100],
              },
            },
            legend: {
              text: {
                fill: colors.grey[100],
              },
            },
            ticks: {
              line: {
                stroke: colors.grey[100],
                strokeWidth: 1,
              },
              text: {
                fill: colors.grey[100],
              },
            },
          },
          legends: {
            text: {
              fill: colors.grey[100],
            },
          },
        }}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'nivel',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'posiciones',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role='application'
        ariaLabel='Nivo bar chart demo'
        barAriaLabel={function (e) {
          return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
        }}
      />
    </Box>
  )
}

export default BarSlots
