import { useContext } from 'react'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import Box from '../Box/Box'
import Transportador from '../Transportador/Transportador'
import { useSelector } from 'react-redux'
import styles from './Calle.module.css'

const Calle = ({ calle, lado }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const profundidades = [...Array(warehouse.profundidades)]
  const nivel_actual = useSelector((state) => state.gridView.actualLevel)

  // --------------------- CAMBIOS DE PROFUNDIDAD SEGUN ORIENTACION DEL DEPOSITO ----------------------------
  const getProfundidad = (i) => {
    if (warehouse.orientacion === 'derecha') {
      if (+lado === 0) {
        return ++i
      } else {
        return profundidades.length - i
      }
    } else {
      if (+lado === 0) {
        return profundidades.length - i
      } else {
        return ++i
      }
    }
  }
  // -------------- ********************************* --------------------------------------------------

  // ---------------------------- DIBUJAR TRANSPORTADOR O BOX ----------------------------------------
  const drawTransporte = () => {
    const transporte = warehouse.transportador.find(
      (t) => t.calle === calle && t.lado === +lado && t.nivel === nivel_actual
    )
    if (transporte) {
      return <Transportador calle={calle} lado={lado} {...transporte} />
    } else {
      return profundidades.map((p, i) => <Box key={i} _id={`L${lado}C${calle}N${nivel_actual}P${getProfundidad(i)}`} />)
    }
  }

  // ------------------------------------ ************************** ------------------------------------------

  return <div className={styles.calleDefault}>{drawTransporte()}</div>
}

export default Calle
