import { configureStore } from "@reduxjs/toolkit";
import adminPosSlice from "../../pages/AdminPosiciones/redux/slices/configuracion/adminPosSlice.js";
import filtersSlice from "../../pages/Almacen/redux/slices/filters/filtersSlice.js";
import gridViewSlice from "../../pages/Almacen/redux/slices/gridView/gridViewSlice.js";
import NotificationsSlice from "../../pages/Almacen/redux/slices/notifications/NotificationsSlice.js";
import operationSlice from "../../pages/Almacen/redux/slices/operation/operationSlice.js";
import positionClickSlice from "../../pages/Almacen/redux/slices/positionClickSlice/positionClickSlice.js";
import positionsReducer from "../../pages/Almacen/redux/slices/positions/positionsSlice.js"
import configuracionAutonomoSlices from "../../pages/Configuracion/redux/slices/configuracion/configuracionAutonomoSlices.js";
import segmentacionSlice from "../../pages/Configuracion/redux/slices/segmentacion/segmentacionSlice.js";
import movimientosSlice from "../../pages/Dashboard/redux/slices/movimientosSlice/movimientosSlice.js";
import listaDescargaSlice from "../../pages/Descarga/redux/slices/lista/listaDescargaSlice.js";

export default configureStore({
    reducer: {
        positions: positionsReducer,
        gridView: gridViewSlice,
        filter: filtersSlice,
        positionClick: positionClickSlice,
        operation: operationSlice,
        lista_descarga: listaDescargaSlice,
        configuracion_autonomo: configuracionAutonomoSlices,
        segmentacion: segmentacionSlice,
        movimientosData: movimientosSlice,
        pallets: adminPosSlice,
        notifications: NotificationsSlice

    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

})