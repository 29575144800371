import React, { useContext, useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import styles from './ControlOperacion.module.css'
import { BsSliders } from 'react-icons/bs'
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import useSocket from '../../../../custom-hooks/useSocket'
import { useDispatch, useSelector } from 'react-redux'
import { setDestino, setModoOperacion, setOrigen } from '../../redux/slices/operation/operationSlice'
import { texts } from '../../../../i18n'

const ControlOperaciones = () => {
  const operacion = useSelector((state) => state.operation)
  const { origen, destino, modo_operacion } = operacion
  const [paradaEmergenciaBtn, setParadaEmergenciaBtn] = useState(false)
  const [busy, setBusy] = useState(false)
  const [ready, setReady] = useState(false)
  const [error, setError] = useState(false)
  const [activeAutonomo, SetactiveAutonomo] = useState({ modo: '', state: false })

  const [disableEjecutar, setDisableEjecutar] = useState(false)
  const [open, setOpen] = useState(false)

  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  const socket = useSocket()
  const dispatch = useDispatch()

  const activarAutonomo = (modo) => {
    socket.emit('action:modo-autonomo', { modo: modo, state: !activeAutonomo.state })
  }

  const handleClick = () => {
    socket.emit(`action:modo-automatico`)
    dispatch(setModoOperacion('automatico'))
  }

  // ----------------**** SOCKETS ****-----------------------
  useEffect(() => {
    socket.on('state:modo-operacion', (modo) => {
      dispatch(setModoOperacion(modo))
    })
    socket.on('state:modo-autonomo', (modo) => {
      SetactiveAutonomo(modo)
    })
    socket.on('state:emergencia', (status) => setParadaEmergenciaBtn(status))
    socket.on('state:busy', (status) => setBusy(status))
    socket.on('state:falla', (status) => setError(status))
    socket.on('state:ready', (status) => setReady(status))

    return () => {
      socket.off('state:modo-operacion')
      socket.off('state:modo-autonomo')
      socket.off('state:emergencia')
      socket.off('state:busy')
      socket.off('state:falla')
      socket.off('state:ready')
    }
    // eslint-disable-next-line
  }, [socket])

  const paradaActiva = () => {
    socket.emit(`action:emergencia`)
  }
  const cancelarHandler = () => {
    socket.emit(`action:cancelar`)
  }
  const resetHandler = () => {
    socket.emit(`action:reset`)
  }

  //  ----- EJECUTAR INTERCAMBIO ---------------
  const ejecutarHandler = () => {
    if (modo_operacion === 'automatico' && origen && destino && ready && !busy) {
      setDisableEjecutar(true)
      setTimeout(() => {
        setDisableEjecutar(false)
      }, 5000)

      socket.emit('action:intercambio', { origen: origen, destino: destino }, (res) => {
        if (res) {
          dispatch(setOrigen(null))
          dispatch(setDestino(null))
        }
      })
    }
  }

  const style = {
    color: warehouse.dark ? '#f5f5f5' : '#000',
    backgroundColor: warehouse.dark ? 'rgb(41 41 41)' : '#f5f5f5',
  }

  // -------------*************************-------------------

  return (
    <>
      <Card style={{ background: 'none' }} className={warehouse.dark && styles.allCardDark}>
        <Card.Header className={`${styles.headerCard} ${warehouse.dark && styles.headerCardDark}`}>
          {texts[lang]['LAT_CONTROL_TITLE']} <BsSliders />
        </Card.Header>
        <Card.Body className={`${styles.cardBody} ${warehouse.dark && styles.cardBodyDark}`}>
          <div className={styles.seccionBody} style={{ gridColumn: '1 / span 1' }}>
            <Button
              style={{ padding: '6px' }}
              size='small'
              onClick={handleClick}
              variant='contained'
              color={modo_operacion === 'automatico' ? 'custom' : 'neutral'}
            >
              {texts[lang]['LAT_CONTROL_B_AUTOMA']}
            </Button>
            <Button
              style={{ padding: '6px' }}
              size='small'
              onClick={() => setOpen(true)}
              color={activeAutonomo.state ? 'custom' : 'neutral'}
              variant='contained'
            >
              {texts[lang]['LAT_CONTROL_B_AUTON']}
            </Button>
            <Button
              style={{ padding: '6px' }}
              className={paradaEmergenciaBtn ? styles.paradaEmergencia : ''}
              onClick={paradaActiva}
              color='peligro'
              variant='contained'
            >
              {texts[lang]['LAT_CONTROL_B_S']}
            </Button>
          </div>
          <div className={styles.seccionBody} style={{ gridColumn: '2 / span 1' }}>
            <Button
              style={{ padding: '5px' }}
              variant='contained'
              color='info'
              onClick={ejecutarHandler}
              disabled={busy || !ready || disableEjecutar || (modo_operacion !== 'automatico' && true)}
            >
              {texts[lang]['LAT_CONTROL_B_E']}
            </Button>
            <Button style={{ padding: '5px' }} variant='contained' color='neutral' onClick={cancelarHandler}>
              {texts[lang]['LAT_CONTROL_B_C']}
            </Button>
            <Button style={{ padding: '5px' }} variant='contained' color='info' onClick={resetHandler}>
              {texts[lang]['LAT_CONTROL_B_R']}
            </Button>
          </div>
          <div className={styles.seccionBody} style={{ gridColumn: '3 / span 1' }}>
            <ButtonGroup
              orientation='vertical'
              aria-label='vertical outlined button group'
              className={styles.grupoBoton}
            >
              <Button
                className={warehouse.dark ? styles.buttonEstadosDark : styles.buttonEstados}
                color={warehouse.dark ? 'active' : 'neutral'}
                size='large'
                key='one'
              >
                {texts[lang]['LAT_CONTROL_E_O']}

                <div
                  className={`${styles.muestraColor} ${warehouse.dark && styles.muestraColorDark} ${
                    busy && styles.activo
                  }`}
                ></div>
              </Button>
              <Button
                className={warehouse.dark ? styles.buttonEstadosDark : styles.buttonEstados}
                color={warehouse.dark ? 'active' : 'neutral'}
                size='large'
                key='two'
              >
                {texts[lang]['LAT_CONTROL_E_L']}
                <div
                  className={`${styles.muestraColor} ${warehouse.dark && styles.muestraColorDark} ${
                    ready && styles.activo
                  }`}
                ></div>
              </Button>
              <Button
                className={warehouse.dark ? styles.buttonEstadosDark : styles.buttonEstados}
                color={warehouse.dark ? 'active' : 'neutral'}
                size='large'
                key='three'
              >
                {texts[lang]['LAT_CONTROL_E_E']}
                <div
                  className={`${styles.muestraColor} ${warehouse.dark && styles.muestraColorDark} ${
                    error && styles.activoError
                  }`}
                ></div>
              </Button>
            </ButtonGroup>
          </div>
        </Card.Body>
      </Card>
      {/*  -------Modal Autonomo---------- */}
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='dialog-title'>
        <DialogTitle id='dialog-title' sx={style}>
          {texts[lang]['LAT_CONTROL_M_AUTON']}
        </DialogTitle>
        <DialogContent sx={style}>
          <DialogContentText sx={style}>{texts[lang]['LAT_CONTROL_M_TEXT']}</DialogContentText>
        </DialogContent>
        <DialogActions sx={style}>
          <Button
            autoFocus
            onClick={() => activarAutonomo('carga')}
            variant={activeAutonomo.modo === 'carga' && activeAutonomo.state ? 'contained' : 'outlined'}
            color='custom'
          >
            {texts[lang]['LAT_CONTROL_M_C']}
          </Button>

          <Button
            onClick={() => activarAutonomo('descarga')}
            variant={activeAutonomo.modo === 'descarga' && activeAutonomo.state ? 'contained' : 'outlined'}
            color='custom'
          >
            Descarga
          </Button>
          <Button
            onClick={() => activarAutonomo('transfer')}
            variant={activeAutonomo.modo === 'transfer' && activeAutonomo.state ? 'contained' : 'outlined'}
            color='custom'
          >
            Transferencia
          </Button>

        </DialogActions>
      </Dialog>
    </>
  )
}

export default ControlOperaciones
