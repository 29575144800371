import { Error } from '@mui/icons-material'
import { debounce, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { MaterialReactTable } from 'material-react-table'
import React from 'react'
import { useMemo } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContextoAlmacen } from '../../contexts/Almacen/AlmacenContext'
import { ContextoData } from '../../contexts/Data/DataContext'
import { texts } from '../../i18n'
import { translate_table } from '../Almacen/components/Busqueda/Translate'
import { getPalletsThunk } from './redux/slices/configuracion/thunk/getPalletsThunk'
import { updateRowValues } from './services/services'

const AdminSKU = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  const { data_format_SKU } = useContext(ContextoData)
  const dispatch = useDispatch()
  const pallets = useSelector((state) => state.pallets.pallets)
  const columns = useMemo(() => [...data_format_SKU], [data_format_SKU])
  const [columnVisibility, setColumnVisibility] = useState(
    localStorage.getItem('admin_SKU_visibility') ? JSON.parse(localStorage.getItem('admin_SKU_visibility')) : {} // SAVE VISIBILITY IN LOCAL STORAGE
  )

  useEffect(() => {
    localStorage.setItem('admin_SKU_visibility', JSON.stringify(columnVisibility))
  }, [columnVisibility])

  useEffect(() => {
    dispatch(getPalletsThunk()) //eslint-disable-next-line
  }, [])

  // Convertimos el objeto positios a un array de objetos SKU y Productos no repetidos
  const options =
    (pallets &&
      [
        ...new Set(
          Object.values(pallets)
            .filter(({ estado, sku }) => estado && sku)
            .map(({ sku, producto }) => JSON.stringify({ sku, producto }))
        ),
      ].map((obj) => {
        const { sku, producto } = JSON.parse(obj)
        return { description: producto, sku }
      })) ||
    []

  const handleSaveRow = async (value) => {
    const { row, values, exitEditingMode } = value
    const { original } = row

    try {
      await updateRowValues(original, values)
      dispatch(getPalletsThunk())
      exitEditingMode()
      setValidationErrors({})
    } catch (error) {
      setValidationErrors({ error: texts[lang]['ERROR_UPDATE'] })
    }
  }

  const [validationErrors, setValidationErrors] = useState({})

  return (
    <>
      <MaterialReactTable
        enableFilterMatchHighlighting
        columns={columns}
        data={options || []}
        localization={translate_table}
        getRowId={(row) => row.id}
        state={{ columnVisibility }}
        onColumnVisibilityChange={setColumnVisibility}
        muiTableContainerProps={{
          sx: { height: '60vh', backgroundColor: warehouse.dark ? '#292929' : '#fff', width: '95vw' },
        }}
        initialState={{
          density: 'compact',
          pagination: {
            pageIndex: 0,
            pageSize: 50,
          },
        }}
        muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
        muiToolbarAlertBannerProps={{
          sx: {
            color: warehouse?.dark === true && '#ffffff',
            backgroundColor: warehouse?.dark === true && '#356161',
          },
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <Stack alignItems={'center'} paddingTop='1rem' direction='row' gap='5px' color='red'>
            {validationErrors.error && (
              <>
                <Error />
                <Typography sx={{ color: 'red !important' }}>{validationErrors.error}</Typography>
              </>
            )}
          </Stack>
        )}
        editingMode='row'
        enableEditing
        onEditingRowSave={handleSaveRow}
        muiTableBodyCellEditTextFieldProps={{
          error: !!validationErrors.error, //highlight mui text field red error color
          helperText: validationErrors.error, //show error message in helper text.
          required: true,
          type: 'text',
          onChange: debounce(
            (event) => () => {
              const value = event.target.value
              //validation logic
              if (!value) {
                setValidationErrors((prev) => ({ ...prev, error: 'Is Required' }))
              } else {
                delete validationErrors.error
                setValidationErrors({ ...validationErrors })
              }
            },
            300
          ),
        }}
      />
    </>
  )
}

export default AdminSKU
