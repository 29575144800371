const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const getMachineLogs = async (lang) => {
    try {
        const response = await fetch(`${SERVER_URL}/machine/${lang}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result.result
    } catch (e) {
        throw Error('Fallo conexión al servidor ', e)
    }

}