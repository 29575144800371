import React, { useEffect, useRef } from 'react'
import styles from './FloatInformation.module.css'

const FloatInformation = ({ children, refGrilla: grillaRef }) => {
  const refBox = useRef(null)

  useEffect(() => {
    const floatingBox = refBox.current
    const grilla = grillaRef.current

    function handleScroll() {
      const left = grilla.scrollLeft
      const top = grilla.scrollTop
      const rect = grilla.getBoundingClientRect()
      const grillaWidth = rect.width
      const grillaTop = rect.top
      const floatingBoxWidth = floatingBox.offsetWidth
      const floatingBoxHeight = floatingBox.offsetHeight
      floatingBox.style.left = `${grillaWidth + left - floatingBoxWidth - 20}px`
      floatingBox.style.top = `${grillaTop + top - floatingBoxHeight - 60}px`
    }

    grilla.addEventListener('scroll', handleScroll)
    return () => {
      grilla.removeEventListener('scroll', handleScroll)
    } // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.floatingBox} ref={refBox}>
      <div className={styles.boxContent}>{children}</div>
    </div>
  )
}

export default FloatInformation
