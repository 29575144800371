import React, { useState } from 'react'
import { RiFileExcel2Fill } from 'react-icons/ri'
import { Check, PictureAsPdf } from '@mui/icons-material'
import { saveToPDF, saveToXLS } from '../../services/services'
import { IconButton, Tooltip } from '@mui/material'

const changeColumnName = (obj, columns) => {
  const newObj = {}
  for (let property in obj) {
    let { header } = columns.find((c) => c.accessorKey === property) || { header: 'nnName' }
    newObj[header] = obj[property]
  }
  return newObj
}

const ExportButtons = ({ data, title, columns }) => {
  const [alert, setAlert] = useState(null)

  // --------------- GENERAR XLS Y PDF ---------------
  const xlsSendServer = async (data) => {
    const saveXLS = await saveToXLS({ title: title, datos: data.map((row) => changeColumnName(row.original, columns)) })
    if (saveXLS.Status === 'Success') {
      setAlert({ display: true, message: 'Se generó archivo XLS ir al directorio Documentos/xls para visualizar' })
      setTimeout(() => setAlert(null), 5000)
    }
  }

  const pdfSendServer = async (data) => {
    const savePDF = await saveToPDF({ title: title, datos: data.map((row) => row.original) })
    if (savePDF.Status === 'Success') {
      setAlert({ display: true, message: 'Se generó archivo PDF ir al directorio Documentos/pdf para visualizar' })
      setTimeout(() => setAlert(null), 5000)
    }
  }

  return (
    <div style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
      <Tooltip title='Exportar XLS' arrow>
        <IconButton onClick={() => xlsSendServer(data)}>
          <RiFileExcel2Fill />
        </IconButton>
      </Tooltip>
      <Tooltip title='Exportar PDF' arrow>
        <IconButton onClick={() => pdfSendServer(data)}>
          <PictureAsPdf />
        </IconButton>
      </Tooltip>
      {alert && (
        <div style={{ width: 'auto', display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Check fontSize='inherit' />
          {alert.message}
        </div>
      )}
    </div>
  )
}

export default ExportButtons
