import { useContext } from 'react'
import BarraLateral from '../Almacen/components/BarraLateral/BarraLateral'
import styles from './Dashboard.module.css'
import { ContextoAlmacen } from '../../contexts/Almacen/AlmacenContext'
import TabDashboard from './components/TabPanel'

const Dashboard = () => {
  const { warehouse } = useContext(ContextoAlmacen)

  return (
    <div
      className={`${styles.container} ${warehouse.dark ? styles.containerDark : styles.containerWhite} `}
      style={{ height: 'auto' }}
    >
      <div>
        <TabDashboard />
      </div>
      <BarraLateral all={false} />
    </div>
  )
}

export default Dashboard
