import React, { useContext, useEffect, useState } from 'react'
import Menu from '../../../../components/Menu/Menu'
import { BiSearchAlt } from 'react-icons/bi'
import { TiInfoLargeOutline } from 'react-icons/ti'
import BusquedaModal from '../Busqueda/BusquedaModal'
import { Badge, Tooltip } from '@mui/material'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import InfoModal from '../InfoModal/InfoModal'
import styles from './BarraLateral.module.css'
import { getNotificationsThunk } from '../../redux/slices/notifications/thunks/notificationsThunks'
import { useDispatch, useSelector } from 'react-redux'
import useSocket from '../../../../custom-hooks/useSocket'
import OffCanvasUpdated from '../OffCanvasUpdated'
import Notificaciones from '../Notificaciones'
import { Notifications } from '@mui/icons-material'
import { clearNotifications } from '../../services/services'
import { texts } from '../../../../i18n'

const BarraLateral = ({ all = true }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  const [open, setOpen] = useState(false)
  const [visibleInfo, setVisibleInfo] = useState(false)
  const [visibleTooltip, setVisibleTooltip] = useState(false);

  const dispatch = useDispatch()
  const count = useSelector((state) => state.notifications.count)
  const socket = useSocket()
  const [offCanvasVisible, setOffCanvasVisible] = useState(false)
  const [type, setType] = useState('')

  useEffect(() => {

    const timerMostrarTooltip = setTimeout(() => setVisibleTooltip(true), 1500);

    const timerOcultarTooltip = setTimeout(() => setVisibleTooltip(false), 7000);

    return () => {
      clearTimeout(timerMostrarTooltip);
      clearTimeout(timerOcultarTooltip);
    };
  }, []);
  

  useEffect(() => {
    socket.on('state:new-notification', () => {
      dispatch(getNotificationsThunk())
    })

    return () => socket.off('state:new-notification') // eslint-disable-next-line
  }, [socket])

  return (
    <>
      <div className={styles.barraLateral}>
        <div className={styles.barraIconos}>
          <Menu />

          {all && (
            <>
              <Tooltip title='Búsqueda' arrow placement='left'>
                <button onClick={() => setOpen(true)} className={styles.botonBarra}>
                  <BiSearchAlt className={`${styles.icono} ${warehouse.dark && styles.iconoDark}`} />
                </button>
              </Tooltip>

              <Tooltip title={count ? 'Notificaciones nuevas' : 'Notificaciones'} arrow placement='left'>
                <button
                  onClick={() => {
                    setOffCanvasVisible(true)
                    setType('estados')
                  }}
                  className={styles.botonBarra}
                >
                  {count ? (
                    <Badge badgeContent={count} color='primary' anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                      <Notifications
                        className={`${styles.icono} ${warehouse.dark && styles.iconoDark}`}
                        style={{ width: '1.6rem', height: '1.6rem' }}
                      />
                    </Badge>
                  ) : (
                    <Notifications
                      className={`${styles.icono} ${warehouse.dark && styles.iconoDark}`}
                      style={{ width: '1.6rem', height: '1.6rem' }}
                    />
                  )}
                </button>
              </Tooltip>
              {/* <Tooltip title='Lista Descarga' arrow placement='left'>
                <button onClick={() => setVisible(true)} className={styles.botonBarra}>
                  <DownloadingOutlined className={`${styles.icono} ${warehouse.dark && styles.iconoDark}`} />
                </button>
              </Tooltip>
              <Tooltip title='Transferencias' arrow placement='left'>
                <button onClick={() => setTransferencia(true)} className={styles.botonBarra}>
                  <MoveDown className={`${styles.icono} ${warehouse.dark && styles.iconoDark}`} />
                </button>
              </Tooltip> */}
            </>
          )}
        </div>
        {all && (
          <Tooltip title={texts[lang]['TOOLTIP_INFO_ICON']} placement="top-start" arrow open={visibleTooltip}>
          <button className={styles.botonBarra} onClick={() => setVisibleInfo(true)}>
            <TiInfoLargeOutline
              className={`${styles.icono} ${warehouse.dark && styles.iconoDark}`}
              style={{ width: '1.6rem', height: '1.6rem' }}
            />
          </button>
          </Tooltip>
        )}
      </div>
      {type === 'estados' && (
        <OffCanvasUpdated
          visible={offCanvasVisible}
          setVisible={setOffCanvasVisible}
          title={texts[lang]['OFFC_NOT_TITLE']}
          icon={<Notifications />}
          optFunction={clearNotifications}
          notif={true}
        >
          <Notificaciones />
        </OffCanvasUpdated>
      )}
      <BusquedaModal open={open} setOpen={setOpen} />
      <InfoModal visible={visibleInfo} setVisible={setVisibleInfo} />
      {/* <OffCanvasDescargas visible={visible} setVisible={setVisible} />
      <OffCanvasTransferList visible={transferencia} setVisible={setTransferencia} /> */}
    </>
  )
}

export default BarraLateral
