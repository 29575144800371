import { positionsAdapter, filterAdapter, putLoadUnitAdapter, getFilterAdapter } from "../adapters/adapters"

// --------------------------- SERVICIOS ALMACEN --------------------------------
const SERVER_URL = process.env.REACT_APP_SERVER_URL
export const getPositions = async () => {
    try {
        const response = await fetch(SERVER_URL + '/slot',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return positionsAdapter(result.result)
    } catch (e) {
        throw Error('Fallo conexión al servidor')
    }

}

// +++++++++++++++++++++++++ FILTROS ++++++++++++++++++++++++++++++++++++++++++++++++++
// ------- SEGUN EL FILTRO APLICADO PEDIMOS EL ARRAY DE DATOS QUE NECESITAMOS O UNA FECHA-------
export const getFilterData = async (prop) => {
    const property = getFilterAdapter(prop)
    try {
        const response = await fetch(SERVER_URL + `/pallets/filter?prop=${property}`)
        const result = await response.json()
        return filterAdapter(result)
    } catch (e) {
        console.log(e)
        throw Error(`No se puede obtener filtro de ${prop} error al consultar servicio`)
    }
}

export const getFilterDate = async (prop) => {
    const property = getFilterAdapter(prop)
    try {
        const response = await fetch(SERVER_URL + `/pallets/filter?date=${property}`)
        const result = await response.json()
        return filterAdapter(result, prop)
    } catch (e) {
        console.log(e)
        throw Error(`No se puede obtener filtro de ${prop} error al consultar servicio`)
    }
}
// ----------------- ************************************ ---------------




// +++++++++++++++++++++++++ LOAD UNIT  ++++++++++++++++++++++++++++++++++++++++++++++++++
export const postLoadUnit = async (data) => {
    // const loadUnit = loadUnitAdapter(data)  // Adapter LoadUnit send to server
    try {
        const response = await fetch(SERVER_URL + "/pallets/",
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result
    } catch (e) {
        throw Error('No se puedo publicar nueva unidad de carga')
    }
}

export const putLoadUnit = async (data, id) => {
    const loadUnit = putLoadUnitAdapter(data, id)  // Adapter LoadUnit send to server
    try {
        const response = await fetch(SERVER_URL + "/pallets/relocation",
            {
                method: 'PUT',
                body: JSON.stringify(loadUnit),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result
    } catch (e) {
        throw Error('No se puedo actualizar la unidad de carga error en la DB')
    }
}

export const deleteLoadUnit = async (lpn) => {
    try {
        const response = await fetch(SERVER_URL + "/pallets/",
            {
                method: 'DELETE',
                body: JSON.stringify({ lpn, status: 'manual' }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result
    } catch (e) {
        throw Error('No se pudo eliminar la unidad de carga error en la DB')
    }
}
// ----------------- ************************************ ---------------


// ------------------------- SLOT -------------------------------------
export const putSlot = async (id, status) => {
    try {
        const response = await fetch(SERVER_URL + "/slot/",
            {
                method: 'PUT',
                body: JSON.stringify({ id, status }),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
        const result = await response.json()
        return result
    } catch (e) {
        throw Error('No se puedo actualizar la posición, error en la DB')
    }
}

// ----------------- ************************************ ---------------


// ---------------- GET DATOS FROM API WMS -----------------------

export const getDataAPI = async (lpn) => {
    try {
        const response = await fetch(`${SERVER_URL}/pallets/fromclient/${lpn}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        // console.log("client-----", result)
        return result.result
    } catch (e) {
        throw Error('Fallo conexión al servidor')
    }

}

// ----------------------------- GET DATA FROM LPN ----------------------

export const getDataFromLPN = async (lpn) => {
    try {
        const response = await fetch(`${SERVER_URL}/pallets/${lpn}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result.result
    } catch (e) {
        throw Error('Fallo conexión al servidor')
    }

}

// ----------------------------- PUT LOAD UNIT BY LPN ----------------------

export const putDataByLPN = async (lpn, data) => {
    try {
        const response = await fetch(SERVER_URL + "/pallets/" + lpn,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
        const result = await response.json()
        return result
    } catch (e) {
        throw Error('No se puedo actualizar la posición, error en la DB')
    }

}

export const getNotifications = async () => {
    try {
        const response = await fetch(`${SERVER_URL}/notifications/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result.Result
    } catch (e) {
        throw Error('Fallo conexión al servidor')
    }

}
// ----------------------------- Clear Notifications ----------------------
export const clearNotifications = async () => {
    try {
        const response = await fetch(SERVER_URL + "/notifications/",
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
        const result = await response.json()
        return result
    } catch (e) {
        throw Error('No se puedo actualizar notificaciones')
    }

}