
export class PositionsAdmin {
    constructor(position) {
        this.id = position.slot_id
        this.estado = position.status
        this.tipo_celda = position.comment || 'bajo'
        this.lpn = position['lpn']
        this.cantidad = position['qty']
        this.sku = position['sku']
        this.ingreso = position['createdAt']?.slice(0, 10)
        this.fecha_pallet = position['pallet_date']?.slice(0, 10)
        this.mayor_elaboracion = position['earliest_production_date']?.slice(0, 10)
        this.menor_elaboracion = position['lastest_production_date']?.slice(0, 10)
        this.vencimiento = position['earliest_expiration_date']?.slice(0, 10)
        this.menor_vencimiento = position['lastest_expiration_date']?.slice(0, 10)
        this.producto = position['description']
        this.market_code = position['market_code']
        this.market_description = position['market_description']
        this.net_weight = position['net_weight'] || 'Sin Informacion'
        this.gross_weight = position['gross_weight'] || 'Sin Informacion'
        this.tipo_pallet = position['pallet_type'] || 'Sin Informacion'

    }
}

export const palletsAdapter = (pallets) => {
    return pallets.map(p => new PositionsAdmin(p))

}