import React, { useContext, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { tokens } from '../../../theme'
import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext'
import { FormatListNumberedOutlined, QrCode2Outlined } from '@mui/icons-material'
import { texts } from '../../../i18n'
const ProductBox = ({ cantidadTotal, hoverText }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  const colors = tokens(warehouse.dark)

  const [showHoverText, setShowHoverText] = useState(false)

  const handleMouseEnter = () => setShowHoverText(true)
  const handleMouseLeave = () => setShowHoverText(false)

  return (
    <Box width='100%' m='0 30px'>
      <Box display='flex' justifyContent='space-between' sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Box style={{ flex: 4 }}>
          <Typography variant='h4' marginBottom='.5rem'>
            <FormatListNumberedOutlined sx={{ color: colors.verdes[200], fontSize: '32px' }} />
          </Typography>

          <Typography variant='h4' fontWeight='bold' sx={{ color: colors.grey[100] }}>
            {cantidadTotal}
          </Typography>
        </Box>

        <Box style={{ flex: 4 }} justifyContent='center'>
          <QrCode2Outlined sx={{ color: colors.verdes[200], fontSize: '75px' }} />
        </Box>

        <Box
          justifyContent='space-between'
          style={{ flex: 4 }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            position: 'relative',
          }}
        >
          {showHoverText && (
            <Typography
              sx={{
                position: 'absolute',
                bottom: '100%',
                left: 0,
                backgroundColor: colors.primary[900],
                padding: '0.5rem',
                borderRadius: '0.25rem',
                fontSize: '1rem',
                width: '10rem',
                fontWeight: '200',
                height: '5rem',
              }}
            >
              {hoverText}
            </Typography>
          )}
          <Typography variant='h5' fontWeight='bold' sx={{ color: colors.verdes[300] }} marginBottom='.4rem'>
            {texts[lang]['PRO_BOX_TITLE']}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ProductBox
