import { createTheme, ThemeProvider } from '@mui/material'
import { useContext, useMemo } from 'react'
import { ContextoAlmacen } from '../Almacen/AlmacenContext'

export const AppThemeMui = ({ children }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          background: {
            default: warehouse?.dark === false ? '#ffffff' : '#121212e6',
          },
          custom: {
            light: 'rgb(34, 185, 185)',
            main: 'rgb(34, 185, 185)',
            dark: 'rgb(33 139 139)',
            contrastText: '#ffffff',
          },
          active: {
            light: 'rgb(32, 130, 130)',
            main: 'rgb(32, 130, 130)',
            dark: 'rgb(32, 130, 130)',
            contrastText: '#ffffff',
          },
          ctto: {
            main: '#ffb300',
            contrastText: '#ffffff',
          },
          neutral: {
            main: '#64748B',
            contrastText: '#fff',
          },
          peligro: {
            main: 'rgb(172, 0, 0)',
            contrastText: '#fff',
          },
          text: {
            primary: warehouse?.dark === false ? '#777777' : '#ffffffb6',
          },
        },
        typography: {
          body1: { color: warehouse?.dark === false ? '#777777 !important' : '#ffffffb6 !important' },
          customH4: {
            color: warehouse?.dark === false ? '#777777' : '#ffffffb6',
            fontWeight: 400,
            fontSize: '2.125rem',
          },
          h4: {
            fontWeight: 600,
            fontSize: '1.25rem',
            lineHeight: 1.4,
          },
          h5: {
            fontWeight: 600,
            fontSize: '1rem',
            lineHeight: 1.5,
          },
          customH6: {
            color: warehouse?.dark === false ? '#777777' : '#ffffffb6',
            fontWeight: 400,
            fontSize: '2.125rem',
          },
          h6: {
            color: warehouse?.dark === false ? '#777777' : '#ffffffb6',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.57,
          },
          caption: {
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 1.66,
            letterSpacing: '0.03333em',
            color: warehouse?.dark === false ? '#777777' : '#ffffffb6',
          },
        },
        components: {
          MuiTableRow: {
            styleOverrides: {
              root: {
                '&:hover': {
                  backgroundColor: warehouse?.dark === false ? '#e5f6fd !important' : '#000000e6 !important',
                  filter: 'saturate(0.5)',
                },
                '&.Mui-selected': {
                  backgroundColor: warehouse?.dark === false ? '#e5f6fd !important' : '#356161 !important',
                },
              },
            },
          },
          MuiCheckbox: {
            styleOverrides: {
              root: {
                color: warehouse?.dark === false ? '' : '#e5f6fd !important',
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                color: warehouse?.dark === false ? '' : 'inherit !important',
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              list: {
                backgroundColor: warehouse?.dark === false ? '' : 'black !important',
              },
            },
          },
          MuiInput: {
            styleOverrides: {
              root: {
                '&:after': {
                  borderBottom: warehouse?.dark === true && 'solid 2px #46afaf6b',
                },
              },
            },
          },
          MuiAlert: {
            // styleOverrides: {
            //   root: {
            //     color: warehouse?.dark === true && '#ffffff',
            //     backgroundColor: warehouse?.dark === true && '#356161',
            //   },
            // },
          },
          MuiButton: {
            styleOverrides: {
              textPrimary: {
                color: warehouse?.dark === true && '#46afaf6b',
              },
              root: {
                '&.Mui-disabled': {
                  backgroundColor: warehouse?.dark && '#363636',
                  color: warehouse?.dark && 'rgb(255 255 255 / 26%)',
                },
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              switchBase: {
                color: warehouse?.dark === true && '#46afaf6b !important',
              },
              track: {
                backgroundColor: warehouse?.dark === true && '#46afaf6b !important',
              },
            },
          },
          MuiBadge: {
            styleOverrides: {
              badge: {
                backgroundColor: 'rgb(34, 185, 185)',
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                color: warehouse?.dark === true && '#ffffff',
              },
            },
          },
          MuiTabs: {
            styleOverrides: {
              indicator: {
                backgroundColor: 'rgb(33 139 139)',
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  color: warehouse?.dark ? '#ffffffb6 !important' : '#363636',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#d1eaff',
                },
              },
            },
          },
          MuiToolbar: {
            styleOverrides: {
              root: {
                backgroundColor: warehouse?.dark ? 'rgb(41 41 41) !important' : 'white !important',
              },
            },
          },
          MuiAutocomplete: {
            styleOverrides: {
              paper: {
                backgroundColor: warehouse?.dark ? 'rgb(41 41 41) !important' : 'white !important',
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                backgroundColor: warehouse?.dark ? 'rgb(32 32 32) !important' : '#f5f5f5 !important',
              },
            },
          },
        },
      }),
    [warehouse.dark]
  )

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
