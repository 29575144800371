export default function decodeID(id) {
    // Ej. id: L0C10N11P1
    if (!id) { return }
    let lado = Number(id.substring(id.lastIndexOf('L') + 1, id.lastIndexOf('C')))
    let calle = Number(id.substring(id.lastIndexOf('C') + 1, id.lastIndexOf('N')))
    // Si ID no tiene profundidad (Utilizado para bahias completas. Ej. input: L0C10N11)
    if (id.indexOf('P') === -1) {
        let nivel = Number(id.substring(id.lastIndexOf('N') + 1, id.length))
        return {
            lado,
            nivel,
            calle
        }
    } else {
        let nivel = Number(id.substring(id.lastIndexOf('N') + 1, id.lastIndexOf('P')))
        return {
            id: id,
            lado,
            nivel,
            calle,
            prof: Number(id.substring(id.lastIndexOf('P') + 1, id.length))
        }
    }
}