import React from 'react'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext'
import { tokens } from '../../../theme'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getDataMovimientosThunk } from '../redux/slices/movimientosSlice/thunk/getDataMovimientosThunk'
import { agruparPorOperacionYMes } from '../../../utils/dashboard'
import { ResponsiveLine } from '@nivo/line'

const LineMovements = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const colors = tokens(warehouse.dark)
  const dispatch = useDispatch()

  const agregarColorSegunMovimientoLine = (arr = [], campo) => {
    if (arr.length === 0) {
      return
    }

    let color
    arr.forEach((elemento) => {
      switch (elemento[campo]) {
        case 'entrada':
          color = colors.verdes[300]
          break

        case 'salida':
          color = colors.verdes[200]
          break

        case 'reubicaciones':
          color = colors.blueAccent[300]
          break

        case 'rechazos' || 'rechazado':
          color = colors.redAccent[400]
          break
        case 'total':
          color = colors.verdes[400]
          break

        default:
          //console.log('agregarColorSegunMovimientoPie: no existe el elemento ' + arr.campo)
          break
      }

      elemento['color'] = color
    })

    return arr
  }

  useEffect(() => {
    dispatch(getDataMovimientosThunk()) //eslint-disable-next-line
  }, [])

  const historial = useSelector((state) => state.movimientosData.data)
  const historialFiltrado = historial.filter(
    (h) =>
      h.operation !== 'guardado' &&
      h.operation !== 'trans' &&
      h.operation !== 'posicion entrada' &&
      h.operation !== 'edicion manual'
  )
  let historialLine = agruparPorOperacionYMes(historialFiltrado)

  historialLine = agregarColorSegunMovimientoLine(historialLine, 'id')
  //eslint-disable-next-line
  const now = new Date()
  return (
    <>
      <ResponsiveLine
        data={historialLine}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          reverse: false,
        }}
        theme={{
          tooltip: {
            color: colors.grey[100],
            container: {
              background: colors.primary[900],
            },
          },
          legends: {
            text: {
              fill: colors.grey[100],
            },
          },
          axis: {
            ticks: {
              line: {
                stroke: '#777777',
                strokeWidth: 1,
              },
              text: {
                fill: colors.grey[100],
              },
            },
            legend: {
              text: {
                fill: colors.grey[100],
              },
            },
          },
        }}
        colors={{ datum: 'color' }}
        yFormat=' >-.2f'
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'meses',
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'cantidad',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        pointSize={9}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ theme: 'background' }}
        pointLabel='y'
        pointLabelYOffset={0}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 105,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </>
  )
}

export default LineMovements
