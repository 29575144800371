import styled from "styled-components";

export const ReglaDraw = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${(props) => `repeat(${props.cantCalles}, 40px);`};
  gap: 2rem;
  // padding: 0 1rem 0 1rem;
  background: rgb(118, 118, 118);
  width: fit-content;
  height: 16px;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
  margin: 1rem
`;
