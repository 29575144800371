export const texts = {
    // PORTUGUES
    PT: {
        MENU_GRILLA: "Quadro de operações",
        MENU_DASH: "Dashboard",
        MENU_ADM: "Gerente de paletes",
        MENU_ADM_SKU: "Gerente de SKU",
        MENU_DESC: "Ordem de descarga",
        MENU_AUT: "Configuração autônoma",


        // --------- GRILLA ----------------
        TITLE_INFO: "Informações do Palete",
        OFFC_INFO_P: "Posição",
        OFFC_INFO_P_O: "Ocupado",
        OFFC_INFO_P_L: "Livre",
        OFFC_INFO_P_B: "Bloqueado",
        OFFC_INFO_P_I: "Desabilitado",

        TOOLTIP_INFO_ICON: "Clique aqui para receber mais informações",

        OFFC_INFO_E: "Estado",
        OFFC_INFO_TP: "Tipo de Posição",

        OFFC_INFO_BUTTON_ING: "Inserir Palete",
        OFFC_INFO_BUTTON_REING: "Reinserir Palete",

        DATA_F_P: "Posição",
        DATA_F_S: "Status",
        DATA_F_L: "LPN",
        DATA_F_SK: "SKU",
        DATA_F_D: "Descrição",
        DATA_F_CM: "Cód de Mercado",
        DATA_F_DN: "Desc do Mercado",
        DATA_F_I: "Entrada",
        DATA_F_U: "Data de movimentação",
        DATA_F_PA: "Produção Antiga",
        DATA_F_PR: "Produção Recente",
        DATA_F_VA: "Vencimento Antiga",
        DATA_F_VR: "Vencimento Recente",
        DATA_F_C: "Quantidade",
        DATA_F_PT: "Peso Total",
        DATA_F_PL: "Peso Liquido",
        DATA_F_PB: "Peso Bruto",
        DATA_F_TP: "Tipo de Pallet",
        DATA_F_ID: "Id",
        DATA_F_CS: "Posição de Origem",
        DATA_F_DS: "Posição de Destino",
        DATA_F_O: "Operação",
        DATA_F_OB: "Observacion",
        DATA_F_FP: "Paleta de dátiles",



        LAT_FILTRO_T: "Filtros da Grade",
        LAT_FILTRO_ACT: "Filtros Ativados",
        LAT_FILTRO_SIN_ACT: "Nenhum Filtro Ativado",
        LAT_FILTRO_S: "Sem Filtro",
        LAT_FILTRO_P: "Produto",
        LAT_FILTRO_V: "Vencimento mais Antigo",
        LAT_FILTRO_I: "Entrada",
        LAT_FILTRO_E: "Filtro Especial",

        LAT_OPERA_T: "Operação de Troca",
        LAT_OPERA_O: "Origem",
        LAT_OPERA_D: "Destino",
        LAT_OPERA_INF_T: "Informação de Origem",
        LAT_OPERA_SELEC: "Selecione posições de origem e destino para iniciar uma operação",

        LAT_CONTROL_TITLE: "Controle de Operações",
        LAT_CONTROL_B_AUTOMA: "Automático",
        LAT_CONTROL_B_AUTON: "Autônomo",
        LAT_CONTROL_B_S: "Parar",
        LAT_CONTROL_B_E: "Executar",
        LAT_CONTROL_B_C: "Cancelar",
        LAT_CONTROL_B_R: "Redefinir",
        LAT_CONTROL_E_O: "Ocupado",
        LAT_CONTROL_E_L: "Livre",
        LAT_CONTROL_E_E: "Erro",

        LAT_CONTROL_M_AUTON: "Modo Autônomo",
        LAT_CONTROL_M_C: "Carga",
        LAT_CONTROL_M_TEXT: "Ativar o modo autônomo de carga",

        OFFC_NOT_TITLE: "Notificações",

        BARRA_INF_C: "Rua",
        BARRA_INF_N: "Nivel",
        BARRA_INF_P: "Profundidade",

        // MODAL INFORMACION SIMULACION 
        MODAL_TITLE: "Instruções do simulador",
        MODAL_CHARGUE_MODE_TITLE: "Ativar o modo de carga do transelevador",
        MODAL_CHARGUE_MODE_INSTRUCTION_ONE: "Vá para o Controle de operações e pressione o botão AUTÔNOMO.",    
        MODAL_CHARGUE_MODE_INSTRUCTION_TWO: "Nas opções que aparecem, clique no botão UPLOAD.",
        MODAL_DOWNLOAD_MODE_TITLE:"Ativar o modo de descarga do transelevador",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_ONE:"Acesse os paletes disponíveis usando o botão SEARCH.",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_TWO:"Uma vez na lista, selecione os paletes a serem descarregados e pressione DOWNLOAD LIST.",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_THREE:"Vá para a lista Download Orders (Pedidos de download) no Menu (canto superior direito da tela).",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_FOUR:"Selecione os paletes que você enviou anteriormente para a lista para confirmar o descarregamento.",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_FIVE:"Selecione os downloads a serem feitos na lista e pressione o botão CONFIRM.",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_SIX:"Clique no botão AUTÔNOMO no Controle de operações e selecione a opção Download.",


        //EDTBOX

        EDIT_BOX_TITLE: "Editar Posição",
        EDIT_BOX_TAB_E: "Editar",
        EDIT_BOX_TAB_C: "Mudar Posição",
        EDIT_BOX_EDIT_T: "Editar Pallet",
        EDIT_BOX_EDIT_TIPE: "Tipo de Pallet",
        EDIT_BOX_EDIT_TIPE_B: "Baixo",
        EDIT_BOX_EDIT_TIPE_A: "Alto",
        EDIT_BOX_EDIT_G: "Salvar",
        EDIT_BOX_EDIT_E: "Excluir",
        EDIT_BOX_CAMBIAR_P: "Posição Atual",
        EDIT_BOX_CAMBIAR_N: "Nova Posição",
        EDIT_BOX_CAMBIAR_E: "Estado da Posição",
        EDIT_BOX_CAMBIAR_SELECT: "Selecione uma opção",

        EDIT_BOX_ERROR_1: "Erro: O pallet que você está tentando adicionar já tem uma posição atribuída",
        EDIT_BOX_ERROR_2: "Não foi possível obter dados do banco de dados, falha na atualização",
        EDIT_BOX_ERROR_3: "Erro: Não é possível excluir esta posição, LPN não disponível",
        // --------- END GRILLA ----------------

        // --------- DASHBOARD -----------------

        TAB_CAP: "Capacidade",
        TAB_MOV: "Movimentos",

        TITLE_CAP: "Painel de controle de disponibilidade",
        SUBT_CAP: "Capacidade de armazenamento em tempo real",

        STAT_BOX_D: "Espaço disponível",
        H_STAT_BOX_D: "Proporção de posições livres e totais.",

        STAT_BOX_O: "Espacio Ocupado",
        H_STAT_BOX_O: "Proporção entre as posições ocupadas e o total de posições.",

        STAT_BOX_I: "Espaço desativado",
        H_STAT_BOX_I: "Relação entre posições desativado e totais.",

        PRO_BOX_TITLE: "Numero do SKU",
        H_PRO_BOX_TITLE: "Número total de produtos diferentes no depósito",

        TITLE_GRAP_C_F: "Capacidade do Armazém",
        SUBT_GRAP_C_F: "Exibição da posição completa",

        TITLE_GRAP_C_L: "Capacidade do Armazém por Nível",
        SUBT_GRAP_C_L: "Status da posição por nível",

        TITLE_PROD_TABLE: "Produtos",
        SUBT_PROD_TABLE: "Quantidade total de produtos no depósito",

        TABLE_PROD_COLUMN_S: "Sku",
        TABLE_PROD_COLUMN_P: "Produto",
        TABLE_PROD_COLUMN_N: "Número de paletes",
        TABLE_PROD_COLUMN_U: "Stock Total",

        TITLE_MOV: "Painel de controle movimentos",
        SUBT_MOV: "Todos os movimentos de entrada, saída e realocação",

        TITLE_ACTIV_H: "Atividade de última hora",
        H_TITLE_ACTIV_H: "Número de movimentos na última hora",

        TITLE_ACTIV_24: "Actividade de última 24 horas",
        H_TITLE_ACTIV_24: "Número de movimentos nas últimas 24 horas",


        TITLE_ACTIV_W: "Atividade nos últimos 7 dias",
        H_TITLE_ACTIV_W: "Número de movimentos nos últimos 7 dias",

        TITLE_ACTIV_M: "Atividade do Último Mês",
        H_TITLE_ACTIV_M: "Número de movimentos nos últimos 30 dias",

        ICON_TEXTS_E: "Entradas",
        ICON_TEXTS_S: "Saídas",
        ICON_TEXTS_R: "Reubic.",
        ICON_TEXTS_RJ: "Rejeições",

        TITLE_MOV_TABLE: "Rastreamento de operações de paletes",
        SUBT_MOV_TABLE: "Controle de todos os movimentos por paletes, entradas, saídas, realocações e rejeições.",

        TITLE_GRAP_M_M: "Registro de Movimentos Mensais",
        SUBT_GRAP_M_M: "Evolução mensal dos movimentos de entrada, saída e realocação",

        TITLE_GRAP_M_D: "Registro de Movimentos Diários",
        SUBT_GRAP_M_D: "Movimentos diários de entrada, saída e realocação ao longo de 7 dias",

        TITLE_GRAP_M_H: "Registro de Movimentos por Hora",
        SUBT_GRAP_M_H: "Análise horária dos movimentos nas últimas 24 horas",

        // --------- END DASHBOARD -----------------

        // ----------------------- ADMIN SKU --------------------
        COLUM_LABEL: "Descrição",
        COLUM_SKU: "SKU",
        ERROR_UPDATE: "Erro de atualização",
        // ----------------------- END ADMIN SKU -------------------

        // -------------------- MACHINE LOGS TABLE ---------------
        COLUM_USER: "Usuário",
        COLUM_MACHINE: "Máquina",
        COLUMN_CODE: "Código",
        COLUMN_TYPE: "Tipo",
        COLUMN_TIME: "Data e Hora",

                // -------------------- NAVBAR ---------------

        NAVBAR_NAME_ALMACEN: "Armazém",
        NAVBAR_NAME_TRANSELEVADOR: "Transelevador",
        NAVBAR_NAME_LINEAS: "Linhas",
        NAVBAR_NAME_LINEA_INGRESO: "Linha entrada",
        NAVBAR_NAME_LINEA_SALIDA: "Linha saída",
        NAVBAR_NAME_LINEA_SISTEMAS: "Sistemas",
        NAVBAR_NAME_LINEA_DISPOSITIVOS: "Dispositivos",
        NAVBAR_NAME_LINEA_AJUSTES: "Ajustes",

        //--------------------------TUNTELNAVBAR-----------------------
        NAVBAR_NAME__TUNEL_AUTONOMO: "Autônomo",
        NAVBAR_NAME_TUNEL:"Túnel",
        NAVBAR_NAME_TUNEL_REFRIGERACION:"Resfriamento",
        NAVBAR_NAME_TUNEL_POSICIONES:"Posições",

        // --------------------------ORDEN DE DESCARGA ------------------

        
        DESCAGA_MENU_ORDER: "ORDEM DE DESCARREGAMENTO",
        DESCARGA_MENU_GENERATE: "GERAR PEDIDOS",
        DESCARGA_MENU_PENDIENTE: "PENDENTE",
        DESCARGA_MENU_CONFIRM: "CONFIRMADO",
        DESCARGA_MENU_HISTORIC: "HISTÓRICO",

        //---------------------------LISTA DE DESCARGA BOTONES -------------

        DESCARGA_BOTTONS_CONFIRM: "CONFIRMAR",
        DESCARGA_BOTTONS_DELETE: "REMOVER",
        DESCARGA_BOTTONS_OPTIMIZAR: "ORDEM DE OTIMIZAÇÃO",
        DESCARGA_BOTTONS_CONFIRM_TOOLTIP: "Confirmar pedido completo",
        DESCARGA_MODAL_TITLE_CONFIRM_ORDER: "Confirmar pedido",
        DESCARGA_BOTTONS_DELETE_TOOLTIP: "Remover paletes selecionados",
        DESCARGA_BOTTONS_OPTIMIZAR_TOOLTIP: "Otimize o pedido, gere uma extração mais rápida.",
        DESCARGA_BOTTONS_MODALCONFIRM: "O comando será confirmado para execução. Observe que, se o equipamento estiver no modo autônomo, o processo de download será iniciado imediatamente após a confirmação.",

        //-----------------------------LISTA DE DESCARGA -----------------

        DATA_F_DES_POSICION: "Posição",
        DATA_F_DES_DEPOSITO: "depósito",
        DATA_F_DES_LPN: "LPN",
        DATA_F_DES_SKU: "SKU",
        DATA_F_DES_CLIENTE: "Cliente",
        DATA_F_DES_INGRESO: "Renda",
        DATA_F_DES_RETENCION: "Retenção",
        DATA_F_DES_VENCIMIENTO: "Vencimento",
        DATA_F_DES_BULTOS: "Nódulos",
        DATA_F_DES_CONTENIDO: "Conteúdo",
        DATA_F_DES_PESO: "Peso Tomado",
        DATA_F_DES_PALLET: "Tipo de palete",
        DATA_F_DES_PRODUCTO: "Produto",

        //-----------------------------LISTA DE DESCARGA HISTORIAL -----------------
        
        
        DATA_F_DES_HIS_ORDEN: "Número de pedido",
        DATA_F_DES_HIS_STATE: "Estado",
        DATA_F_DES_HIS_ORIGIN: "Origem",
        DATA_F_DES_HIS_PRIORIDAD: "Prioridade",

        //-----------------------------LISTA DE DESCARGA ORDERS -----------------

        DESCARGA_ORDERS_LABEL_PRODUCT: "Produto",
        DESCARGA_ORDERS_LABEL_PALLETS: "Quantidade de paletes",
        DESCARGA_ORDERS_LABEL_CAJAS: "Caixas/Conteúdo",
        DESCARGA_ORDERS_BUTTON_ANADIR:"ADD",
        DESCARGA_ORDERS_BUTTON_CONFIRM_TOOLTIP: "Enviar seleção como ordem pendente",
        DESCARGA_ORDERS_BUTTON_DELETE_TOOLTIP: "Excluir linhas selecionadas.",

        //-----------------------------LISTA DE DESCARGA PENDIENTES -----------------

        DESCARGA_PENDIENTE_PALLETSORDEN: "Paletes em ordem:",
        DESCARGA_PENDIENTE_MODALMSG: "O comando será confirmado para execução. Observe que, se o equipamento estiver no modo autônomo, o processo de download será iniciado imediatamente após a confirmação.",
        DESCARGA_PENDIENTE_MODAL_TITLE: "Confirmar downloads selecionados",
        DESCARGA_PENDIENTE_CANCELMSG: "Deseja cancelar os downloads 'pendentes' selecionados?",
        DESCARGA_PENDIENTE_CONFIRM_TOOLTIP: "Confirmar pedido completo",
        DESCARGA_PENDIENTE_DELETE_TOOLTIP: "Cancelar pedidos",

        //-----------------------------LISTA DE DESCARGA CONFIRMADAS -----------------

        DESCARGA_CONFIRMADAS_BUTTON_CANCEL: "CANCELAR",
        DESCARGA_CONFIRMADAS_MODAL_TITLE: "Cancelar downloads selecionados",
        DESCARGA_CONFIRMADAS_MODAL_TEXT: "Deseja cancelar os descarregamentos selecionados? Somente os paletes que estiverem no status 'na fila' serão cancelados; se houver um palete no status 'transit', ele será descarregado normalmente até que a operação seja concluída.",
        
        //-----------------------------CONFIGURACION DEL AUTONOMO -----------------

        CONFIG_AUTONOMO_REGLAS: "Regras gerais",
        CONFIG_AUTONOMO_CONFIG_CARGAS: "Configuração de carga",
        CONFIG_AUTONOMO_CONFIG_REGLAS: "Regras gerais de armazenamento, agrupamento em faixas de acordo com as propriedades agrupadas e a tolerabilidade.",
        CONFIG_AUTONOMO_TIPOPALLETS: "Tipo de entrada de palete",
        CONFIG_AUTONOMO_DEPOSITO: "Depósito",
        CONFIG_AUTONOMO_TUNEL: "Túnel",
        CONFIG_AUTONOMO_MODALCONFIRM_TITLE: "Confirmar a configuração",
        CONFIG_AUTONOMO_MODALCONFIRM_MSG: "A configuração a seguir será aplicada para as próximas operações de carga e descarga autônomas.",
        CONFIG_AUTONOMO_DESCARGA: "Download da configuração",
        CONFIG_AUTONOMO_DESCARGA_TEXT: "Otimize as descargas por número de movimentos ou FEFO rigoroso.",
        CONFIG_AUTONOMO_DESCARGA_PRIORIDAD: "Prioridade de download",
        CONFIG_AUTONOMO_DESCARGA_LINEA: "Linea 2",
        CONFIG_AUTONOMO_DESCARGA_PROPIEDADES: "Propriedades",
        CONFIG_AUTONOMO_DESCARGA_GRUPOS: "Grupos gerados por",
        CONFIG_AUTONOMO_DESCARGA_TOLERANCIA: "Tolerância dias de maturidade",

        //-----------------------------BUSQUEDA MODAL -----------------

        BUSQUEDA_MODAL_BUTTON_FILTRO: "Filtro especial",
        BUSQUEDA_MODAL_BUTTON_DESCARGA: "Lista de downloads",
        BUSQUEDA_MODAL_BUTTON_MSG: "Os paletes selecionados foram adicionados à Ordem de Descarga"


    },

    // INGLES 
    ENG: {
        MENU_GRILLA: "Operation",
        MENU_DASH: "Dashboard",
        MENU_ADM: "Admin Pallets",
        MENU_ADM_SKU: "Admin SKU",
        MENU_DESC:"Download Order",
        MENU_AUT: "Standalone Configuration",

        // --------- GRILLA ----------------
        TITLE_INFO: "Pallet Information",
        OFFC_INFO_P: "Position",
        OFFC_INFO_P_O: "Occupied",
        OFFC_INFO_P_L: "Free",
        OFFC_INFO_P_B: "Blocked",
        OFFC_INFO_P_I: "Disabled",

        TOOLTIP_INFO_ICON: "Click here to receive more information",

        OFFC_INFO_E: "Status",
        OFFC_INFO_TP: "Position Type",

        OFFC_INFO_BUTTON_ING: "Enter Pallet",
        OFFC_INFO_BUTTON_REING: "Re-enter Pallet",

        DATA_F_P: "Position",
        DATA_F_S: "Status",
        DATA_F_L: "LPN",
        DATA_F_SK: "SKU",
        DATA_F_D: "Description",
        DATA_F_CM: "Market Code",
        DATA_F_DN: "Market Description",
        DATA_F_I: "Entry",
        DATA_F_U: "Movement Date",

        DATA_F_PA: "Old Production",
        DATA_F_PR: "Recent Production",
        DATA_F_VA: "Old Expiry",
        DATA_F_VR: "Recent Expiry",
        DATA_F_C: "Quantity",
        DATA_F_PT: "Total Weight",
        DATA_F_PL: "Net Weight",
        DATA_F_PB: "Gross Weight",
        DATA_F_TP: "Pallet Type",
        DATA_F_ID: "Id",
        DATA_F_CS: "Source Position",
        DATA_F_DS: "Destination Position",
        DATA_F_O: "Operation",
        DATA_F_OB: "Observation",
        DATA_F_FP: "Pallet Date",



        LAT_FILTRO_T: "Grid Filters",
        LAT_FILTRO_ACT: "Filters Activated",
        LAT_FILTRO_SIN_ACT: "No Filters Activated",
        LAT_FILTRO_S: "No Filter",
        LAT_FILTRO_P: "Product",
        LAT_FILTRO_V: "Oldest Expiry",
        LAT_FILTRO_I: "Entry",
        LAT_FILTRO_E: "Special Filter",

        LAT_OPERA_T: "Exchange Operation",
        LAT_OPERA_O: "Origin",
        LAT_OPERA_D: "Destination",
        LAT_OPERA_INF_T: "Origin Information",
        LAT_OPERA_SELEC: "Select origin and destination positions to initiate an operation",

        LAT_CONTROL_TITLE: "Operations Control",
        LAT_CONTROL_B_AUTOMA: "Automatic",
        LAT_CONTROL_B_AUTON: "Autonomous",
        LAT_CONTROL_B_S: "Stop",
        LAT_CONTROL_B_E: "Execute",
        LAT_CONTROL_B_C: "Cancel",
        LAT_CONTROL_B_R: "Reset",
        LAT_CONTROL_E_O: "Busy",
        LAT_CONTROL_E_L: "Free",
        LAT_CONTROL_E_E: "Error",

        LAT_CONTROL_M_AUTON: "Autonomous Mode",
        LAT_CONTROL_M_C: "Load",
        LAT_CONTROL_M_TEXT: "Activate autonomous load mode",

        OFFC_NOT_TITLE: "Notifications",

        BARRA_INF_C: "Street",
        BARRA_INF_N: "Level",
        BARRA_INF_P: "Depth",

        // MODAL INFORMACION SIMULACION 
        MODAL_TITLE: "Simulator instructions",
        MODAL_CHARGUE_MODE_TITLE: "Activate the load mode of the stacker crane",
        MODAL_CHARGUE_MODE_INSTRUCTION_ONE: "Go to Operations Control and press the AUTONOMOUS button.",    
        MODAL_CHARGUE_MODE_INSTRUCTION_TWO: "In the options that appear, click on the UPLOAD button.",
        MODAL_DOWNLOAD_MODE_TITLE:"Activate the unloading mode of the stacker crane.",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_ONE:"Access the available pallets using the SEARCH button.",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_TWO:"Once in the list, select the pallets to be unloaded and press DOWNLOAD LIST",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_THREE:"Go to the Download Orders list in the Menu (upper right corner of the screen).",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_FOUR:"Select the pallets you previously sent to the list to confirm their unloading.",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_FIVE:"Select from the list the downloads to be performed and press the CONFIRM button.",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_SIX:"Click on the AUTONOMOUS button in Operations Control and select the Download option.",

        //EDITBOX

        EDIT_BOX_TITLE: "Edit Position",
        EDIT_BOX_TAB_E: "Edit",
        EDIT_BOX_TAB_C: "Change Position",
        EDIT_BOX_EDIT_T: "Edit Pallet",
        EDIT_BOX_EDIT_TIPE: "Pallet Type",
        EDIT_BOX_EDIT_TIPE_B: "Low",
        EDIT_BOX_EDIT_TIPE_A: "High",
        EDIT_BOX_EDIT_G: "Save",
        EDIT_BOX_EDIT_E: "Delete",
        EDIT_BOX_CAMBIAR_P: "Current Position",
        EDIT_BOX_CAMBIAR_N: "New Position",
        EDIT_BOX_CAMBIAR_E: "Position Status",
        EDIT_BOX_CAMBIAR_SELECT: "Select an option",

        EDIT_BOX_ERROR_1: "Error: The pallet you are trying to add already has an assigned position",
        EDIT_BOX_ERROR_2: "Could not retrieve data from the database, update failed",
        EDIT_BOX_ERROR_3: "Error: Cannot delete this position, LPN not available",

        // --------- END GRILLA -----------

        // --------- DASHBOARD -----------------

        TAB_CAP: "Capability",
        TAB_MOV: "Movements",

        TITLE_CAP: "Availability control panel",
        SUBT_CAP: "Know the storage capacity of the warehouse in real time",

        STAT_BOX_O: "Space Occupied",
        H_STAT_BOX_O: "Ratio between occupied and total positions in the warehouse.",

        STAT_BOX_D: "Space Available",
        H_STAT_BOX_D: "Ratio between free and total positions in the warehouse",

        STAT_BOX_I: "Space Disabled",
        H_STAT_BOX_I: "Ratio between disqualified and total positions in the warehouse.",

        PRO_BOX_TITLE: "Quantity of product types",
        H_PRO_BOX_TITLE: "Total quantity of different products in the warehouse",

        TITLE_GRAP_C_F: "Warehouse capacity",
        SUBT_GRAP_C_F: "Full position display",

        TITLE_GRAP_C_L: "Warehouse capacity per level",
        SUBT_GRAP_C_L: "Position status by level",

        TITLE_PROD_TABLE: "Products",
        SUBT_PROD_TABLE: "Total quantity of products in the warehouse",

        TABLE_PROD_COLUMN_S: "Sku",
        TABLE_PROD_COLUMN_P: "Product",
        TABLE_PROD_COLUMN_N: "Qty of pallets",
        TABLE_PROD_COLUMN_U: "Stock Total",

        TITLE_MOV: "Movement control panel",
        SUBT_MOV: "Analysis of entries, exits and relocations in the warehouse",

        TITLE_ACTIV_H: "Latest hour activity",
        H_TITLE_ACTIV_H: "All movements in latest hour",

        TITLE_ACTIV_24: "Activity last 24 hours",
        H_TITLE_ACTIV_24: "Number of movements in the last 24 hours",

        TITLE_ACTIV_W: "Activity last 7 days",
        H_TITLE_ACTIV_W: "Number of movements in the last 7 days",

        TITLE_ACTIV_M: "Activity Last Month",
        H_TITLE_ACTIV_M: "Number of movements in the last 30 days",

        ICON_TEXTS_E: "Entries",
        ICON_TEXTS_S: "Exits",
        ICON_TEXTS_R: "Relocation",
        ICON_TEXTS_RJ: "Rejections",

        TITLE_MOV_TABLE: "Operations tracking by pallet",
        SUBT_MOV_TABLE: "Movement control by pallets",

        TITLE_GRAP_M_M: "Monthly Movement Record",
        SUBT_GRAP_M_M: "Monthly evolution of incoming, outgoing, and relocation movements",

        TITLE_GRAP_M_D: "Daily Movement Record",
        SUBT_GRAP_M_D: "Daily incoming, outgoing, and relocation movements over 7 days",

        TITLE_GRAP_M_H: "Hourly Movement Record",
        SUBT_GRAP_M_H: "Hourly analysis of movements in the last 24 hours",

        // ---------END DASHBOARD -----------------

        // ----------------------- ADMIN SKU --------------------
        COLUM_LABEL: "Description",
        COLUM_SKU: "SKU",
        ERROR_UPDATE: "Error updating ",
        // ----------------------- END ADMIN SKU -------------------

        // -------------------- MACHINE LOGS TABLE ---------------
        COLUMN_USER: "User",
        COLUMN_MACHINE: "Machine",
        COLUMN_CODE: "Code",
        COLUMN_TYPE: "Type",
        COLUMN_TIME: "Date and Time",

                // -------------------- NAVBAR ---------------

        NAVBAR_NAME_ALMACEN: "Warehouse",
        NAVBAR_NAME_TRANSELEVADOR: "Stacker crane",
        NAVBAR_NAME_LINEAS: "Lines",
        NAVBAR_NAME_LINEA_INGRESO: "Income line",
        NAVBAR_NAME_LINEA_SALIDA: "Output line",
        NAVBAR_NAME_LINEA_SISTEMAS: "Systems",
        NAVBAR_NAME_LINEA_DISPOSITIVOS: "Devices",
        NAVBAR_NAME_LINEA_AJUSTES: "Settings",

        //--------------------------TUNTELNAVBAR-----------------------
        NAVBAR_NAME__TUNEL_AUTONOMO: "Self-employed",
        NAVBAR_NAME_TUNEL:"Tunnel",
        NAVBAR_NAME_TUNEL_REFRIGERACION:"Cooling",
        NAVBAR_NAME_TUNEL_POSICIONES:"Positions",


        // --------------------------ORDEN DE DESCARGA ------------------

        
        DESCAGA_MENU_ORDER: "ORDER OF UNLOADING",
        DESCARGA_MENU_GENERATE: "GENERATE ORDERS",
        DESCARGA_MENU_PENDIENTE: "PENDING",
        DESCARGA_MENU_CONFIRM: "CONFIRMED",
        DESCARGA_MENU_HISTORIC: "HISTORICAL",

        //---------------------------LISTA DE DESCARGA BOTONES -------------

        DESCARGA_BOTTONS_CONFIRM: "CONFIRM",
        DESCARGA_BOTTONS_DELETE: "DELETE",
        DESCARGA_BOTTONS_OPTIMIZAR: "OPTIMIZE ORDER",
        DESCARGA_BOTTONS_CONFIRM_TOOLTIP: "Confirm Complete Order",
        DESCARGA_MODAL_TITLE_CONFIRM_ORDER: "Confirm Order",
        DESCARGA_BOTTONS_DELETE_TOOLTIP: "Remove Selected Pallets",
        DESCARGA_BOTTONS_OPTIMIZAR_TOOLTIP: "Optimize Order, generate a faster extraction.",
        DESCARGA_BOTTONS_MODALCONFIRM: "The command will be confirmed for execution. Please note that if the unit is in stand-alone mode, the download process will start immediately upon confirmation.",

        //-----------------------------LISTA DE DESCARGA -----------------

        DATA_F_DES_POSICION: "Position",
        DATA_F_DES_DEPOSITO: "deposit",
        DATA_F_DES_LPN: "LPN",
        DATA_F_DES_SKU: "SKU",
        DATA_F_DES_CLIENTE: "Customer",
        DATA_F_DES_INGRESO: "Income",
        DATA_F_DES_RETENCION: "Retention",
        DATA_F_DES_VENCIMIENTO: "Expiration",
        DATA_F_DES_BULTOS: "Lumps",
        DATA_F_DES_CONTENIDO: "Content",
        DATA_F_DES_PESO: "Weight Taken",
        DATA_F_DES_PALLET: "Pallet Type",
        DATA_F_DES_PRODUCTO: "Product",

        //-----------------------------LISTA DE DESCARGA HISTORIAL -----------------
        
        
        DATA_F_DES_HIS_ORDEN: "Order No.",
        DATA_F_DES_HIS_STATE: "State",
        DATA_F_DES_HIS_ORIGIN: "Origin",
        DATA_F_DES_HIS_PRIORIDAD: "Priority",

        //-----------------------------LISTA DE DESCARGA ORDERS -----------------

        DESCARGA_ORDERS_LABEL_PRODUCT: "Product",
        DESCARGA_ORDERS_LABEL_PALLETS: "Quantity Pallets",
        DESCARGA_ORDERS_LABEL_CAJAS: "Boxes/Contents",
        DESCARGA_ORDERS_BUTTON_ANADIR:"ADD",
        DESCARGA_ORDERS_BUTTON_CONFIRM_TOOLTIP: "Send selection as backorder",
        DESCARGA_ORDERS_BUTTON_DELETE_TOOLTIP: "Delete selected lines.",

        //-----------------------------LISTA DE DESCARGA PENDIENTES -----------------

        DESCARGA_PENDIENTE_PALLETSORDEN: "Pallets in Order:",
        DESCARGA_PENDIENTE_MODALMSG: "The command will be confirmed for execution. Please note that if the unit is in stand-alone mode, the download process will start immediately upon confirmation.",
        DESCARGA_PENDIENTE_MODAL_TITLE: "Confirm selected downloads",
        DESCARGA_PENDIENTE_CANCELMSG: "Do you want to cancel selected 'pending' downloads?",
        DESCARGA_PENDIENTE_CONFIRM_TOOLTIP: "Confirm Complete Order",
        DESCARGA_PENDIENTE_DELETE_TOOLTIP: "Cancel orders",

        //-----------------------------LISTA DE DESCARGA CONFIRMADAS -----------------

        DESCARGA_CONFIRMADAS_BUTTON_CANCEL: "CANCEL",
        DESCARGA_CONFIRMADAS_MODAL_TITLE: "Cancel selected downloads",
        DESCARGA_CONFIRMADAS_MODAL_TEXT: "Do you want to cancel the selected unloadings? Only the pallets that are in 'in queue' status will be cancelled. If there is a pallet in 'transit' status, it will be unloaded normally until the operation is finished.",

        //-----------------------------CONFIGURACION DEL AUTONOMO -----------------

        CONFIG_AUTONOMO_REGLAS: "General Rules",
        CONFIG_AUTONOMO_CONFIG_CARGAS: "Load configuration",
        CONFIG_AUTONOMO_CONFIG_REGLAS: "General rules for storage, grouping in lanes according to grouped properties and tolerability.",
        CONFIG_AUTONOMO_TIPOPALLETS: "Type of pallet input",
        CONFIG_AUTONOMO_DEPOSITO: "Deposit",
        CONFIG_AUTONOMO_TUNEL: "Tunnel",
        CONFIG_AUTONOMO_MODALCONFIRM_TITLE: "Confirm configuration",
        CONFIG_AUTONOMO_MODALCONFIRM_MSG: "The following configuration will be applied for the next Autonomous loading and unloading operations.",
        CONFIG_AUTONOMO_DESCARGA: "Configuration Download",
        CONFIG_AUTONOMO_DESCARGA_TEXT: "Optimize discharges by number of movements or strict FEFO.",
        CONFIG_AUTONOMO_DESCARGA_PRIORIDAD: "Download Priority",
        CONFIG_AUTONOMO_DESCARGA_LINEA: "Line 2",
        CONFIG_AUTONOMO_DESCARGA_PROPIEDADES: "Properties",
        CONFIG_AUTONOMO_DESCARGA_GRUPOS: "Groups generated by",
        CONFIG_AUTONOMO_DESCARGA_TOLERANCIA: "Tolerance expiration days",

        //-----------------------------BUSQUEDA MODAL -----------------

        BUSQUEDA_MODAL_BUTTON_FILTRO: "Special filter",
        BUSQUEDA_MODAL_BUTTON_DESCARGA: "Download List",
        BUSQUEDA_MODAL_BUTTON_MSG: "The selected pallets were added to the Unloading Order"

    },




    //ESPAÑOL

    ES: {

        MENU_GRILLA: "Grilla Operación",
        MENU_DASH: "Dashboard",
        MENU_ADM: "Administrador de Pallets",
        MENU_ADM_SKU: "Admin SKU",
        MENU_DESC: "Orden de Descarga",
        MENU_AUT: "Configuración Autonomo",
        // --------- GRILLA ---------------

        TITLE_INFO: "Información del Pallet",
        OFFC_INFO_P: "Posición",
        OFFC_INFO_P_O: "Ocupado",
        OFFC_INFO_P_L: "Libre",
        OFFC_INFO_P_B: "Bloqueado",
        OFFC_INFO_P_I: "Inhabilitado",

        TOOLTIP_INFO_ICON: "Clickee aquí para recibir mas información",

        OFFC_INFO_E: "Estado",
        OFFC_INFO_TP: "Tipo de Posición",

        OFFC_INFO_BUTTON_ING: "Ingresar Pallet",
        OFFC_INFO_BUTTON_REING: "Reingresar Pallet",

        DATA_F_P: "Posición",
        DATA_F_S: "Status",
        DATA_F_L: "LPN",
        DATA_F_SK: "SKU",
        DATA_F_D: "Descripción",
        DATA_F_CM: "Cod de Mercado",
        DATA_F_DN: "Desc de Mercado",
        DATA_F_I: "Ingreso",
        DATA_F_U: "Fecha Movimiento",
        DATA_F_PA: "Producción Antigua",
        DATA_F_PR: "Producción Reciente",
        DATA_F_VA: "Vencimiento Antigua",
        DATA_F_VR: "Vencimiento Reciente",
        DATA_F_C: "Cantidad",
        DATA_F_PT: "Peso Total",
        DATA_F_PL: "Peso Liquido",
        DATA_F_PB: "Peso Bruto",
        DATA_F_TP: "Tipo de Pallet",
        DATA_F_ID: "Id",
        DATA_F_CS: "Posición de origen",
        DATA_F_DS: "Posición de Destino",
        DATA_F_O: "Operación",
        DATA_F_OB: "Observacion",
        DATA_F_FP: "Fecha de Pallet",



        LAT_FILTRO_T: "Filtros de grilla",
        LAT_FILTRO_ACT: "Filtros activado",
        LAT_FILTRO_SIN_ACT: "Ningun filtro activado",
        LAT_FILTRO_S: "Sin Filtro",
        LAT_FILTRO_P: "Producto",
        LAT_FILTRO_V: "Vencimiento más antiguo",
        LAT_FILTRO_I: "Ingreso",
        LAT_FILTRO_E: "Filtro Especial",

        LAT_OPERA_T: "Operación de Intercambio",
        LAT_OPERA_O: "Origen",
        LAT_OPERA_D: "Destino",
        LAT_OPERA_INF_T: "Información de origen",
        LAT_OPERA_SELEC: "Seleccione las posiciones de origen y destino para iniciar una operación",

        LAT_CONTROL_TITLE: "Control de Operaciones",
        LAT_CONTROL_B_AUTOMA: "Automatico",
        LAT_CONTROL_B_AUTON: "Autonomo",
        LAT_CONTROL_B_S: "Stop",
        LAT_CONTROL_B_E: "Ejecutar",
        LAT_CONTROL_B_C: "Cancelar",
        LAT_CONTROL_B_R: "Reset",
        LAT_CONTROL_E_O: "Ocupado",
        LAT_CONTROL_E_L: "Libre",
        LAT_CONTROL_E_E: "Error",

        LAT_CONTROL_M_AUTON: "Modo Autonomo",
        LAT_CONTROL_M_C: "Carga",
        LAT_CONTROL_M_TEXT: "Activar el modo autonomo de carga",

        OFFC_NOT_TITLE: "Notificaciones",

        BARRA_INF_C: "Calle",
        BARRA_INF_N: "Nivel",
        BARRA_INF_P: "Profundidad",

        // MODAL INFORMACION SIMULACION 
        MODAL_TITLE: "Instrucciones del simulador",
        MODAL_CHARGUE_MODE_TITLE: "Accionar el modo carga del transelevador",
        MODAL_CHARGUE_MODE_INSTRUCTION_ONE: "Diríjase al Control de Operaciones y oprima el botón AUTÓNOMO",    
        MODAL_CHARGUE_MODE_INSTRUCTION_TWO: "En las opciones que se despliegan, oprima el botón CARGA",
        MODAL_DOWNLOAD_MODE_TITLE:"Accionar el modo de descarga del transelevador",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_ONE:"Acceda a los pallets disponibles por medio del botón BÚSQUEDA",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_TWO:"Una vez en la lista, seleccione los pallets a descargar y oprima LISTA DE DESCARGA",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_THREE:"Diríjase a la lista Órdenes de Descarga en el Menú (Esquina superior derecha de la pantalla)",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_FOUR:"Seleccione los pallets que envió previamente a la lista para confirmar su descarga",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_FIVE:"Seleccione de la lista las descargas que realizará y oprima el botón CONFIRMAR",
        MODAL_DOWNLOAD_MODE_INSTRUCTION_SIX:"Oprima el botón AUTÓNOMO en Control de Operaciones y seleccione la opción Descarga",

        
        
    
        // EDITBOX

        EDIT_BOX_TITLE: "Editar Posición",
        EDIT_BOX_TAB_E: "Editar",
        EDIT_BOX_TAB_C: "Cambiar Posición",
        EDIT_BOX_EDIT_T: "Editar Pallet",
        EDIT_BOX_EDIT_TIPE: "Tipo de Pallet",
        EDIT_BOX_EDIT_TIPE_B: "Bajo",
        EDIT_BOX_EDIT_TIPE_A: "Alto",
        EDIT_BOX_EDIT_G: "Guardar",
        EDIT_BOX_EDIT_E: "Eliminar",
        EDIT_BOX_CAMBIAR_P: "Posición Actual",
        EDIT_BOX_CAMBIAR_N: "Posición Nueva",
        EDIT_BOX_CAMBIAR_E: "Estado de Posición",
        EDIT_BOX_CAMBIAR_SELECT: "Seleccione una opción",

        EDIT_BOX_ERROR_1: "Error: El pallet que quiere añadir ya tiene posición asignada",
        EDIT_BOX_ERROR_2: "No se pudo obtener datos de DB, fallo actualización",
        EDIT_BOX_ERROR_3: "Error: No se puede eliminar esta posición LPN no disponible",


        // ---------- END GRILLA ----------

        // --------- DASHBOARD -----------------

        TAB_CAP: "Capacidad",
        TAB_MOV: "Movimientos",

        TITLE_CAP: "Capacidad del Deposito",
        SUBT_CAP: "Conozca la capacidad del deposito en tiempo real",

        STAT_BOX_O: "Espacio Ocupado",
        H_STAT_BOX_O: "Relación entre espacio ocupado y total de posiciones.",

        STAT_BOX_D: "Espacio Disponible",
        H_STAT_BOX_D: "Relación entre espacio disponible y total de posiciones.",

        STAT_BOX_I: "Espacio Inhabilitado",
        H_STAT_BOX_I: "Relación entre espacio inahabilidato y el total de las posiciones.",

        PRO_BOX_TITLE: "Cantidad de SKU",
        H_PRO_BOX_TITLE: "Cantidad de SKU en deposito.",

        TITLE_GRAP_C_F: "Capacidad del deposito",
        SUBT_GRAP_C_F: "Visualización de todas las posiciones",

        TITLE_GRAP_C_L: "Capacidad del deposito por Nivel",
        SUBT_GRAP_C_L: "Visualización de las posiciones por nivel",

        TITLE_PROD_TABLE: "Productos",
        SUBT_PROD_TABLE: "Cantidad total de tipos de producto en el desposito",

        TABLE_PROD_COLUMN_S: "Sku",
        TABLE_PROD_COLUMN_P: "Producto",
        TABLE_PROD_COLUMN_N: "Número de pallets",
        TABLE_PROD_COLUMN_U: "Stock Total",

        TITLE_MOV: "Panel de control movimientos",
        SUBT_MOV: "Análisis de Entradas, Salidas y Reubicaciones en el depósito",

        TITLE_ACTIV_H: "Actividad última hora",
        H_TITLE_ACTIV_H: "Cantidad de movimientos en la última hora",

        TITLE_ACTIV_24: "Actividad últimas 24 horas",
        H_TITLE_ACTIV_24: "Cantidad de movimientos en las últimas 24 horas",

        TITLE_ACTIV_W: "Actividad últimos 7 días",
        H_TITLE_ACTIV_W: "Cantidad de movimientos en los últimos 7 días",

        TITLE_ACTIV_M: "Actividad último mes",
        H_TITLE_ACTIV_M: "Cantidad de movimientos en los últimos 30 días",

        ICON_TEXTS_E: "Entradas",
        ICON_TEXTS_S: "Salidas",
        ICON_TEXTS_R: "Reubic.",
        ICON_TEXTS_RJ: "Rechazos",


        TITLE_MOV_TABLE: "Seguimiento de Operaciones por pallets",
        SUBT_MOV_TABLE: "Control de todos los movimientos por Pallets, ingresos, salidas, reubicaciones, rechazos",

        TITLE_GRAP_M_M: "Registro de movimientos mensuales",
        SUBT_GRAP_M_M: "Evolución mensual de los movimientos de entrada, salida y reubicación",

        TITLE_GRAP_M_D: "Registro de movimientos diarios",
        SUBT_GRAP_M_D: "Movimientos diarios de entrada, salida y reubicación en 7 días",

        TITLE_GRAP_M_H: "Registro de movimientos por hora",
        SUBT_GRAP_M_H: "Análisis horario de movimientos en las últimas 24 horas",

        // ---------END DASHBOARD -----------------

        // ----------------------- ADMIN SKU --------------------
        COLUM_LABEL: "Descripción",
        COLUM_SKU: "SKU",
        ERROR_UPDATE: "Error al actualizar ",
        // ----------------------- END ADMIN SKU -------------------- 


        // -------------------- MACHINE LOGS TABLE ---------------
        COLUMN_USER: "Usuario",
        COLUMN_MACHINE: "Maquina",
        COLUMN_CODE: "Codigo",
        COLUMN_TYPE: "Tipo",
        COLUMN_TIME: "Fecha y hora",

        // -------------------- NAVBAR ---------------

        NAVBAR_NAME_ALMACEN: "Almacen",
        NAVBAR_NAME_TRANSELEVADOR: "Transelevador",
        NAVBAR_NAME_LINEAS: "Lineas",
        NAVBAR_NAME_LINEA_INGRESO: "Linea ingreso",
        NAVBAR_NAME_LINEA_SALIDA: "Linea salida",
        NAVBAR_NAME_LINEA_SISTEMAS: "Sistemas",
        NAVBAR_NAME_LINEA_DISPOSITIVOS: "Dispositivos",
        NAVBAR_NAME_LINEA_AJUSTES: "Ajustes",

        //--------------------------TUNTELNAVBAR-----------------------
        NAVBAR_NAME__TUNEL_AUTONOMO: "Autónomo",
        NAVBAR_NAME_TUNEL:"Túnel",
        NAVBAR_NAME_TUNEL_REFRIGERACION:"Refrigeración",
        NAVBAR_NAME_TUNEL_POSICIONES:"Posiciones",

        
        // --------------------------ORDEN DE DESCARGA ------------------

        
        DESCAGA_MENU_ORDER: "ORDEN DE DESCARGA",
        DESCARGA_MENU_GENERATE: "GENERAR ORDENES",
        DESCARGA_MENU_PENDIENTE: "PENDIENTE",
        DESCARGA_MENU_CONFIRM: "CONFIRMADAS",
        DESCARGA_MENU_HISTORIC: "HISTÓRICO",

        //---------------------------LISTA DE DESCARGA BOTONES -------------

        DESCARGA_BOTTONS_CONFIRM: "CONFIRMAR",
        DESCARGA_BOTTONS_DELETE: "ELIMINAR",
        DESCARGA_BOTTONS_OPTIMIZAR: "OPTIMIZAR ORDEN",
        DESCARGA_BOTTONS_CONFIRM_TOOLTIP: "Confirmar Orden Completa",
        DESCARGA_MODAL_TITLE_CONFIRM_ORDER: "Confirmar Orden",
        DESCARGA_BOTTONS_DELETE_TOOLTIP: "Eliminar Pallets Seleccionados",
        DESCARGA_BOTTONS_OPTIMIZAR_TOOLTIP: "Optimizar Orden, genera una extracción más rápida.",
        DESCARGA_BOTTONS_MODALCONFIRM: "La orden será confirmada para ejecutar. Tenga en cuenta que si el equipo se encuentra en modo autónomo comenzara el proceso de descarga inmediatamente se confirme.",

        //-----------------------------LISTA DE DESCARGA -----------------

        DATA_F_DES_POSICION: "Posición",
        DATA_F_DES_DEPOSITO: "deposito",
        DATA_F_DES_LPN: "LPN",
        DATA_F_DES_SKU: "SKU",
        DATA_F_DES_CLIENTE: "Cliente",
        DATA_F_DES_INGRESO: "Ingreso",
        DATA_F_DES_RETENCION: "Retención",
        DATA_F_DES_VENCIMIENTO: "Vencimiento",
        DATA_F_DES_BULTOS: "Bultos",
        DATA_F_DES_CONTENIDO: "Contenido",
        DATA_F_DES_PESO: "Peso Tomado",
        DATA_F_DES_PALLET: "Tipo de Pallet",
        DATA_F_DES_PRODUCTO: "Producto",


        //-----------------------------LISTA DE DESCARGA HISTORIAL -----------------
        
        
        DATA_F_DES_HIS_ORDEN: "N° de Orden",
        DATA_F_DES_HIS_STATE: "Estado",
        DATA_F_DES_HIS_ORIGIN: "Origen",
        DATA_F_DES_HIS_PRIORIDAD: "Prioridad",


        //-----------------------------LISTA DE DESCARGA ORDERS -----------------

        DESCARGA_ORDERS_LABEL_PRODUCT: "Producto",
        DESCARGA_ORDERS_LABEL_PALLETS: "Cantidad Pallets",
        DESCARGA_ORDERS_LABEL_CAJAS: "Cajas/Contenido",
        DESCARGA_ORDERS_BUTTON_ANADIR:"AÑADIR",
        DESCARGA_ORDERS_BUTTON_CONFIRM_TOOLTIP: "Enviar selección como orden pendiente",
        DESCARGA_ORDERS_BUTTON_DELETE_TOOLTIP: "Eliminar las lineas seleccionadas.",

        //-----------------------------LISTA DE DESCARGA PENDIENTES -----------------

        DESCARGA_PENDIENTE_PALLETSORDEN: "Pallets en Orden:",
        DESCARGA_PENDIENTE_MODAL_TITLE: "Confirmar descargas seleccionadas",
        DESCARGA_PENDIENTE_MODALMSG: "La orden será confirmada para ejecutar. Tenga en cuenta que si el equipo se encuentra en modo autónomo comenzara el proceso de descarga inmediatamente se confirme.",
        DESCARGA_PENDIENTE_CANCELMSG: "Desea cancelar las descargas 'pendientes' seleccionadas?",
        DESCARGA_PENDIENTE_CONFIRM_TOOLTIP: "Confirmar Orden Completa",
        DESCARGA_PENDIENTE_DELETE_TOOLTIP: "Cancelar ordenes",

        //-----------------------------LISTA DE DESCARGA CONFIRMADAS -----------------

        DESCARGA_CONFIRMADAS_BUTTON_CANCEL: "CANCELAR",
        DESCARGA_CONFIRMADAS_MODAL_TITLE: "Cancelar descargas seleccionadas",
        DESCARGA_CONFIRMADAS_MODAL_TEXT: "Desea cancelar las descargas seleccionadas?. Solo se cancelaran los pallets que se encuentren en estado 'en cola' en caso de existir uno en ejecución 'transito' va a descargase normalmente hasta finalizar la operación.",


        //-----------------------------CONFIGURACION DEL AUTONOMO -----------------

        CONFIG_AUTONOMO_REGLAS: "Reglas Generales",
        CONFIG_AUTONOMO_CONFIG_CARGAS: "Configuración de cargas",
        CONFIG_AUTONOMO_CONFIG_REGLAS: "Reglas generales de almacenamiento, agrupación en calles según propiedades agrupadas y toleracia.",
        CONFIG_AUTONOMO_TIPOPALLETS: "Tipo de pallet ingreso",
        CONFIG_AUTONOMO_DEPOSITO: "Deposito",
        CONFIG_AUTONOMO_TUNEL: "Tunel",
        CONFIG_AUTONOMO_MODALCONFIRM_TITLE: "Confirmar configuración",
        CONFIG_AUTONOMO_MODALCONFIRM_MSG: "Se aplicara la siguiente configuración para las proximas operaciones del Autónomo de carga y descarga.",
        CONFIG_AUTONOMO_DESCARGA: "Configuración Descarga",
        CONFIG_AUTONOMO_DESCARGA_TEXT: "Optimizar la descargas por cantidad de movimientos o FEFO estricto.",
        CONFIG_AUTONOMO_DESCARGA_PRIORIDAD: "Prioridad de Descarga",
        CONFIG_AUTONOMO_DESCARGA_LINEA: "Linea 2",
        CONFIG_AUTONOMO_DESCARGA_PROPIEDADES: "Propiedades",
        CONFIG_AUTONOMO_DESCARGA_GRUPOS: "Grupos generados por",
        CONFIG_AUTONOMO_DESCARGA_TOLERANCIA: "Tolerancia vencimiento días",

        //-----------------------------BUSQUEDA MODAL -----------------

        BUSQUEDA_MODAL_BUTTON_FILTRO: "Filtro especial",
        BUSQUEDA_MODAL_BUTTON_DESCARGA: "Lista de descarga",
        BUSQUEDA_MODAL_BUTTON_MSG: "Se añadieron los pallets seleccionados a la Orden de Descarga"



    }



}