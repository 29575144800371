import React, { useContext } from 'react'
import { ContextoAlmacen } from '../../contexts/Almacen/AlmacenContext'
import BarraLateral from '../Almacen/components/BarraLateral/BarraLateral'
import TabDescarga from './components/Tab/TabDescarga'
import styles from './Descarga.module.css'

const Descarga = () => {
  const { warehouse } = useContext(ContextoAlmacen)

  return (
    <div className={`${styles.container} ${warehouse.dark ? styles.containerDark : styles.containerWhite} `}>
      <div>
        <TabDescarga />
      </div>
      <BarraLateral all={false} />
    </div>
  )
}

export default Descarga
