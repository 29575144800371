import moment from "moment"
import decodeID from "../../../utils/decodeId"
import { retencionCalc } from "../../../utils/formatDate"

const translate_status = {
    cancel: "cancelado",
    null: "en cola",
    transit: "transito",
    complete: "completado",
    waiting: "espera",
    pending: "pendiente",
    "": "en cola"
}

class DownloadItem {
    constructor(position) {
        this.n_orden = position.dl_order
        this.prioridad_de_descarga = position.lu_dl_order
        this.estado = translate_status[position.status]
        this.data_origin = position.data_origin
        this.lpn = position.lpn
        this.sku = position.sku
        this.producto = position.description
        this.posicion = position.slot_id
        this.cliente = position.area
        this.cantidad = position.qty
        this.vencimiento = position.expiration
        this.ingreso = position.createdAt && moment(position.createdAt).format("YYYY-MM-DD HH:mm:ss")
        this.deposito = decodeID(position.slot_id).calle === 1 ? 'Tunel' : 'Deposito'
        this.retencion = retencionCalc(this.ingreso) || ''

    }
}

export const downloadAdapter = (list) => {
    return list.result.map(l => new DownloadItem(l))
}