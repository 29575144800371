import { useEffect, useState } from 'react'
import decodeID from '../../../../../utils/decodeId'

const useAsequiblePosition = ({ posiciones, _id, origen, nivel_actual }) => {
  const [asequible, setAsequible] = useState(true)
  const { lado, calle, nivel, prof } = decodeID(_id)
  const _idcut = `L${lado}C${calle}N${nivel}`

  useEffect(() => {
    if (posiciones) {
      for (let index = +prof; index > 1; index--) {
        if (index - 1 === 0) continue
        const posicion = posiciones[_idcut + 'P' + (index - 1)]
        if (origen === _idcut + 'P' + (index - 1)) {
          setAsequible(true)
          break
        }

        if (posicion?.estado === 1 || posicion?.estado === 2) {
          setAsequible(false)
          break
        }

        setAsequible(true)
      }
    } //eslint-disable-next-line
  }, [origen, nivel_actual, posiciones])

  return { asequible }
}

export default useAsequiblePosition
