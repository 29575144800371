import React, { useContext, useEffect, useMemo, useState } from 'react'
import { translate_table } from './Translate'
import { translate_table_pr } from './Translate_pr'
import styles from './Busqueda.module.css'
import MaterialReactTable from 'material-react-table'
import ColorPicker from '../../../../components/ColorPicker/ColorPicker'
import { Button, ButtonGroup } from '@mui/material'
import { DoneAll, FormatColorFill } from '@mui/icons-material'
import { ContextoData } from '../../../../contexts/Data/DataContext'
import { useDispatch, useSelector } from 'react-redux'
import { addCustomFilter, resetFilters } from '../../redux/slices/filters/filtersSlice'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { setListaDescarga } from '../../../Descarga/redux/slices/lista/listaDescargaSlice'
import ExportButtons from '../../../../components/ExportButtons/ExportButtons'
import {texts} from '../../../../i18n.js'

const BusquedaTable = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  
  let tableTranslate;

  const translations = {
    ES: translate_table,
    PT: translate_table_pr
  };
  
  tableTranslate = translations[lang] || '';
  // ------------- GET STATE POSICIONES ALMACEN ------------
  const posiciones = useSelector((state) => state.positions.positions)

  // --------------- CUSTOM FILTER ----------------------
  const { filter } = useSelector((state) => state.filter)
  const dispatch = useDispatch()
  // ------------------- SELECCION DE LA TABLA ------------------
  const initialSelected = filter?.array_data?.reduce((o, selected) => ({ ...o, [selected]: true }), {})
  const [rowSelection, setRowSelection] = useState(initialSelected || {})
  const [color, setColor] = useState(filter?.color_selected || '#ffffff')
  let data = posiciones !== null ? Object.values(posiciones).filter((row) => row.estado === 1 || row.estado === 2) : []

  // SETEAR LAS COLUMNAS DE LA TABLA PARA REPRESENTAR EN EL MODAL DE BUSQUEDA
  // --- Cada columna representa una propiedad del objeto a representar por row ---
  const { data_format } = useContext(ContextoData)
  const columns = useMemo(() => [...data_format], [data_format])
  const [columnVisibility, setColumnVisibility] = useState(
    localStorage.getItem('search_visibility') ? JSON.parse(localStorage.getItem('search_visibility')) : {} // SAVE VISIBILITY IN LOCAL STORAGE
  )

  useEffect(() => {
    localStorage.setItem('search_visibility', JSON.stringify(columnVisibility))
  }, [columnVisibility])

  // -------------- CUSTOM FILTER ---------------------
  const setCustomFilter = () => {
    if (filter?.type === 'custom_color') {
      dispatch(resetFilters())
    } else {
      dispatch(
        addCustomFilter({
          type: 'custom_color',
          array_data: Object.keys(rowSelection),
          color_selected: color,
        })
      )
    }
  }

  // ------------------ LISTA DE DESCARGA -----------------------
  const [success, setSuccess] = useState(false)
  const generarListaDeDescarga = () => {
    const selected = Object.keys(rowSelection).map((item) => posiciones[item])
    if (selected.length === 0) return
    dispatch(setListaDescarga({ lista: selected}))
    setRowSelection({})
    setSuccess(texts[lang]['BUSQUEDA_MODAL_BUTTON_MSG'])
    setTimeout(() => setSuccess(false), 3000)
  }

  return (
    <MaterialReactTable
      enableRowVirtualization
      enableFilterMatchHighlighting
      columns={columns}
      data={data}
      localization={tableTranslate}
      enableRowSelection
      getRowId={(row) => row.id}
      onRowSelectionChange={setRowSelection}
      state={{ rowSelection, columnVisibility }}
      onColumnVisibilityChange={setColumnVisibility}
      enableStickyHeader
      enableStickyFooter
      enableColumnResizing
      columnResizeMode='onChange' //default is "onEnd"
      initialState={{
        density: 'compact',
        pagination: {
          pageIndex: 0,
          pageSize: 50,
        },
      }}
      defaultColumn={{
        maxSize: 400,
        minSize: 80,
        size: 180, //default size is usually 180
      }}
      muiTableContainerProps={{ sx: { height: '65vh', backgroundColor: warehouse.dark ? '#292929' : '#fff' } }}
      muiTablePaperProps={{ sx: { boxShadow: 'none', backgroundColor: warehouse.dark ? '#292929' : '#fff' } }}
      muiToolbarAlertBannerProps={{
        sx: { color: warehouse?.dark === true && '#ffffff', backgroundColor: warehouse?.dark === true && '#356161' },
      }}
      renderBottomToolbarCustomActions={() => (
        <div className={styles.wrapper}>
          <div className={styles.colorPickerWrapper}>
            <ButtonGroup>
              <Button variant='contained' color={filter?.type === 'custom_color' ? 'active' : 'custom'}>
                <FormatColorFill style={{ color: color }}></FormatColorFill>
                <ColorPicker default_color={color} setColor={setColor} />
              </Button>
              <Button
                variant='contained'
                color={filter?.type === 'custom_color' ? 'active' : 'custom'}
                onClick={setCustomFilter}
              >
                {texts[lang]['BUSQUEDA_MODAL_BUTTON_FILTRO']}
              </Button>
            </ButtonGroup>
            <Button onClick={generarListaDeDescarga} variant='contained' color='custom'>
            {texts[lang]['BUSQUEDA_MODAL_BUTTON_DESCARGA']}
            </Button>
            {success && (
              <div className={styles.customAlert}>
                <DoneAll /> {success}
              </div>
            )}
          </div>
        </div>
      )}
      renderTopToolbarCustomActions={({ table }) => (
        <ExportButtons data={table.getFilteredRowModel().rows} title='Stock' columns={columns} />
      )}
    />
  )
}

export default BusquedaTable
