import React from 'react'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Box } from '@mui/material'
import { AddRoad, Info, RvHookup } from '@mui/icons-material'
import { tokens } from '../../../theme'
import moment from 'moment/moment'
import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext'

const Notificaciones = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const colors = tokens(warehouse.dark)
  const notifications = useSelector((state) => state.notifications.items)

  return (
    <Box
      icon={<Info fontSize='inherit' color='custom' />}
      sx={{
        color: warehouse.dark ? '#fff' : '',
        gap: '1rem',
        justifyContent: 'center',
      }}
    >
      <Box
        display='grid'
        gridTemplateColumns='1fr'
        sx={{
          gap: '1rem',
        }}
      >
        {notifications.map((item, key) => {
          const color = item.from === 'trans' ? '#ffb300' : '#3AA056'

          return (
            <Box sx={{ position: 'relative' }} key={key}>
              <Alert
                id={item.FechaHora}
                variant='outlined'
                sx={{
                  width: '100%',
                  backgroundColor: colors.grey[700],
                  color: colors.primary[100],
                  gridColumn: 'span 12',
                  borderColor: color,
                  fill: 'red',
                }}
                icon={
                  item.from === 'trans' ? (
                    <RvHookup fontSize='inherit' sx={{ color: color }} />
                  ) : (
                    <AddRoad fontSize='inherit' sx={{ color: color }} />
                  )
                }
              >
                <p style={{ paddingBottom: '0.4rem' }} dangerouslySetInnerHTML={{ __html: item?.msg }}></p>
                <p
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    padding: '0.2rem',
                    paddingRight: '0.7rem',
                    fontSize: '0.8rem',
                  }}
                >
                  {moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                </p>
              </Alert>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default Notificaciones
