import { Box, Button, CircularProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { green } from '@mui/material/colors'
const ButtonSpinner = ({ children, action = false ,type='custom' }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const timer = useRef()

  const buttonSx = {
    ...(success && {
      bgcolor:  'rgb(32, 130, 130)',
      '&:hover': {
        bgcolor: ' rgb(32, 130, 130)',
      },
    }),
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const handleButtonClick = (e) => {
    action() // FUNCION A EJECUTAR
    if (!loading) {
      setSuccess(false)
      setLoading(true)
      timer.current = window.setTimeout(() => {
        setSuccess(true)
        setLoading(false)
      }, 2000)
    }
  }

  return (
    <Box sx={{ m: 1, position: 'relative' }}>
      <Button variant='contained' sx={buttonSx} disabled={loading} color={type} onClick={handleButtonClick}>
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  )
}

export default ButtonSpinner
