import React, { useContext, useEffect, useMemo, useState } from 'react'
import MaterialReactTable from 'material-react-table'
import { ContextoData } from '../../../../../../contexts/Data/DataContext'
import { translate_table } from '../../../../../Almacen/components/Busqueda/Translate'
import { ContextoAlmacen } from '../../../../../../contexts/Almacen/AlmacenContext'
import ExportButtons from '../../../../../../components/ExportButtons/ExportButtons'

const TableMovements = ({ data }) => {
  const { data_format_movements } = useContext(ContextoData)
  const { warehouse } = useContext(ContextoAlmacen)
  const columns = useMemo(() => [...data_format_movements], [data_format_movements])
  // ------------ VISIBILITY COLUMN SAVE ---------------------------
  const [columnVisibility, setColumnVisibility] = useState(
    localStorage.getItem('movements_column_visibility')
      ? JSON.parse(localStorage.getItem('movements_column_visibility'))
      : {} // SAVE VISIBILITY IN LOCAL STORAGE
  )
  useEffect(() => {
    localStorage.setItem('movements_column_visibility', JSON.stringify(columnVisibility))
  }, [columnVisibility])
  // ------------------------- ******************** ---------------------
  return (
    <MaterialReactTable
      enableFilterMatchHighlighting
      columns={columns}
      data={data || []}
      localization={translate_table}
      muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
      muiTableContainerProps={{ sx: { backgroundColor: warehouse.dark ? '#292929' : '#fff' } }}
      muiToolbarAlertBannerProps={{
        sx: { color: warehouse?.dark === true && '#ffffff', backgroundColor: warehouse?.dark === true && '#356161' },
      }}
      initialState={{
        density: 'compact',
        sorting: [{ id: 'id', desc: true }],
        grouping: ['lpn'],
        expanded: true,
        pagination: {
          pageIndex: 0,
          pageSize: 50,
        },
      }}
      defaultColumn={{
        maxSize: 400,
        minSize: 80,
        size: 150, //default size is usually 180
      }}
      state={{ columnVisibility }}
      onColumnVisibilityChange={setColumnVisibility}
      enableGrouping
      enableColumnResizing
      enableStickyHeader
      enableStickyFooter
      columnResizeMode='onChange' //default is "onEnd"
      renderTopToolbarCustomActions={({ table }) => (
        <ExportButtons data={table.getFilteredRowModel().rows} title='Movimientos' />
      )}
    />
  )
}

export default TableMovements
