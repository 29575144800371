import { createSlice } from "@reduxjs/toolkit";

const gridViewSlice = createSlice({
    name: 'grid-view',
    initialState: {
        zoom:  localStorage.getItem('zoom') !== null ? JSON.parse(localStorage.getItem('zoom')) : 1,
        actualLevel: 1,
    },

    reducers: {
        zoomIn(state, action) {
            state.zoom = state.zoom + action.payload
            localStorage.setItem('zoom', state.zoom)
        },

        zoomOut(state, action) {
            state.zoom = state.zoom - action.payload
            localStorage.setItem('zoom', state.zoom)


        },
        zoomNone(state) {
            state.zoom = 1
            localStorage.setItem('zoom', state.zoom)

        },
        setLevel(state, action) {
            state.actualLevel = action.payload
        }
    }
})

export const { zoomIn, zoomOut, zoomNone, setLevel } = gridViewSlice.actions
export default gridViewSlice.reducer