import { historyAdapter } from "../adapters/adapters"
const SERVER_URL = process.env.REACT_APP_SERVER_URL
// +++++++++++++++++++++++++ HISTORICO MOVIMIENTOS  ++++++++++++++++++++++++++++++++++++++++++++++++++
export const getHistoryMovements = async () => {
    try {
        const response = await fetch(SERVER_URL + "/history/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return historyAdapter(result)
    } catch (e) {
        throw Error('No se puede obtener listado de movimientos')
    }
}

//Cantidad movimientos ultimas 24 horas
export const getStatsLineHours = async () => {
    try {
        const response = await fetch(SERVER_URL + "/stats/statsLineHours",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()

        return result.result;
    } catch (e) {
        throw Error('No se puede obtener listado de movimientos')
    }
}

//Cantidad movimientos ultima hora
export const getStatsLineLastHour = async () => {
    try {
        const response = await fetch(SERVER_URL + "/stats/statsLineLastHour",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result.result;
    } catch (e) {
        throw Error('No se puede obtener listado de movimientos')
    }
}

//Cantidad movimientos ultimos 7 dias
export const getStatsLineLastDays = async () => {
    try {
        const response = await fetch(SERVER_URL + "/stats/statsLineDays",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result.result;
    } catch (e) {
        throw Error('No se puede obtener listado de movimientos')
    }
}

//Cantidad movimientos ultimos 7 dias
export const getStatsLineLastDay = async () => {
    try {
        const response = await fetch(SERVER_URL + "/stats/statsLineLastDay",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result.result;
    } catch (e) {
        throw Error('No se puede obtener listado de movimientos')
    }
}

export const getStatsLineLastSevenDays = async () => {
    try {
        const response = await fetch(SERVER_URL + "/stats/statsLineLastSevenDays",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result.result;
    } catch (e) {
        throw Error('No se puede obtener listado de movimientos')
    }
}

export const getStatsLineLastMonth = async () => {
    try {
        const response = await fetch(SERVER_URL + "/stats/statsLineLastMonth",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })

        const result = await response.json()
        return result.result;
    } catch (e) {
        throw Error('No se puede obtener listado de movimientos')
    }
}




