import { createSlice } from "@reduxjs/toolkit";

const operationSlice = createSlice({
    name: 'operation',
    initialState: {
        modo_operacion: 'intercambio',
        origen: null,
        destino: null,
        pallet_ingreso: [],
        pallet_cuna: false,
    },
    reducers: {
        setModoOperacion(state, action) {
            state.modo_operacion = action.payload
        },
        setPalletCuna(state, action) {
            state.pallet_cuna = action.payload
        },
        setOrigen(state, action) {
            if (action.payload === state.origen) {
                state.origen = null
                return
            }
            state.origen = action.payload
        },
        setDestino(state, action) {
            if (action.payload === state.destino) {
                state.destino = null
                return
            }
            state.destino = action.payload
        },
        clearPositions(state) {
            state.origen = null
            state.destino = null
        },
        setPalletIngreso(state, action) {
            state.pallet_ingreso = [...state.pallet_ingreso.filter(p => p.transporte !== action.payload.transporte), action.payload]
        }
    }
})


export const { setOrigen, setDestino, setModoOperacion, clearPositions, setPalletIngreso, setPalletCuna } = operationSlice.actions
export default operationSlice.reducer