import styles from './PanelControl.module.css'
import ControlOperaciones from '../ControlOperaciones/ControlOperaciones'
import BarraLateral from '../BarraLateral/BarraLateral'
import Acordions from '../Acordions/Acordions'


const PanelControl = () => {

  return (
    <>
      <div className={styles.info}>
        <Acordions/>
        <ControlOperaciones />
      </div>
      <BarraLateral />
    </>
  )
}

export default PanelControl
