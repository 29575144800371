import React, { useContext, useEffect, useMemo, useState } from 'react'
import MaterialReactTable from 'material-react-table'
import { translate_table } from '../../../Almacen/components/Busqueda/Translate'
import { translate_table_pr } from '../../../Almacen/components/Busqueda/Translate_pr'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { ContextoData } from '../../../../contexts/Data/DataContext'
import { useDispatch, useSelector } from 'react-redux'
import { getHistoryOrdersThunk } from '../../redux/slices/lista/thunk/getConfirmOrdersThunk'
import ExportButtons from '../../../../components/ExportButtons/ExportButtons'

const HistoricoTable = () => {
  const { data_format_descarga_history } = useContext(ContextoData)
  const columns = useMemo(() => [...data_format_descarga_history], [data_format_descarga_history])
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  const dispatch = useDispatch()
  const data = useSelector((state) => state.lista_descarga.history.lista)

  let tableTranslate;

  const translations = {
    ES: translate_table,
    PT: translate_table_pr
  };
  
  tableTranslate = translations[lang] || '';

  // --------- GET POSITIONS -----------------
  useEffect(() => {
    dispatch(getHistoryOrdersThunk()) // eslint-disable-next-line
  }, [])

  // ------------ VISIBILITY COLUMN SAVE ---------------------------
  const [columnVisibility, setColumnVisibility] = useState(
    localStorage.getItem('download_history_column_visibility')
      ? JSON.parse(localStorage.getItem('download_history_column_visibility'))
      : {} // SAVE VISIBILITY IN LOCAL STORAGE
  )
  
  useEffect(() => {
    localStorage.setItem('download_history_column_visibility', JSON.stringify(columnVisibility))
  }, [columnVisibility])
  // ------------------------- ******************** ---------------------

  return (
    <MaterialReactTable
      columns={columns}
      data={data || []}
      localization={tableTranslate}
      positionExpandColumn='first'
      state={{ columnVisibility }}
      onColumnVisibilityChange={setColumnVisibility}
      muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
      muiTableContainerProps={{
        sx: { height: '60vh', backgroundColor: warehouse.dark ? '#292929' : '#fff', maxWidth: '95vw' },
      }}
      muiToolbarAlertBannerProps={{
        sx: { color: warehouse?.dark === true && '#ffffff', backgroundColor: warehouse?.dark === true && '#356161' },
      }}
      initialState={{
        density: 'compact',
        sorting: [
          { id: 'n_orden', desc: true },
          { id: 'prioridad_de_descarga', desc: false },
        ],
        grouping: ['n_orden'],
        pagination: {
          pageIndex: 0,
          pageSize: 50,
        },
      }}
      defaultColumn={{
        maxSize: 400,
        minSize: 80,
        size: 150, //default size is usually 180
      }}
      enableGrouping
      enableColumnResizing
      enableStickyHeader
      enableStickyFooter
      columnResizeMode='onChange' //default is "onEnd"
      renderTopToolbarCustomActions={({ table }) => (
        <ExportButtons data={table.getFilteredRowModel().rows} title='Historico' />
      )}
    />
  )
}

export default HistoricoTable
