import React from 'react'

import lineaing from '../../assets/imgs/AlmacenLineas.png'

const LineaIngreso = () => {
  return (
    <img src={lineaing} width="100%" height="100%" alt="linea-ing"/>
  )
}

export default LineaIngreso