import { createAsyncThunk } from "@reduxjs/toolkit"
import { getFilterData, getFilterDate } from "../../../../services/services"

// async thunk get data (filtro producto, marca etc. tipo clave_color)
export const getFilterDataThunk = createAsyncThunk('filter/getData',
    async (params) => {
        const { prop } = params
        return await getFilterData(prop)
    })
// async thunk get date (filtro vencimiento, elaboracion etc. tipo fecha)
export const getFilterDateThunk = createAsyncThunk('filter/getDate',
    async (params) => {
        const { prop } = params
        return await getFilterDate(prop)
    })