import React, { useState } from 'react'
import { useContext } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { ContextoData } from '../../../../contexts/Data/DataContext'
import styles from './InfoBox.module.css'
import { BiPackage } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { closeInfoBox } from '../../redux/slices/positionClickSlice/positionClickSlice'
import { Button, Stack } from '@mui/material'
import useSocket from '../../../../custom-hooks/useSocket'
import moment from 'moment'
import { texts } from '../../../../i18n'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
// import decodeID from '../../../../utils/decodeId'
// import { postTransferList } from '../../../Configuracion/services/services'
// import { setListaDescarga } from '../../../Descarga/redux/slices/lista/listaDescargaSlice'
// import { AddRoad, DoneAll, Download, Error, MoveDown } from '@mui/icons-material'

const InfoBox = () => {
  const info = useSelector((state) => state.positionClick.info)
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  // const posiciones = useSelector((state) => state.positions.positions)
  // const { destino } = useSelector((state) => state.operation)

  const { data_format } = useContext(ContextoData)
  const dispatch = useDispatch()
  // const [savedInList, setSavedInList] = useState(false)
  // const [savedInListStreet, setSavedInListStreet] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  // const [transferStatus, setTransferStatus] = useState(null)

  const socket = useSocket()

  // const sendToDownload = () => {
  //   dispatch(setListaDescarga({ lista: [info.content] }))
  //   setSavedInList(true)
  //   setTimeout(() => {
  //     setSavedInList(false)
  //   }, 3000)
  // }

  // const sortLCNandProf = (array) => {
  //   const sortedArray = array.sort((a, b) => {
  //     const { lado: la, nivel: na, calle: ca, prof: pa } = decodeID(a.id)
  //     const { lado: lb, nivel: nb, calle: cb, prof: pb } = decodeID(b.id)
  //     if (la === lb && na === nb && ca === cb) return pa - pb
  //     return 0
  //   })
  //   return sortedArray
  // }

  // const sendToTransferList = async () => {
  //   if (!destino) {
  //     setTransferStatus({ status: 'error', msg: 'Você deve selecionar a rua vazia de destino' })
  //     setTimeout(() => setTransferStatus(null), 2000)

  //     return
  //   }
  //   const decode = decodeID(info.content.id)
  //   const lista = sortLCNandProf(filterStreet(`L${decode.lado}C${decode.calle}N${decode.nivel}`))
  //   let { lado, calle, nivel, prof } = decodeID(destino)
  //   let aux = prof
  //   let listToTransfer = lista.map((p) => {
  //     if (aux === 0) return {}
  //     let ob = {
  //       slot_id_origin: p.id,
  //       slot_id_destiny: `L${lado}C${calle}N${nivel}P${aux}`,
  //     }

  //     aux--
  //     return ob
  //   })

  //   listToTransfer = listToTransfer.filter((l) => l.slot_id_destiny && l.slot_id_origin)

  //   try {
  //     await postTransferList(listToTransfer)
  //     setTransferStatus({ status: 'ok', msg: 'Transferência gerada com sucesso' })
  //     setTimeout(() => setTransferStatus(null), 2000)
  //     socket.emit('action:actualizar-socket')
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // const filterStreet = (id) => {
  //   const regex = new RegExp(`^${id}P\\d+`)
  //   const arr = Object.values(posiciones).filter((p) => regex.test(p?.id) && +p.estado === 1)
  //   return arr
  // }

  // const sendToDownloadStreet = () => {
  //   const decode = decodeID(info.content.id)
  //   const lista = filterStreet(`L${decode.lado}C${decode.calle}N${decode.nivel}`)
  //   dispatch(setListaDescarga({ lista }))
  //   setSavedInListStreet(true)
  //   setTimeout(() => {
  //     setSavedInListStreet(false)
  //   }, 3000)
  // }

  // INGRESO PALLETS LINEA 1
  const ingresarPallets = () => {
    setDisableButton(true)
    socket.emit('action:ingresar-pallets')
    setTimeout(() => {
      setDisableButton(false)
    }, 2000)
  }

  const reingresarPallets = () => {
    setDisableButton(true)
    socket.emit('action:reingresar-pallets')
    setTimeout(() => {
      setDisableButton(false)
    }, 2000)
  }

  const position_status = {
    0: texts[lang]['OFFC_INFO_P_L'],
    1: texts[lang]['OFFC_INFO_P_O'],
    2: texts[lang]['OFFC_INFO_P_B'],
    3: texts[lang]['OFFC_INFO_P_I'],
  }

  function statusBox(dataPosicion) {
    if (!dataPosicion) return

    if (dataPosicion.id === 'L1C7N1P1') {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {dataPosicion.estado === 1 && (
            <ul>
              <li>
                {texts[lang]['OFFC_INFO_P']}: {dataPosicion?.id}
              </li>
              <li>
                {texts[lang]['OFFC_INFO_E']}: {texts[lang]['OFFC_INFO_P_O']}
              </li>
              <li>
                {texts[lang]['OFFC_INFO_TP']}: {dataPosicion.tipo_celda}
              </li>
              {data_format.map((column, key) => (
                <li key={key}>
                  {column.header}: {dataPosicion[column.accessorKey]}
                </li>
              ))}
            </ul>
          )}
          <div
            style={{
              position: 'absolute',
              bottom: '50px',
              left: '50px',
            }}
          >
            <Stack
              direction={'column'}
              justifyContent='space-between'
              style={{ height: '-webkit-fill-available' }}
              gap={'1rem'}
            >
              <Button onClick={ingresarPallets} color='custom' variant='contained' disabled={disableButton}>
                {texts[lang]['OFFC_INFO_BUTTON_ING']}
              </Button>
              <Button onClick={reingresarPallets} color='custom' variant='contained' disabled={disableButton}>
                {texts[lang]['OFFC_INFO_BUTTON_REING']}
              </Button>
            </Stack>
          </div>
        </div>
      )
    }

    if (dataPosicion.estado === 1 || dataPosicion.estado === 2) {
      return (
        <Stack justifyContent='space-between' style={{ height: '-webkit-fill-available' }}>
          <ul>
            <li>
              {texts[lang]['OFFC_INFO_P']}: {dataPosicion?.id}
            </li>
            <li>
              {texts[lang]['OFFC_INFO_E']}: {position_status[dataPosicion.estado]}
            </li>
            <li>
              {texts[lang]['OFFC_INFO_TP']}: {dataPosicion.tipo_celda}
            </li>
            {data_format.map((column, key) => {
              return (
                <li key={key}>
                  {column.header}:{' '}
                  {column.accessorKey === 'vencimiento'
                    ? moment(dataPosicion[column.accessorKey]).format('DD-MM-YYYY')
                    : dataPosicion[column.accessorKey]}
                </li>
              )
            })}
          </ul>
          {/* <Stack direction='column'>
            <Stack gap={'1rem'} direction='row' justifyContent={'start'} alignItems='center' width={'100%'}>
              <Typography variant='h5' fontWeight='bold'>
              Ações :
              </Typography>
              <Tooltip title='Enviar a Descarga' placement='top-start' arrow>
                <IconButton onClick={sendToDownload} variant='contained' color={'custom'}>
                  <Download />
                </IconButton>
              </Tooltip>
              <Tooltip title='Descarga Calle' placement='top-start' arrow>
                <IconButton onClick={sendToDownloadStreet} variant='contained' color={'custom'}>
                  <AddRoad />
                </IconButton>
              </Tooltip>
              <Tooltip title='Transferir Calle' placement='top-start' arrow>
                <IconButton onClick={sendToTransferList} variant='contained' color={'custom'}>
                  <MoveDown />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack alignContent={'center'} padding='5px' height={'40px'}>
              {transferStatus && (
                <p style={{ padding: '5px' }}>
                  {transferStatus.status === 'error' ? <Error /> : <DoneAll />} {transferStatus.msg}
                </p>
              )}
              {savedInList && (
                <p style={{ padding: '5px' }}>
                  <DoneAll /> Enviado a lista de descarga
                </p>
              )}
              {savedInListStreet && (
                <p style={{ padding: '5px' }}>
                  <DoneAll /> Enviado calle completa
                </p>
              )}
            </Stack>
          </Stack> */}
        </Stack>
      )
    }

    return (
      <div>
        <p>
          {texts[lang]['OFFC_INFO_P']}: {dataPosicion?.id}
        </p>
        <p>
          {texts[lang]['OFFC_INFO_E']}: {position_status[dataPosicion.estado]}
        </p>
        <p>
          {texts[lang]['OFFC_INFO_TP']}: {dataPosicion.tipo_celda}
        </p>
      </div>
    )
  }

  const handleClose = () => {
    dispatch(closeInfoBox())
  }

  return (
    <>
      <Offcanvas
        show={info.display}
        backdrop={false}
        onHide={handleClose}
        placement='start'
        className={styles.canvas}
        enforceFocus={false}
      >
        <Offcanvas.Header closeVariant={'white'} closeButton>
          <Offcanvas.Title>
            <BiPackage /> {texts[lang]['TITLE_INFO']}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={styles.canvaBody}>{statusBox(info.content)}</Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default InfoBox
