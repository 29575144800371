import React from "react";
import transelevador from "../../assets/imgs/AlmacenTranselevador.png";

const TranselevadorPage = () => {
  return (
    <img src={transelevador} width="100%" height="100%" alt="Transelevador"/>
  )
};

export default TranselevadorPage;
