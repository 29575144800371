import { createSlice } from "@reduxjs/toolkit";
import decodeID from "../../../../../utils/decodeId";
import { getConfirmOrdersThunk, getHistoryOrdersThunk } from "./thunk/getConfirmOrdersThunk";


// --------- SORT BY LCN AND PROF ---------------------
const sortLCNandProf = (array) => {
    const sortedArray = array.sort((a, b) => {
        const { lado: la, nivel: na, calle: ca, prof: pa } = decodeID(a.id)
        const { lado: lb, nivel: nb, calle: cb, prof: pb } = decodeID(b.id)
        if (la === lb && na === nb && ca === cb) return pa - pb
        return 0
    })
    return sortedArray;
};


const listaDescargaSlice = createSlice({
    name: 'lista_descarga',
    initialState: {
        lista: localStorage.getItem('download_list') ? JSON.parse(localStorage.getItem('download_list')) : [],
        prev_list: localStorage.getItem('prev_list') ? JSON.parse(localStorage.getItem('prev_list')) : [],
        reubications: [],
        confirm_orders: {
            lista: [],
            reubications: [],
            loading: 'idle',
            currentRequestId: undefined,
            error: null,
        },
        history: {
            lista: [],
            loading: 'idle',
            currentRequestId: undefined,
            error: null,
        },
    },
    reducers: {
        setListaDescarga(state, action) {
            const { lista } = action.payload
            const list = [...state.lista, ...lista.filter((item) => !state.lista.map((i) => i.id).includes(item.id))]
            state.lista = sortLCNandProf(list)
            localStorage.setItem('download_list', JSON.stringify(state.lista))
        },

        sortPositions(state, action) {
            // -------- SORT BY DISTANCE -----------------
            state.lista.sort((a, b) => {
                const a_decod = decodeID(a.id)
                const b_decod = decodeID(b.id)
                const distance_a = Math.sqrt(Math.pow(a_decod.calle, 2) + Math.pow(a_decod.nivel, 2))
                const distance_b = Math.sqrt(Math.pow(b_decod.calle, 2) + Math.pow(b_decod.nivel, 2))
                return distance_a - distance_b
            })
            state.lista = sortLCNandProf(state.lista)
            localStorage.setItem('download_list', JSON.stringify(state.lista))
        },

        clearListaDescarga(state, action) {
            const { lista } = action.payload
            state.lista = [...state.lista.filter((item) => !lista.map((i) => i.id).includes(item.id))]
            localStorage.setItem('download_list', JSON.stringify(state.lista))
        },

        deleteItem(state, action) {
            const { lista } = action.payload
            const result = [...state.lista.filter(i => !lista.map(e => e.id).includes(i.id))]
            //pre sort by LCN and Prof
            state.lista = sortLCNandProf(result)
            localStorage.setItem('download_list', JSON.stringify(state.lista))
        },
        // Lista Previa de SKU , CANTIDAD DE PALLETS Y CANTIDAD DE CAJAS
        setPrevList(state, action) {
            const lista = action.payload
            state.prev_list = [...state.prev_list, ...lista.filter((item) => !state.prev_list.map((i) => i.sku).includes(item.sku))]
            localStorage.setItem('prev_list', JSON.stringify(state.prev_list))
        },

        deleteItemPrevList(state, action) {
            const lista = action.payload
            state.prev_list = [...state.prev_list.filter(i => !lista.map(e => e.sku).includes(i.sku))]
            //pre sort by LCN and Prof
            localStorage.setItem('prev_list', JSON.stringify(state.prev_list))
        },

    },
    // ------------------ EXTRA REDUCERS GET CONFIRM ORDERS --------------------
    extraReducers: builder => {
        //Get and Set Positions Object 
        builder.addCase(getConfirmOrdersThunk.fulfilled, (state, action) => {
            const { requestId } = action.meta
            const { lista } = action.payload
            if (state.confirm_orders.loading === 'pending' && state.confirm_orders.currentRequestId === requestId) {
                state.confirm_orders.loading = 'idle'
                // SEARCH ID IN POSITIONS AND ADD TO LISTA
                state.confirm_orders.lista = lista
                state.confirm_orders.currentRequestId = undefined
            }
        })

        builder.addCase(getConfirmOrdersThunk.pending, (state, action) => {
            if (state.confirm_orders.loading === 'idle') {
                state.confirm_orders.loading = 'pending'
                state.confirm_orders.currentRequestId = action.meta.requestId
            }
        })
        builder.addCase(getConfirmOrdersThunk.rejected, (state, action) => {
            const { requestId } = action.meta
            if (state.confirm_orders.loading === 'pending' && state.confirm_orders.currentRequestId === requestId) {
                state.confirm_orders.loading = 'idle'
                state.confirm_orders.error = action.error
                state.confirm_orders.currentRequestId = undefined
            }
        })

        //-------------------------- HISTORY ---------------------------------- 
        builder.addCase(getHistoryOrdersThunk.fulfilled, (state, action) => {
            const { requestId } = action.meta
            const { lista } = action.payload
            if (state.history.loading === 'pending' && state.history.currentRequestId === requestId) {
                state.history.loading = 'idle'
                state.history.lista = lista
                state.history.currentRequestId = undefined
            }
        })
        builder.addCase(getHistoryOrdersThunk.pending, (state, action) => {
            if (state.history.loading === 'idle') {
                state.history.loading = 'pending'
                state.history.currentRequestId = action.meta.requestId
            }
        })
        builder.addCase(getHistoryOrdersThunk.rejected, (state, action) => {
            const { requestId } = action.meta
            if (state.history.loading === 'pending' && state.history.currentRequestId === requestId) {
                state.history.loading = 'idle'
                state.history.error = action.error
                state.history.currentRequestId = undefined
            }
        })
    }
})


export const { setListaDescarga, sortPositions, clearListaDescarga, deleteItem, setPrevList, deleteItemPrevList } = listaDescargaSlice.actions
export default listaDescargaSlice.reducer




// GROUPS BY PROPERTY
// const generateGroupsbyProperty = (originalArray, groupTo) => {
//     const newArray = [...new Set(originalArray.map((a) => a[groupTo]))].map(
//         (groupName) => {
//             return {
//                 [groupTo]: groupName,
//                 items: originalArray.filter((i) => i[groupTo] === groupName)
//             };
//         }
//     );
//     return newArray;
// };



// // CALCULATE RELOCATIONS
// const calculeReubications = (data, positions) => {
//     const all_reubications = []
//     for (let index = 0; index < data.length; index++) {
//         const id = data[index].id
//         if (!id) continue
//         if (data[index].estado === 'cancelado') continue
//         if (!positions || !data) return []
//         const _idcut = id.slice(0, -2)
//         const prof = id.slice(-1)
//         if (prof === 1) return
//         // ------- RECORRE PROFUNDIDADES ------------
//         const reubications = []
//         for (let index = +prof; index > 1; index--) {
//             const posicion = positions[_idcut + 'P' + (index - 1)]
//             const include_in_list = data.find((item) => item.id === posicion.id)
//             const pre_pos_reubication = all_reubications.find(i => i.id.slice(0, -2) === posicion.id.slice(0, -2))
//             if (include_in_list || pre_pos_reubication) continue
//             if (posicion?.estado !== 0) {
//                 reubications.push(posicion.id)
//             }
//         }
//         if (reubications.length > 0) {
//             all_reubications.push({ id: id, reubications: reubications })
//         }
//     }
//     return all_reubications
// }

// // Add ID (Position by LPN)
// const addId = (data, positions) => {
//     for (let index = 0; index < data.length; index++) {
//         for (const key in positions) {
//             if (Object.hasOwnProperty.call(positions, key)) {
//                 const element = positions[key];
//                 if (element.lpn === data[index].lpn) {
//                     data[index].id = key
//                     break
//                 }
//             }
//         }
//     }
//     return data
// }

// const addReubications = (data, reubications) => {
//     return data.map(item => {
//         return {
//             ...item, reubications: reubications.filter(r => r.id === item.id).map(i => i.reubications)[0]?.length || 0
//         }
//     })
// }
