import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AlmacenProvider from './contexts/Almacen/AlmacenContext';
import { AppThemeMui } from './contexts/ThemeMui/AppThemeMui';
import { SocketProvider } from './contexts/Socket/SocketContext';
import DataContextProvider from './contexts/Data/DataContext';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import Almacen from './pages/Almacen/Almacen';
import Navbar from './components/Navbar/Navbar';
import { useRef } from 'react';
import AlertCtto2 from './components/AlertCtto/AlertCtto2';
import Descarga from './pages/Descarga/Descarga';
import Configuracion from './pages/Configuracion/Configuracion';
import Dashboard from './pages/Dashboard/Dashboard';
import AdminPosiciones from './pages/AdminPosiciones/AdminPosiciones';
import MachineLogs from './pages/MachineLogs/MachineLogs';
import TranselevadorPage from './pages/TranselevadorPage/TranselevadorPage';
import LineaIngreso from './pages/LineaIngreso/LineaIngreso';
import Dispositivos from './pages/Dispositivos/Dispositivos';
import Ajustes from './pages/Ajustes/Ajustes';
import TunelPageTunel from './pages/TunelPages/TunelPageTunel/TunelPageTunel';
import TunelPageCintaIngreso from './pages/TunelPages/TunelPageCintaIngreso/TunelPageCintaIngreso';
import TunelPageAutonomo from './pages/TunelPages/TunelPageAutonomo/TunelPageAutonomo';
import TunelPageCintaEgreso from './pages/TunelPages/TunelPageCintaEgreso/TunelPageCintaEgreso';
import TunelPageSistemas from './pages/TunelPages/TunelPageSistemas/TunelPageSistemas';
import TunelPageDispositivos from './pages/TunelPages/TunelPageDispositivos/TunelPageDispositivos';
import TunelPageAjustes from './pages/TunelPages/TunelPageAjustes/TunelPageAjustes';
import TunelPageRefrigeracion from './pages/TunelPages/TunelPageRefrigeracion/TunelPageRefrigeracion';
import TunelPagePosiciones from './pages/TunelPages/TunelPagePosiciones/TunelPagePosiciones';
import Devices from './pages/Devices/Devices';




function App() {
  const firstRender = useRef(true)

  return (
    <Provider store={store}>
      <SocketProvider>
        <BrowserRouter>
          <AlmacenProvider>
            <DataContextProvider>
              <AppThemeMui>
                <Navbar />
                <Routes>
                  
                  {/* RUTAS DEL ALMACEN */}
                  <Route path='/' element={<Almacen firstRender={firstRender} />} />
                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/descarga' element={<Descarga />} />
                  <Route path='/config' element={<Configuracion />} />
                  <Route path='/admin-pos' element={<AdminPosiciones />} />
                  <Route path='/machine' element={<MachineLogs />} />
                  <Route path='/transelevador' element={<TranselevadorPage />} />
                  <Route path='/lineas' element={<LineaIngreso />} />
                  <Route path='/dispositivos' element={<Devices />} />
                  <Route path='/hardware' element={<Dispositivos />} />
                  <Route path='/ajustes' element={<Ajustes />} />
                  {/* RUTAS DEL TUNEL */}
                  <Route path='/tunel-autonomo' element={<TunelPageAutonomo />} />
                  <Route path='/tunel' element={<TunelPageTunel />} />
                  <Route path='/tunel-cinta-ingreso' element={<TunelPageCintaIngreso />} />
                  <Route path='/tunel-cinta-egreso' element={<TunelPageCintaEgreso />} />
                  <Route path='/tunel-sistema' element={<TunelPageSistemas />} />
                  <Route path='/tunel-dispositivos' element={<TunelPageDispositivos />} />
                  <Route path='/tunel-ajustes' element={<TunelPageAjustes />} />
                  <Route path='/tunel-refrigeracion' element={<TunelPageRefrigeracion />} />
                  <Route path='/tunel-posiciones' element={<TunelPagePosiciones />} />

                </Routes>
                <AlertCtto2 />
              </AppThemeMui>
            </DataContextProvider>
          </ AlmacenProvider>
        </BrowserRouter>
      </SocketProvider>
    </Provider >
  );
}

export default App;