const controlFunction = (inputs, data) => {
  const control = inputs
    .map((input) => {
      if (data[input] === '' || !data[input]) return { input_name: input, pass: false }
      return { input_name: input, pass: true }
    })
    .filter((input) => !input.pass)
  if (control.length > 0) return { pass: false, in: control }
  return { pass: true }
}

export const formErrors = (data, exclude = null) => {
  let inputs = Object.keys(data)
  if (exclude) {
    inputs = inputs.filter((i) => !exclude.includes(i))
  }
  const control = controlFunction(inputs, data)
  if (!control.pass) {
    return {
      pass: false,
      msg: `Error hay campos sin completar:  ${control.in.map((m) => m.input_name).toString()}`,
      display: true,
      type: 'error',
    }
  } else {
    return { pass: true, msg: `Enviado Correctamente`, display: true, type: 'success' }
  }
}
