export const historyAdapter = (response) => {
    const translate = {

        save_in: 'Guardado',
        manual_in: "Guardado Manual",
        exit: 'Salida',
        manual_exit: "Salida Manual",
        relocation: 'Reubicacion',
        manual_relocation: 'Reubicacion Manual',
        rejected: 'Rechazo de pallet',
    }

    // 'pallet_created',
    // 'line_end',
    // 'save_in',
    // 'trans',
    // 'relocation',
    // 'exit',
    // 'manual_relocation',
    // 'manual_in',
    // 'rejected',
    // 'manual_exit',

    const data = response.Result.map(d => {
        return {
            ...d, updatedAt: d.updatedAt.slice(0, 10), operation: translate[d.operation]
        }
    })

    return data
}