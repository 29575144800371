import React from 'react'

import hardware from '../../assets/imgs/AlmacenHardware.png'

const Hardware = () => {
  return (
    <img src={hardware} width="100%" height="100%" alt="linea-ing"/>
  )
}

export default Hardware