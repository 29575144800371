import React from 'react'
import io from 'socket.io-client'

const socket_server = 'https://javier.ctto.com.ar'
const socket = io(socket_server, { transports: ['websocket'] })
export const SocketContext = React.createContext()

export const SocketProvider = (props) => {
  return <SocketContext.Provider value={socket}>{props.children}</SocketContext.Provider>
}
