import { ResponsiveLine } from '@nivo/line'
import React, { useContext, useEffect, useState } from 'react'
import { tokens } from '../../../theme'

import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext'
import { getStatsLineLastDays } from '../services/services'

const LineMovementsDays = () => {
  const agregarColorSegunMovimientoLine = (arr = [], campo) => {
    if (arr.length === 0) {
      return
    }
    let color
    arr.forEach((elemento) => {
      switch (elemento[campo]) {
        case 'entradas':
          color = colors.verdes[300]
          break

        case 'salidas':
          color = colors.verdes[200]
          break

        case 'reubicaciones':
          color = colors.blueAccent[300]
          break

        case 'rechazos' || 'rechazado':
          color = colors.redAccent[400]
          break
        case 'total':
          color = colors.verdes[400]
          break

        default:
          // console.log('agregarColorSegunMovimientoPie: no existe el elemento ' + arr.campo)
          break
      }

      elemento['color'] = color
    })

    return arr
  }

  const [datos, setDatos] = useState([])

  const { warehouse } = useContext(ContextoAlmacen)
  const colors = tokens(warehouse.dark)

  useEffect(() => {
    async function cargarDatos() {
      const data = await getStatsLineLastDays()
      setDatos(data)
    }

    cargarDatos()
  }, [])

  agregarColorSegunMovimientoLine(datos, 'id')

  return (
    <>
      <ResponsiveLine
        data={datos}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          reverse: false,
          stacked: false,
        }}
        enableArea={false}
        theme={{
          tooltip: {
            color: colors.grey[100],
            container: {
              background: colors.primary[900],
            },
          },
          crosshair: {
            line: {
              stroke: colors.primary[100],
            },
          },
          grid: {
            line: {
              stroke: warehouse.dark ? colors.grey[500] : colors.grey[900],
            },
          },
          legends: {
            text: {
              fill: colors.grey[100],
            },
          },
          axis: {
            ticks: {
              line: {
                stroke: '#777777',
                strokeWidth: 1,
              },
              text: {
                fill: colors.grey[100],
              },
            },
            legend: {
              text: {
                fill: colors.grey[100],
              },
            },
            domain: {
              line: {
                stroke: warehouse.dark ? colors.grey[500] : colors.grey[900],
                strokeWidth: 2,
              },
            },
          },
        }}
        enableSlices='x'
        colors={{ datum: 'color' }}
        yFormat=' >-.2f'
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'días',
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'cantidad',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        pointSize={9}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ theme: 'background' }}
        pointLabel='y'
        pointLabelYOffset={0}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 110,
            translateY: 12,
            itemsSpacing: 11,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            //symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </>
  )
}

export default LineMovementsDays
