import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import ListaDescargaTable from '../Table/ListaDescargaTable'
import ConfirmadasTable from '../Table/ConfirmadasTable'
import HistoricoTable from '../Table/HistoricoTable'
import CreateDownloadOrders from '../CreateDownloadOrders/CreateDownloadOrders'
import PendientesTable from '../Table/PendientesTable'
import { texts } from '../../../../i18n'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const TabDescarga = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='tablas' textColor='inherit'>
          <Tab label={texts[lang]['DESCAGA_MENU_ORDER']} {...a11yProps(0)} />
          <Tab label={texts[lang]['DESCARGA_MENU_GENERATE']} {...a11yProps(1)} />
          <Tab label={texts[lang]['DESCARGA_MENU_PENDIENTE']} {...a11yProps(2)} />
          <Tab label={texts[lang]['DESCARGA_MENU_CONFIRM']} {...a11yProps(3)} />
          <Tab label={texts[lang]['DESCARGA_MENU_HISTORIC']} {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ListaDescargaTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CreateDownloadOrders />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PendientesTable />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ConfirmadasTable />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <HistoricoTable />
      </TabPanel>
    </>
  )
}

export default TabDescarga
