import { returnDaysFromDate } from "./formatDate";

export const getRandomColor = (elementos) => {
    const ARRAY_COLORS = []
    for (let [i] of elementos.entries()) {
        const h = 360 / elementos.length * i;
        ARRAY_COLORS.push(`hsl(${h}deg, 76%, 61%)`);
    }
    return ARRAY_COLORS
};

export const createFilterFromProperty = (elementos) => {
    //------- CREAR UN CUSTOM VALUES PARA PODER ASIGNAR COLORES CUSTOMIZADOS ----------
    const custom = {
        'PATFOOD': 'red',
        'PALADINI': 'green'
    }

    const ARRAY_COLORS = getRandomColor(elementos)
    const PROPERTY_AND_COLORS = []
    for (let [i, item] of elementos.entries()) {
        let color
        if (Object.keys(custom).includes(item)) {
            color = custom[item]
        } else {
            color = ARRAY_COLORS[i]
        }

        PROPERTY_AND_COLORS.push({ color: `${color}`, valor: item })
    }
    return PROPERTY_AND_COLORS
}


export const colorAndDaysUpTo = (date, date_max) => {
    let diferencia_dias_hasta = returnDaysFromDate(date)
    let diferencia_max = returnDaysFromDate(date_max)
    if (diferencia_dias_hasta < 1) {
        let color = '#E55858'
        return [color, diferencia_dias_hasta]

    } else {
        let alpha = 1 - (diferencia_dias_hasta / diferencia_max)
        let color = `rgba(2, 5, 77, ${alpha < 0.1 ? 0.1 : alpha})`
        return [color, diferencia_dias_hasta]
    }

}

export const colorAndDaysFrom = (date, date_max) => {
    let diferencia_dias_desde = Math.abs(returnDaysFromDate(date))
    let diferencia_max = returnDaysFromDate(date_max)
    let alpha = Math.abs(diferencia_dias_desde / diferencia_max)
    let color = `rgba(82, 0, 101, ${alpha < 0.1 ? 0.1 : alpha})`
    return [color, diferencia_dias_desde]
}