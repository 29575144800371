import { createSlice } from '@reduxjs/toolkit';
import { getNotificationsThunk } from './thunks/notificationsThunks';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    items: [],
    count: 0,
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
  },

  extraReducers: builder => {
    //Get and Set Positions Object 
    builder.addCase(getNotificationsThunk.fulfilled, (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.items = action.payload
        state.count = state.items.filter(i => !i.view).length
        state.currentRequestId = undefined
      }
    })
    builder.addCase(getNotificationsThunk.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.currentRequestId = action.meta.requestId
      }
    })
    builder.addCase(getNotificationsThunk.rejected, (state, action) => {
      const { requestId } = action.meta
      if (state.loading === 'pending' && state.currentRequestId === requestId) {
        state.loading = 'idle'
        state.error = action.error
        state.currentRequestId = undefined
      }
    })
  }
})


export default notificationsSlice.reducer;