export const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_THEME': {
            localStorage.setItem('theme_dark', !state.warehouse.dark)
            return {
                ...state,
                warehouse: { ...state.warehouse, dark: !state.warehouse.dark }

            }
        }
        case 'SET_LANG': {
            const lang = action.payload
            localStorage.setItem('lang', lang)
            return {
                ...state,
                warehouse: { ...state.warehouse, lang: lang }

            }
        }
        default:
            return state
    }
}
