import React, { useContext, useEffect } from 'react'
import styles from './Transportador.module.css'
import { ReactComponent as CargaSup } from './cargaSup.svg'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { Alert, Snackbar, Tooltip } from '@mui/material'
import { useState } from 'react'
import useSocket from '../../../../custom-hooks/useSocket'
import { useDispatch, useSelector } from 'react-redux'
import { setEdit, setInfo } from '../../redux/slices/positionClickSlice/positionClickSlice'
import { setDestino, setOrigen } from '../../redux/slices/operation/operationSlice'

const Transportador = ({ lado, id, profundidad, name }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const posiciones = useSelector((state) => state.positions.positions)
  const operacion = useSelector((state) => state.operation)
  const { origen, destino } = operacion
  const dispatch = useDispatch()
  const [viewpop, setViewPop] = useState(false)

  //  Estado del Transporte, modo de Operacion
  const [modo, setModo] = useState('No disponible')
  const [listo, setListo] = useState(false)

  // Modificación del Transporte según layout
  const getDirection = () => {
    if (warehouse.orientacion === 'derecha') {
      if (+lado === 0) {
        return 'rotate(180deg)'
      }
    } else {
      if (+lado === 1) {
        return 'rotate(180deg)'
      }
    }
  }

  // -------------------------------- SOCKET ------------------------------------
  const socket = useSocket()
  useEffect(() => {
    socket.on(`state:pallet-${name}`, (status) => {
      setListo(status)
    })
    socket.on(`state:modo-${name}`, (status) => {
      setModo(status)
    })

    return () => {
      socket.off(`state:pallet-${name}`)
      socket.off(`state:modo-${name}`)
      // socket.off(`state:status-${name}`)
    }
    //eslint-disable-next-line
  }, [])

  // ---------------------- ************************* ---------------------------

  // ------------------ EVENTOS CLICK SOBRE TRANSPORTADOR -----------------------
  const handleClick = (event) => {
    if (event.shiftKey) {
      //console.log(modo, posiciones[id])
      if (modo === 'carga' && posiciones[id].estado === 1) {
        dispatch(setOrigen(id))
        return
      }
      if (modo === 'descarga' && posiciones[id].estado === 0) {
        dispatch(setDestino(id))
        return
      }
      setViewPop(true)
      return
    }
    if (event.ctrlKey) {
      dispatch(setEdit(posiciones[id] ? posiciones[id] : { id: id }))
      return
    }
    dispatch(setInfo(posiciones[id] || { id: id })) // Show info Box
  }
  // ---------------------- ************************* ---------------------------

  return (
    <>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'rgb(41 41 41 / 80%)',
              border: 3,
              borderColor: '#383838',
            },
          },
        }}
        title={
          <div className='tultip'>
            <p>Linea {name === 'T1' ? 'L1' : 'L2'}</p>
            <p>Modo de Linea: {modo}</p>
          </div>
        }
      >
        <div
          className={`${styles.transporte} ${warehouse.dark && styles.transporteDark}`}
          style={{
            transform: getDirection(),
            boxShadow: (id === origen || id === destino) && 'rgb(167 203 224) 0px 0px 0px 8px',
            height: 100 / profundidad + '%',
          }}
          _id={id}
          onClick={handleClick}
        >
          <CargaSup className={!listo ? styles.sinCarga : styles.palletListoCarga} />
        </div>
      </Tooltip>
      <Snackbar
        open={viewpop}
        autoHideDuration={5000}
        onClose={() => setViewPop(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity='warning' sx={{ width: '100%', userSelect: 'none' }}>
          No existe pallet disponible para ejecutar una operación
        </Alert>
      </Snackbar>
    </>
  )
}

export default Transportador
