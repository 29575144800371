import React, { useContext, useEffect, useMemo, useState } from 'react'
import MaterialReactTable from 'material-react-table'
import { ContextoData } from '../../../../contexts/Data/DataContext'
import { translate_table } from '../../../Almacen/components/Busqueda/Translate'
import { translate_table_pr } from '../../../Almacen/components/Busqueda/Translate_pr.js'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Tooltip } from '@mui/material'
import styles from './ListaDescargaTable.module.css'
import { Check, Delete, Refresh } from '@mui/icons-material'
import {
  clearListaDescarga,
  deleteItem,
  setListaDescarga,
  sortPositions,
} from '../../redux/slices/lista/listaDescargaSlice'
import { postDownloadOrder } from '../../services/services'
import ModalConfirm from '../../../../components/ModalConfirm/ModalConfirm'
import {texts} from '../../../../i18n.js'

const ListaDescargaTable = () => {
  const { data_format_descarga } = useContext(ContextoData)
  const { warehouse } = useContext(ContextoAlmacen)
  const dispatch = useDispatch()

  const { lang } = warehouse

  let tableTranslate;

  const translations = {
    ES: translate_table,
    PT: translate_table_pr
  };
  
  tableTranslate = translations[lang] || '';


  // --------------------- TABLE DATA,  OPTIONS AND COLUMN CONFIG -----------------------------
  const columns = useMemo(() => [...data_format_descarga], [data_format_descarga])
  const [rowSelection, setRowSelection] = useState({})
  const { lista: data } = useSelector((state) => state.lista_descarga)

  // ------------ VISIBILITY COLUMN SAVE ---------------------------
  const [columnVisibility, setColumnVisibility] = useState(
    localStorage.getItem('download_table_column_visibility')
      ? JSON.parse(localStorage.getItem('download_table_column_visibility'))
      : {} // SAVE VISIBILITY IN LOCAL STORAGE
  )
  useEffect(() => {
    localStorage.setItem('download_table_column_visibility', JSON.stringify(columnVisibility))
  }, [columnVisibility])

  // ----------- DELETE ITEMS ----------------------------
  const handleDelete = () => {
    const items = Object.keys(rowSelection).map((item) => data[item])
    if (items.length === 0) return
    dispatch(deleteItem({ lista: items }))
    setRowSelection({}) // RESTORE STATE
  }

  // ------------ CONFIRM LIST -----------------------
  // ----------------- MODAL CONFIRM -----------------
  const [open, setOpen] = useState(false)
  const [errorMsg, setError] = useState(false)

  const generarListaDeDescarga = async () => {
    const items = Object.keys(rowSelection).map((item) => data[item])
    if (items.length > 0) {
      const list = items.map((i, index) => {
        return {
          lu_dl_order: ++index,
          lpn: i.lpn,
        }
      })
      try {
        const response = await postDownloadOrder({ new_list: list })
        if (response.status === 'Success') {
          setError(false)
          setOpen(false)
          dispatch(clearListaDescarga({ lista: items }))
          setRowSelection({})
        }
        if (response.status === 'Error') {
          setError(response.error)
        }
      } catch (error) {
        setError(error.message)
      }
    }
    return
  }
  // ------------------- ************ ----------------

  const handleSort = () => {
    dispatch(sortPositions())
  }
  return (
    <MaterialReactTable
      columns={columns}
      data={data || []}
      localization= {tableTranslate}
      enableRowSelection
      state={{ rowSelection, columnVisibility }}
      onColumnVisibilityChange={setColumnVisibility}
      onRowSelectionChange={setRowSelection}
      muiTablePaperProps={{ sx: { boxShadow: 'none' } }}
      muiTableContainerProps={{
        sx: { height: '60vh', backgroundColor: warehouse.dark ? '#292929' : '#fff', maxWidth: '95vw' },
      }}
      muiToolbarAlertBannerProps={{
        sx: { color: warehouse?.dark === true && '#ffffff', backgroundColor: warehouse?.dark === true && '#356161' },
      }}
      initialState={{
        density: 'compact',
        pagination: {
          pageIndex: 0,
          pageSize: 50,
        },
      }}
      defaultColumn={{
        maxSize: 400,
        minSize: 80,
        size: 150, //default size is usually 180
      }}
      enableColumnResizing
      enableStickyHeader
      enableStickyFooter
      columnResizeMode='onChange' //default is "onEnd"
      // -------- SORTING ORDER PROPS --------------
      enableSorting={false}
      enableRowOrdering
      muiTableBodyRowDragHandleProps={({ table }) => ({
        onDragEnd: () => {
          const { draggingRow, hoveredRow } = table.getState()
          if (hoveredRow && draggingRow) {
            const copyArray = [...data]
            const drag_idcut = draggingRow.original.id.slice(0, -2)
            const indices = []
            const simils = copyArray.filter((item) => {
              if (item.id.slice(0, -2) === drag_idcut) {
                indices.push(item.id)
                return true
              }
              return false
            })
            // ---- ELIMINAR LOS INDICES QUE SE DESPLAZAN --------
            indices.forEach((i) => {
              copyArray.forEach((item, index) => {
                if (item.id === i) copyArray.splice(index, 1)
              })
            })
            // ----------- INSERTAR EN EL HOVER LO QUE SE DESPLAZA ---------
            copyArray.splice(hoveredRow.index, 0, ...simils)
            dispatch(setListaDescarga({ lista: copyArray }))
          }
        },
      })}
      // --------- ************* ----------------

      // --------------- RENDER FOOTER TABLE BUTTONS -----------------------
      renderBottomToolbarCustomActions={() => (
        <div>
          <div className={styles.wrapperButtons}>
            <Tooltip title={texts[lang]['DESCARGA_BOTTONS_CONFIRM_TOOLTIP']} arrow placement='top'>
              <Button onClick={() => setOpen(true)} variant='contained' color='custom' startIcon={<Check />}>
              {texts[lang]['DESCARGA_BOTTONS_CONFIRM']}
              </Button>
            </Tooltip>
            <Tooltip title={texts[lang]['DESCARGA_BOTTONS_DELETE_TOOLTIP']} arrow placement='top'>
              <Button onClick={handleDelete} variant='contained' color='error' startIcon={<Delete />}>
                {texts[lang]['DESCARGA_BOTTONS_DELETE']}
              </Button>
            </Tooltip>
            <Tooltip title={texts[lang]['DESCARGA_BOTTONS_OPTIMIZAR_TOOLTIP']} arrow placement='top'>
              <Button onClick={handleSort} variant='contained' color='primary' startIcon={<Refresh />}>
              {texts[lang]['DESCARGA_BOTTONS_OPTIMIZAR']}
              </Button>
            </Tooltip>
          </div>
          <ModalConfirm
            open={open}
            setOpen={setOpen}
            confirm={generarListaDeDescarga}
            errorMsg={errorMsg}
            title={texts[lang]['DESCARGA_MODAL_TITLE_CONFIRM_ORDER']}
            setErrorMsg={setError}
          >
            {texts[lang]['DESCARGA_BOTTONS_MODALCONFIRM']}
          </ModalConfirm>
        </div>
      )}
      // --------- ************* ----------------
    />
  )
}

export default ListaDescargaTable
