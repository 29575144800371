import { createContext, useReducer, useRef } from 'react'
import { reducer } from './AlmacenReducer'

export const ContextoAlmacen = createContext()

const AlmacenProvider = ({ children }) => {
  const INITIAL_STATE = {
    warehouse: {
      calles: 17,
      profundidades: 6,
      niveles: 9,
      transportador: [
        { calle: 7, lado: 1, nivel: 1, profundidad: 1, name: 'T1', id: 'L1C7N1P1' },
        { calle: 12, lado: 1, nivel: 1, profundidad: 1, name: 'T2', id: 'L1C12N1P1' },
      ],
      exclude: ['L1C7N1P1', 'L1C12N1P1'],
      orientacion: 'izquierda',
      dark: localStorage.getItem('theme_dark') !== null ? JSON.parse(localStorage.getItem('theme_dark')) : false,
      lang: localStorage.getItem('lang') !== null ? localStorage.getItem('lang') : 'ES',
    },
    transRef: useRef(),
  }

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const setTheme = () => {
    dispatch({ type: 'SET_THEME' })
  }

  const setLang = (value) => {
    dispatch({ type: 'SET_LANG', payload: value })
  }

  const STATE = {
    warehouse: { ...state.warehouse },
    transRef: state.transRef,
    setTheme,
    setLang,
  }

  return <ContextoAlmacen.Provider value={STATE}>{children}</ContextoAlmacen.Provider>
}

export default AlmacenProvider
