import React, { useContext, useState } from 'react';
import {Box, Typography} from "@mui/material";
import ProgressCircle from './ProgressCircle';
import {tokens} from "../../../theme"
import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext';

const StatBox = ({title, subtitle, icon, progress, increase, hoverText, isCapacidad = false}) => {
  const { warehouse } = useContext(ContextoAlmacen);
  const colors = tokens(warehouse.dark);

  const [showHoverText, setShowHoverText] = useState(false);

  const handleMouseEnter = () => setShowHoverText(true);
  const handleMouseLeave = () => setShowHoverText(false);

  
  let sign = "";
  let prc = "%";
  let estado = false;

  if (!isCapacidad){
    estado = true;
    if (increase > 0) {
        sign = "+"
    }
  } else {
    sign = ""
  } 

  return (
    <Box 
    width="100%" m="0 30px"
  
    >
        <Box display="flex" justifyContent="space-between" sx={{alignItems: 'center', justifyContent: 'center'}}>
            <Box style={{ flex:  4 }}>
                <Typography variant="h4" marginBottom=".5rem">
                    {icon} 
                </Typography>
                
                <Typography variant="h4" fontWeight="bold" sx={{color: colors.grey[100]}}>
                    {title}
                </Typography>
            </Box>

            <Box style={{ flex: estado ? 3 : 4 }} justifyContent="center">
                <ProgressCircle progress={progress}/>
            </Box>

            <Box 
            justifyContent="space-between"  
            style={{ flex: estado ? 5 : 4 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                position: 'relative'
              }}
            
            >
            {showHoverText && (
            <Typography
                sx={{
                    position: 'absolute',
                    bottom: '100%',
                    left: 0,
                    backgroundColor: colors.primary[900],
                    padding: '0.5rem',
                    borderRadius: '0.25rem',
                    fontSize: '1rem',
                    width: "15rem",
                    fontWeight: "200"
                }}
            >
                {hoverText}
            </Typography>
            )}
                <Typography variant="h5" fontWeight="bold" sx={{color: colors.verdes[300]}} marginBottom=".4rem">
                    {subtitle}
                </Typography>

                <Typography variant="h5" fontWeight="bold" fontStyle="italic" sx={{color: colors.verdes[300]}}>
                    {sign } {increase} {prc}
                </Typography>
            </Box>

        </Box>

    </Box>
  )
}

export default StatBox