import decodeID from "./decodeId";
import { generateProgrammingLanguageStats } from "@nivo/generators";


//PROPIEDADES DE NIVO (se utilizan para agregar contador de elementos en el pie)
export const commonProperties = {

  margin: { top: 80, right: 120, bottom: 80, left: 120 },
  data: generateProgrammingLanguageStats(true, 9).map(({ label, ...d }) => ({
    id: label,
    ...d,
  })),
  animate: true,
  activeOuterRadiusOffset: 8,
}
//PROPIEDADES DE NIVO

//Funcion para agrupar y contar registros, recibe el array y el campo de referencia
export const generateOptionGroups = (originalArray = [], groupTo = "") => {

  if (originalArray.length === 0) {
    return;
  }

  const newArray = [...new Set(originalArray.map((a) => a[groupTo]))].map(
    groupName => {
      return {
        id: groupName,
        value: originalArray.filter((i) => i[groupTo] === groupName).length,
      };
    }
  );
  return newArray;
};


//Funcion para agrupar y contar registros, recibe el array
export const generateOptionGroupsLevels = (arrayOriginal = []) => {
  if (arrayOriginal.length === 0) {
    return;
  }

  const arrayNuevo = Object.values(arrayOriginal.reduce((acumulador, elemento) => {
    const { nivel, estado } = elemento;

    // Si el grupo para este nivel no existe, lo creamos
    if (!acumulador[nivel]) {
      acumulador[nivel] = { nivel, Libre: 0, Ocupado: 0, Inhabilitado: 0 };
    }

    // Añadimos uno al contador correspondiente al valor de "estado"
    acumulador[nivel][estado]++;

    return acumulador;
  }, {}));

  return (arrayNuevo)
};


//Funcion para cambiar el formato del objeto clave-valor a objeto simple
export const cambiarFormato = (arr = []) => {

  if (arr === null) {
    return;
  }

  let nuevo_objeto = [];

  const position_status = {
    0: 'Libre',
    1: 'Ocupado',
    2: 'Bloqueado',
    3: 'Inhabilitado',
  }
  if (arr) {// eslint-disable-next-line 
    for (const [key, value] of Object.entries(arr)) {
      nuevo_objeto.push({
        "id": value?.id,
        "estado": position_status[value.estado],
        "lpn": value?.lpn,
        "cantidad": value?.cantidad,
        "sku": value?.sku,
        "producto": value?.producto,
      })
    }
  }
  return (nuevo_objeto);
}

//Funcion para cambiar el formato del array clave-valor a array simple, se utiliza para la barra de niveles
export const cambiarFormatoBar = (arr = []) => {

  let nuevo_objeto = [];
  let estadoDescr = "";

  if (arr) {// eslint-disable-next-line 
    for (const [key, value] of Object.entries(arr)) {
      //El nivel se encuentra en este caracter del id 
      let idProps = decodeID(value.id);
      let nivel = idProps.nivel;

      if (value.estado === 0) {
        estadoDescr = "Libre"
      }
      else if (value.estado === 1) {
        estadoDescr = "Ocupado"
      }
      else {
        estadoDescr = "Inhabilitado"
      }
      nuevo_objeto.push({
        "id": value.id,
        "nivel": ("Nivel " + nivel),
        "estado": estadoDescr,
        "lpn": value.lpn,
        "cantidad": value.cantidad,
        "sku": value.sku,
        "vencimiento": value.vencimiento,
        "producto": value.producto,
      })
    }
  }
  return (nuevo_objeto);
}


//Calcula la cantidad de productos en el deposito y los agrupa segun el sku, excluimos los elementos en estado 0
export const productosCantidades = (arr = []) => {

  if (arr.length === 0) {
    return;
  }

  let result = Object.values(arr.reduce((acumulador, { sku, producto, cantidad, estado }) => {
    if (sku) {
      acumulador[sku] = acumulador[sku] || { sku, unidades: 0, cantidad: 0 };
      acumulador[sku].unidades += cantidad;
      acumulador[sku].cantidad += 1;
      acumulador[sku].producto = producto;
    }
    return acumulador;
  }, {}));


  return result;
}

export const capacidadEstadistica = (posiciones = []) => {
  if (posiciones.length === 0) {
    return
  }

  const result = {};
  let ocupadas = 0;
  let libres = 0;
  let inhabilitadas = 0;
  const total = posiciones.length;


  posiciones.forEach(i => {
    if (i.estado === "Libre") {
      libres++
    }
    if (i.estado === "Ocupado") {
      ocupadas++
    }
    if (i.estado === "Inhabilitado") {
      inhabilitadas++
    }
  });
  result["posicionesLibre"] = libres;
  result["posicionesOcupadas"] = ocupadas;
  result["posicionesInhabilitadas"] = inhabilitadas;
  result["porcentajeLibre"] = Math.round((libres * 100 / total) * 10) / 10;
  result["porcentajeOcupado"] = Math.round((ocupadas * 100 / total) * 10) / 10;
  result["porcentajeInhabilitado"] = Math.round((inhabilitadas * 100 / total) * 10) / 10;
  result["progresoLibre"] = (libres * 100 / total / 100);
  result["progresoOcupado"] = (ocupadas * 100 / total / 100);
  result["progresoInhabilitado"] = (inhabilitadas * 100 / total / 100);

  return result;
}

export const agruparPorOperacionYMes = (movements = []) => {
  if (movements === 0) {
    return;
  }

  // Obtenemos la fecha actual
  const currentDate = new Date();

  // Creamos un objeto para almacenar los movimientos agrupados por mes y operación
  const groupedMovements = {
    entrada: [],
    salida: [],
    reubicaciones: [],
    rechazo: [],
    total: []
  };

  // Recorremos cada movimiento
  movements.forEach(movement => {
    // Obtenemos la fecha del movimiento
    const movementDate = new Date(movement.createdAt);

    // Obtenemos el mes del movimiento
    const month = movementDate.getMonth();

    // Si el mes está dentro de los últimos 12 meses
    if (movementDate > new Date(currentDate.setMonth(currentDate.getMonth() - 12))) {
      // Si la operacion ya está registrada 
      if (groupedMovements[movement.operation]) {
        //Si ya existe el mes en esta operacion, se incrementa el contador en uno
        if (groupedMovements[movement.operation][month]) {
          groupedMovements[movement.operation][month]++;
          groupedMovements.total[month]++;
        }
        //Si no existe, se crea el mes y se le asigna 1
        else {
          groupedMovements[movement.operation][month] = 1;
          if (!groupedMovements.total[month]) {
            groupedMovements.total[month] = 1;
          } else {
            groupedMovements.total[month]++;
          }

          // Agregamos el mes al conjunto de meses vistos
        }
      }
      //Si la operacion no existe, se la crea y se le asigna uno al mes actual
      else {
        groupedMovements[movement.operation] = {};
        groupedMovements[movement.operation][month] = 1;
        if (!groupedMovements.total[month]) {
          groupedMovements.total[month] = 1;
        } else {
          groupedMovements.total[month]++;
        }
      }
    }
  });

  const arrayConMeses = agregarMeses(groupedMovements)

  //ordenamos el array para que el elemento "total" este primero, para no desconfigurar el grafico, ya que este elemento contiene todos meses
  const compareById = (a, b) => {
    if (a.id === "total") {
      return -1;
    } else if (b.id === "total") {
      return 1;
    } else {
      return 0;
    }
  }

  const salida = arrayConMeses.sort(compareById)

  return (salida);
}

export const agregarMeses = (groupedMovements = {}) => {

  if (Object.entries(groupedMovements).length === 0) {
    return;
  }

  // Obtenemos la fecha actual
  const currentDate = new Date();

  // Creamos un objeto con los nombres de los meses en español
  const months = {
    0: "enero",
    1: "febrero",
    2: "marzo",
    3: "abril",
    4: "mayo",
    5: "junio",
    6: "julio",
    7: "agosto",
    8: "septiembre",
    9: "octubre",
    10: "noviembre",
    11: "diciembre"
  };

  // Creamos un array con los datos para la gráfica
  const data = [];

  //Array para los meses mayores al mes actual
  let lastYearData = [];
  let currentYearData = [];

  // Recorremos cada operación
  Object.keys(groupedMovements).forEach(operation => {
    const operationData = {
      id: operation,
      data: []
    };

    // Recorremos cada mes
    Object.keys(groupedMovements[operation]).forEach(monthKey => {
      // Añadimos los datos de cada mes al array, usamos la clave para acceder a cada valor
      const monthCount = groupedMovements[operation][monthKey];
      const isLastYear = monthKey > currentDate.getMonth();

      //si pertenece al año pasado
      if (isLastYear) {
        lastYearData.push({ monthKey, monthCount });
      } else {
        currentYearData.push({ monthKey, monthCount });
      }
    });
    //Se ordena el array del año pasado de forma ascendente
    lastYearData = lastYearData.sort((a, b) => a.monthKey - b.monthKey);

    //Agregamos los elementos de cada array al operationData.data, concatenando con el operado ...
    operationData.data = [
      ...lastYearData.map(({ monthKey, monthCount }) => ({ x: months[monthKey], y: monthCount })),
      ...currentYearData.map(({ monthKey, monthCount }) => ({ x: months[monthKey], y: monthCount })),
    ];

    // Añadimos los datos de la operación al array de datos
    data.push(operationData);

    //Limpiamos los arrays
    lastYearData = [];
    currentYearData = [];
  });

  return data;

}

//Personalizamos el idioma de la grilla
export const traduccciones = {
  columnMenuUnsort: "Desclasificar",
  columnMenuSortAsc: "Clasificar de forma ascendente",
  columnMenuSortDesc: "Clasificar de forma descendente",
  columnMenuFilter: "Filtrar",
  columnMenuHideColumn: "Ocultar",
  columnMenuManageColumns: "Administrar columnas",
  toolbarColumns: "Columnas",
  toolbarExport: "Exportar",
  toolbarExportCSV: "Exportar como CSV",
  toolbarExportPrint: "Imprimir",
  toolbarFilters: "Filtros",
  toolbarFiltersTooltipHide: "Ocultar filtros",
  toolbarFiltersTooltipShow: "Mostrar Filtros",
  toolbarDensity: "Altura Filas",
  toolbarDensityCompact: "Compacto",
  toolbarDensityStandard: "Estandar",
  toolbarDensityComfortable: "Confortable",
  columnsPanelShowAllButton: "Mostrar todo",
  columnsPanelHideAllButton: "Ocultar todo",
  columnsPanelTextFieldLabel: "Encontrar columna",
  filterPanelOperator: "Operación",
  filterPanelColumns: "Columnas",
  filterOperatorContains: "Contiene",
  filterOperatorEquals: "Es igual a",
  filterOperatorStartsWith: "Empieza con",
  filterOperatorEndsWith: "Termina con",
  filterOperatorIsEmpty: "Está vacío",
  filterOperatorIsNotEmpty: "No está vacío",
  filterOperatorIsAnyOf: "Cualquiera de",
  filterPanelDeleteIconLabel: "Borrar",
  filterPanelInputLabel: "Valor",
  filterPanelInputPlaceholder: "Filtrar Valor",
  groupingColumnHeaderName: 'Grupo',
  groupColumn: (name) => `Agrupar por ${name}`,
  unGroupColumn: (name) => `Dejar de agrupar por ${name}`,
};

