import React, { useContext, useState } from 'react'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { setLeft, setRight } from '../../redux/slices/configuracion/configuracionAutonomoSlices'
import {texts} from '../../../../i18n.js'

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

// TRANSFER LIST COMPONENT
export default function TransferList() {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  const config = useSelector((state) => state.configuracion_autonomo)
  const { active: right, inactive: left } = config
  const dispatch = useDispatch()

  const [checked, setChecked] = useState([])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    dispatch(setRight(right.concat(left)))
    dispatch(setLeft([]))
  }

  const handleCheckedRight = () => {
    dispatch(setRight(right.concat(leftChecked)))
    dispatch(setLeft(not(left, leftChecked)))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    dispatch(setLeft(left.concat(rightChecked)))
    dispatch(setRight(not(right, rightChecked)))
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    dispatch(setLeft(left.concat(right)))
    dispatch(setRight([]))
  }


  const customList = (items, title) => (
    <Paper
      sx={{
        width: '20vw',
        height: '15vw',
        overflow: 'auto',
        backgroundColor: warehouse.dark ? '#292929' : '#f5f5f5',
      }}
    >
      <div style={{ padding: '1rem', fontWeight: 300 }}>{title}</div>
      <List dense component='div' role='list'>
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.accesorKey}-label`

          return (
            <ListItem key={value.accessorKey} role='listitem' button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.header}`} />
            </ListItem>
          )
        })}
      </List>
    </Paper>
  )

  return (
    <Grid container spacing={2} justifyContent='space-between' alignItems='center'>
      <Grid item>{customList(left, texts[lang]['CONFIG_AUTONOMO_DESCARGA_PROPIEDADES'])}</Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button
            sx={{ my: 0.5 }}
            variant='contained'
            size='small'
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label='move all right'
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant='contained'
            size='small'
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label='move selected right'
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant='contained'
            size='small'
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label='move selected left'
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant='contained'
            size='small'
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label='move all left'
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right, texts[lang]['CONFIG_AUTONOMO_DESCARGA_GRUPOS'])}</Grid>
    </Grid>
  )
}
