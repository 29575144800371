import { Box } from '@mui/material'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { texts } from '../../../../i18n'
import Header from '../../components/Header'
import LineMovements from '../../components/LineMovements'
import LineMovementsDays from '../../components/LineMovementsDays'
import LineMovementsHour from '../../components/LineMovementsHour'

const Line = ({ tipo = 'month' }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  let grafico
  let header

  if (tipo === 'month') {
    grafico = <LineMovements />
    header = <Header title={texts[lang]['TITLE_GRAP_M_M']} subtitle={texts[lang]['SUBT_GRAP_M_M']} />
  } else if (tipo === 'week') {
    grafico = <LineMovementsDays />
    header = <Header title={texts[lang]['TITLE_GRAP_M_D']} subtitle={texts[lang]['SUBT_GRAP_M_D']} />
  } else {
    grafico = <LineMovementsHour />
    header = <Header title={texts[lang]['TITLE_GRAP_M_H']} subtitle={texts[lang]['SUBT_GRAP_M_H']} />
  }

  return (
    <Box m='20px'>
      {header}
      <Box height='500px'>{grafico}</Box>
    </Box>
  )
}

export default Line
