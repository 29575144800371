import React, {useContext} from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import MuiInput from '@mui/material/Input'
import { DateRange } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { setValueDays } from '../../redux/slices/configuracion/configuracionAutonomoSlices'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext.jsx'
import {texts} from '../../../../i18n.js'

const Input = styled(MuiInput)`
  width: 42px;
`

export default function DaysSwitch() {
  const { value } = useSelector((state) => state.configuracion_autonomo.date_props)
  const dispatch = useDispatch()
  const handleSliderChange = (event, newValue) => {
    dispatch(setValueDays(newValue))
  }

  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse

  const handleInputChange = (event) => {
    dispatch(setValueDays(event.target.value === '' ? '' : Number(event.target.value)))
  }

  const handleBlur = () => {
    if (value < 0) {
      dispatch(setValueDays(0))
    } else if (value > 100) {
      dispatch(setValueDays(100))
    }
  }

  return (
    <Box sx={{ width: 250 }}>
      <Typography id='input-slider' gutterBottom>
      {texts[lang]['CONFIG_AUTONOMO_DESCARGA_TOLERANCIA']}
      </Typography>
      <Grid container spacing={2} alignItems='center'>
        <Grid item>
          <DateRange />
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby='input-slider'
          />
        </Grid>
        <Grid item>
          <Input
            value={value}
            size='small'
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
