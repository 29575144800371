import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../contexts/Almacen/AlmacenContext'
import {texts} from '../../../src/i18n'

const ModalConfirm = ({
  open,
  setOpen,
  confirm = () => {},
  errorMsg = '',
  title = '',
  children,
  setErrorMsg = () => {},
}) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  const style = {
    color: warehouse.dark ? '#f5f5f5' : '#000',
    backgroundColor: warehouse.dark ? 'rgb(41 41 41)' : '#f5f5f5',
  }

  const handleConfirm = async () => {
    await confirm()
  }
  const handleClose = () => {
    setErrorMsg(false)
    setOpen(false)
  }
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='dialog-title'>
      <DialogTitle id='dialog-title' sx={style}>
        {title}
      </DialogTitle>
      <DialogContent sx={style}>
        <DialogContentText style={style}>{children}</DialogContentText>
      </DialogContent>
      <DialogActions sx={style}>
        <Button autoFocus onClick={handleClose} variant='outlined' color='error'>
          {texts[lang]['DESCARGA_BOTTONS_DELETE']}
        </Button>
        <Button onClick={handleConfirm} variant='outlined' color='custom'>
          {texts[lang]['DESCARGA_BOTTONS_CONFIRM']}
        </Button>
      </DialogActions>

      {errorMsg && <Alert severity='error'> {errorMsg} </Alert>}
    </Dialog>
  )
}

export default ModalConfirm
