import React, { useContext } from 'react'
import BusquedaTable from './BusquedaTable'
import { ContextoAlmacen } from '../../../../contexts/Almacen/AlmacenContext'
import { Dialog, DialogContent } from '@mui/material'

const BusquedaModal = ({ open, setOpen }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const handleClose = () => setOpen(false)
  const style = {
    color: warehouse.dark ? '#f5f5f5' : '#000',
    backgroundColor: warehouse.dark ? 'rgb(41 41 41)' : '#f5f5f5',
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='dialog-title'
      maxWidth='xl'
      sx={{ '.MuiDialogContent-root': { padding: '0' }, '.MuiDialog-paper': { marginTop: '90px' } }}
    >
      <DialogContent sx={style}>
        <BusquedaTable />
      </DialogContent>
    </Dialog>
  )
}

export default BusquedaModal
