import React from 'react'
import { useContext } from 'react';
import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext';
import { tokens } from '../../../theme';

const CenteredMetric = ({ dataWithArc, centerX, centerY, center }) => {

    const { warehouse } = useContext(ContextoAlmacen);
    const colors = tokens(warehouse.dark);
    
    let total = 0
    dataWithArc.forEach(datum => {
        total += datum.value
    })

  return (
     <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: '52px',
                fontWeight: 600,
                fill: colors.grey[100]
            }}
        >
            {total}
        </text>
  )
}

export default CenteredMetric