import { Typography, Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useContext } from "react";
import { ContextoAlmacen } from "../../../contexts/Almacen/AlmacenContext";

const Header = ({ title, subtitle }) => {
  const { warehouse } = useContext(ContextoAlmacen);
  const colors = tokens(warehouse.dark);


  return (
    <Box >
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        fontSize="30px"
        marginBottom="4px"
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.verdes[200]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;