import React, { useContext, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import ButtonSpinner from '../../../../../components/ButtonSpinner/ButtonSpinner'
import ErrorOrSuccessMsg from '../../../../../components/ErrorOrSuccessMsg/ErrorOrSuccesMsg'
import { ContextoAlmacen } from '../../../../../contexts/Almacen/AlmacenContext'
import { formErrors } from '../../../../../custom-hooks/useFormErrors'
import { texts } from '../../../../../i18n'
import { getPalletsThunk } from '../../../../AdminPosiciones/redux/slices/configuracion/thunk/getPalletsThunk'
import { closeEditBox } from '../../../redux/slices/positionClickSlice/positionClickSlice'
import { getPositionsThunk } from '../../../redux/slices/positions/thunk/positionsThunk'
import { putLoadUnit, putSlot } from '../../../services/services'
import styles from './EditPosicion.module.css'

const EditPosicion = ({ ...data }) => {
  const { warehouse } = useContext(ContextoAlmacen)
  const { lang } = warehouse
  const [errorProps, setErrorProps] = useState({})
  const dispatch = useDispatch()

  // ----------- FORM AND ERROR CONTROL ---------------
  const { register, reset, getValues } = useForm()

  // -------------------- SUBMIT FORM ------------------------------
  const submitForm = async (data_form) => {
    data_form.lpn = data.lpn
    // --------- CAMBIAR ESTADO SLOT ----------
    if (data_form.edit_slot) {
      const update_slot = await putSlot(data.id, data_form.edit_slot)
      if (update_slot.status === 'Success') {
        dispatch(getPositionsThunk()) // Pido posiciones actualizadas
        dispatch(getPalletsThunk())
        dispatch(closeEditBox())
      } else {
        setErrorProps({
          pass: false,
          msg: `Error: ${update_slot.message || ''}`, // Error en los datos enviados a la DB
          display: true,
          type: 'error',
        })
      }
      return
    }
    // --------- *********************** ----------

    // --------- MOVER UNIDAD DE CARGA ----------
    const control = formErrors(data_form, ['edit_slot'])
    if (!control.pass) {
      // Error en Campos
      setErrorProps(control)
      return
    }
    try {
      const loadUnit = await putLoadUnit(data_form, data_form.new_slot.toUpperCase())
      if (loadUnit.status === 'Success') {
        // Actualización Exitosa
        setErrorProps(control)
        reset()
        try {
          dispatch(getPositionsThunk()) // Pido posiciones actualizadas
          dispatch(getPalletsThunk())
          dispatch(closeEditBox())
        } catch (error) {
          // Error en DB al pedir nuevamente las posiciones
          setErrorProps({
            pass: false,
            msg: `Error: ${error}`,
            display: true,
            type: 'error',
          })
        }
      } else {
        setErrorProps({
          pass: false,
          msg: `Error: ${loadUnit.message || ''}`, // Error en los datos enviados a la DB
          display: true,
          type: 'error',
        })
      }
    } catch (e) {
      // Error en DB al Enviar la consulta
      setErrorProps({
        pass: false,
        msg: `Error: ${e.message} `,
        display: true,
        type: 'error',
      })
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    submitForm(getValues())
  }

  return (
    <div
      width='25vw'
      style={{ padding: '1rem' }}
      className={`${styles.canvasDescarga} ${warehouse.dark && styles.canvasDescargaDark}`}
    >
      <Form style={{ width: 'fit-content' }} onSubmit={submitHandler}>
        <Form.Group className='mb-3'>
          <Form.Label>{texts[lang]['EDIT_BOX_CAMBIAR_P']}</Form.Label>
          <InputGroup>
            <Form.Control
              aria-label='Posicion actual'
              aria-describedby='basic-addon2'
              required
              defaultValue={data.id}
              disabled={true}
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className='mb-3'>
          <Form.Label>{texts[lang]['EDIT_BOX_CAMBIAR_N']}</Form.Label>
          <InputGroup>
            <Form.Control
              placeholder='LCNP'
              aria-label='Nueva posicion'
              aria-describedby='basic-addon2'
              required
              {...register('new_slot')}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>{texts[lang]['EDIT_BOX_CAMBIAR_E']}</Form.Label>
          <InputGroup>
            <Form.Select {...register('edit_slot')} required>
              <option value=''>{texts[lang]['EDIT_BOX_CAMBIAR_SELECT']}</option>
              <option value='0'>{texts[lang]['OFFC_INFO_P_L']}</option>
              <option value='1'>{texts[lang]['OFFC_INFO_P_O']}</option>
              <option value='2'>{texts[lang]['OFFC_INFO_P_B']}</option>
              <option value='3'>{texts[lang]['OFFC_INFO_P_I']}</option>
            </Form.Select>
          </InputGroup>
        </Form.Group>

        <ButtonSpinner variant='contained' action={() => submitForm(getValues())}>
          {texts[lang]['EDIT_BOX_EDIT_G']}
        </ButtonSpinner>
      </Form>
      <ErrorOrSuccessMsg {...errorProps}></ErrorOrSuccessMsg>
    </div>
  )
}

export default EditPosicion
