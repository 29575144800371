import { ResponsivePie } from '@nivo/pie'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getPositionsThunk } from '../../Almacen/redux/slices/positions/thunk/positionsThunk'
import { generateOptionGroups, cambiarFormato, commonProperties } from '../../../utils/dashboard'
import { useContext } from 'react'
import { ContextoAlmacen } from '../../../contexts/Almacen/AlmacenContext'
import { tokens } from '../../../theme'
import CenteredMetric from './CenteredMetric'
import { Box } from '@mui/material'

const PieSlots = () => {
  const { warehouse } = useContext(ContextoAlmacen)
  const colors = tokens(warehouse.dark)
  //----------------- RESIZE OBSERVER ERROR DELETE (NOT PROD) ----------------------
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    })
  }, [])
  // ---------------------------------------------------------------------------------
  const agregarColorSegunEstadoPie = (arr = [], campo) => {
    if (arr.length === 0) {
      return
    }
    let color
    arr.forEach((elemento) => {
      switch (elemento[campo]) {
        case 'Libre':
          color = colors.verdes[300]
          break

        case 'Ocupado':
          color = colors.verdes[200]
          break

        case 'Inhabilitado':
          color = colors.verdes[100]
          break

        default:
          // console.log('agregarColorSegunEstado: no existe el elemento ' + arr.campo)
          break
      }

      elemento['color'] = color
    })

    return arr
  }

  const dispatch = useDispatch()

  const posiciones = useSelector((state) => state.positions.positions)
  //Convertimos el array clave-valor en array normal
  const posiciones2 = cambiarFormato(posiciones)
  //Formato necesario para el grafico
  let posicionesConvertidas = generateOptionGroups(posiciones2, 'estado')
  //Agregamos el campo color
  posicionesConvertidas = agregarColorSegunEstadoPie(posicionesConvertidas, 'id')
  useEffect(() => {
    dispatch(getPositionsThunk()) // eslint-disable-next-line
  }, [])

  return (
    <Box height='500px'>
      <ResponsivePie
        {...commonProperties}
        arcLinkLabel={(d) => `${d.id} (${d.formattedValue})`}
        activeInnerRadiusOffset={commonProperties.activeOuterRadiusOffset}
        innerRadius={0.8}
        data={posicionesConvertidas || []}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        activeOuterRadiusOffset={11}
        borderWidth={1}
        colors={(d) => d.data.color}
        colorBy='index'
        theme={{
          tooltip: {
            color: colors.grey[100],
            container: {
              background: colors.primary[900],
            },
          },
          legends: {
            text: {
              fill: colors.grey[100],
            },
          },
        }}
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        //enableArcLinkLabels={false}
        arcLinkLabelsTextColor={colors.grey[100]}
        arcLinkLabelsOffset={-12}
        arcLinkLabelsDiagonalLength={36}
        arcLinkLabelsStraightLength={32}
        arcLinkLabelsThickness={3}
        arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
        enableArcLabels={false}
        arcLabelsRadiusOffset={0.15}
        arcLabelsSkipAngle={7}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 1,
            translateY: 73,
            itemWidth: 147,
            itemHeight: 10,
            itemsSpacing: 0,
            symbolSize: 22,
            itemDirection: 'left-to-right',
          },
        ]}
      />
    </Box>
  )
}

export default PieSlots
