import { createSlice } from "@reduxjs/toolkit"


const segmentacionSlice = createSlice({
    name: 'segmentacion',
    initialState: {
        segmentos: [],
        groupBy: [{
            prop: 'cliente',
            values: ['Cliente A', 'Cliente B', 'Cliente C', 'Cliente D']
        }, {
            prop: 'producto',
            values: ['Producto A', 'Producto B', 'Producto C', 'Producto D']
        }, {
            prop: 'lote',
            values: ['Lote A', 'Lote B', 'Lote C', 'Lote D']
        }],
        colorSegment: '#f5f5f5',
    },
    reducers: {
        setColorSegment(state, action) {
            state.colorSegment = action.payload
        },
        setSegment(state, action) {
            state.segmentos = action.payload
        }
    }
})


export const { setColorSegment, setSegment } = segmentacionSlice.actions
export default segmentacionSlice.reducer