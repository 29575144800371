import React from 'react'

import devices from '../../assets/imgs/DispositivosAlmacen.png'

const Devices = () => {
  return (
    <img src={devices} width="100%" height="100%" alt="linea-ing"/>
  )
}

export default Devices