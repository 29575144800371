import styles from "./Navbar.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Almacen } from "././assets/almacen-icon.svg";
import { ReactComponent as Transelevador } from "././assets/transelevador-icon.svg";
import { ReactComponent as LineaIn } from "././assets/conveyor-icon.svg";
import { ReactComponent as LineaOut } from "././assets/conveyor-out-icon.svg";
import { ReactComponent as Sistems } from "././assets/sistems-icon.svg";
import { ReactComponent as Devices } from "././assets/devices-icon.svg";
import { ReactComponent as Settings } from "././assets/settings-icon.svg";
import { ReactComponent as Ctto } from "././assets/Ctto_LOGO-WHITE.svg";
import { ReactComponent as TunelAutonomoSvg } from "./assets/TunelAutonomo.svg";
import { ReactComponent as TunelSvg } from "./assets/Tunel.svg";
import { ReactComponent as TunelRefrigeracion } from "./assets/TunelRefrigeracion.svg";
import { ReactComponent as TunelDevices } from "./assets/Dispositivos.svg";
import { ReactComponent as TunelPosiciones } from "./assets/TunelPosiciones.svg";
import { useEffect, useState } from "react";
import { texts } from "../../i18n";
import { useContext } from "react";
import { ContextoAlmacen } from "../../contexts/Almacen/AlmacenContext";
import { FormControl, Select, MenuItem, Modal} from "@mui/material";
import banderaArgentina from "./assets/BanderaArgentina.jpg";
import banderaBrasil from "./assets/BanderaBrasil.jpg";
import banderaEeuu from "./assets/BanderaEeuu.jpg";

const Navbar = () => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/"); // eslint-disable-next-line
  }, []);

  const [text, setText] = useState("");
  const [switchAlmacen, setSwitchAlmacen] = useState(1);
  const { warehouse , setLang } = useContext(ContextoAlmacen);
  const { lang } = warehouse;
  const [modalOpen, setModalOpen] = useState(!localStorage.getItem("modalShow"));
  const [selectedLocation, setSelectedLocation] = useState("argentina");

  const activeTab = (path) => {
    if (location.pathname === path) {
      return { borderTop: "0.5rem solid #22B9B9" };
    }
  };

  useEffect(() => {
    const modalAlreadyShow = localStorage.getItem("modalShow");
    if (!modalAlreadyShow) {
      setModalOpen(true);
    }
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
    localStorage.setItem("modalShow", "true");
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    handleCloseModal();

    switch (location) {
      case "argentina":
        setLang('ES');
        break;
      case "brasil":
        setLang('PT');
        break;
      case "eeuu":
        setLang('ENG'); 
        break;
      default:
        setLang('ES');
        break;
    }

  };

  const navText = () => {
    switch (location.pathname) {
      case "/":
        setText("Almacen");
        break;
      case "/transelevador":
        setText("Transelevador");
        break;
      case "/lineas":
        setText("Lineas");
        break;
      case "/sistemas":
        setText("Sistemas");
        break;
      case "/dispositivos":
        setText("Dispositivos");
        break;
      case "/ajustes":
        setText("Ajustes");
        break;

      default:
        break;
    }
  };

  const handleChange = (event) => {
    setSwitchAlmacen(event.target.value);
    if (switchAlmacen === 2) {
      navigate("/");
    } else {
      navigate("/tunel-autonomo");
    }
  };

  useEffect(() => {
    navText(); // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <div>
      {/* Modal*/}
      <Modal open={modalOpen} onClose={handleCloseModal}>
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#424242",
      color: "#c1c1c1",
      padding: "20px",
      borderRadius: "8px",
      height: "30vh",
      width: "30vw"
    }}
  >
    <div
      style={{
        position: "absolute",
        top: "-5px",
        right: "0px",
        padding: "10px",
        cursor: "pointer",
      }}
      onClick={handleCloseModal}
    >
      X
    </div>
    <div
    style={{
      marginTop: "20px",
      marginBottom: "20px",
      textAlign: "center"
    }}>
      <h3>Bienvenido! Seleccione su país:</h3>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        marginTop: "55px"
      }}
    >
      <img
        src={banderaArgentina}
        alt="banderaArgentina"
        style={{ width: "150px", border: "2px solid black", height: "auto", cursor: "pointer" }}
        onClick={() => handleLocationSelect("argentina")}
      />
      <img
        src={banderaBrasil}
        alt="banderaBrasil"
        style={{ width: "150px", border: "2px solid black", height: "auto", cursor: "pointer" }}
        onClick={() => handleLocationSelect("brasil")}
      />
        <img
        src={banderaEeuu}
        alt="banderaEEUU"
        style={{ width: "150px", border: "2px solid black", height: "auto", cursor: "pointer" }}
        onClick={() => handleLocationSelect("eeuu")}
      />
    </div>
  </div>
</Modal>

      {(selectedLocation === "argentina" || selectedLocation === "eeuu") && localStorage.getItem("lang") !== "PT" ? (
        <div className={styles.sectionName}>
          <FormControl>
            <Select
              labelId="interfazSelectLabel"
              value={switchAlmacen}
              displayEmpty
              onChange={handleChange}
              sx={{
                width: "150px",
                height: "3.5vh",
                marginTop: "0.25vh",
                marginLeft: "0.5rem",
                border: "1px solid #c3c3c3",
                color: "#f3ffff",
              }}
            >
              <MenuItem value={1}>{texts[lang]["NAVBAR_NAME_ALMACEN"]}</MenuItem>
              <MenuItem value={2}>{texts[lang]["NAVBAR_NAME_TUNEL"]}</MenuItem>
            </Select>
          </FormControl>
        </div>
      ) : (
        <div className={styles.sectionName}> </div>
      )}

      <div>
        {switchAlmacen === 1 ? ( //Si estoy en modo almacen mostrar este menu
          <div className={styles.Navbar}>
            <Ctto className={styles.logo} />

            <Link to={"/"} className={styles.nameButton} style={activeTab("/")}>
              {texts[lang]["NAVBAR_NAME_ALMACEN"]}
              <Almacen className={styles.icon} />
            </Link>

            <Link
              to={"/transelevador"}
              className={styles.nameButton}
              style={activeTab("/transelevador")}
            >
              {texts[lang]["NAVBAR_NAME_TRANSELEVADOR"]}
              <Transelevador className={styles.icon} />
            </Link>

            <Link
              to={"/lineas"}
              className={styles.nameButton}
              style={activeTab("/lineas")}
            >
              {texts[lang]["NAVBAR_NAME_LINEAS"]}
              <LineaIn className={styles.icon} />
            </Link>

            <Link
              to={"/dispositivos"}
              className={styles.nameButton}
              style={activeTab("/dispositivos")}
            >
              {texts[lang]["NAVBAR_NAME_LINEA_DISPOSITIVOS"]}
              <TunelDevices className={styles.icon} />
            </Link>

            <Link
              to={"/ajustes"}
              className={styles.nameButton}
              style={activeTab("/ajustes")}
            >
              {texts[lang]["NAVBAR_NAME_LINEA_AJUSTES"]}
              <Settings className={styles.icon} />
            </Link>

            <Link
              to={"/hardware"}
              className={styles.nameButton}
              style={activeTab("/hardware")}
            >
              {"Hardware"}
              <Devices className={styles.icon} />
            </Link>
          </div>
        ) : (
          //Si estoy en modo tunel mostrar este menu
          <div className={styles.Navbar}>
            <Ctto className={styles.logo} />

            <Link
              to={"/tunel-autonomo"}
              className={styles.nameButton}
              style={activeTab("/tunel-autonomo")}
            >
              {texts[lang]["NAVBAR_NAME__TUNEL_AUTONOMO"]}
              <TunelAutonomoSvg className={styles.icon} />
            </Link>

            <Link
              to={"/tunel"}
              className={styles.nameButton}
              style={activeTab("/tunel")}
            >
              {texts[lang]["NAVBAR_NAME_TUNEL"]}
              <TunelSvg className={styles.icon} />
            </Link>

            <Link
              to={"tunel-cinta-ingreso"}
              className={styles.nameButton}
              style={activeTab("/tunel-cinta-ingreso")}
            >
              {texts[lang]["NAVBAR_NAME_LINEA_INGRESO"]}
              <LineaIn className={styles.icon} />
            </Link>

            <Link
              to={"/tunel-cinta-egreso"}
              className={styles.nameButton}
              style={activeTab("/tunel-cinta-egreso")}
            >
              {texts[lang]["NAVBAR_NAME_LINEA_SALIDA"]}
              <LineaOut className={styles.icon} />
            </Link>

            <Link
              to={"/tunel-sistema"}
              className={styles.nameButton}
              style={activeTab("/tunel-sistema")}
            >
              {texts[lang]["NAVBAR_NAME_LINEA_SISTEMAS"]}
              <Sistems className={styles.icon} />
            </Link>

            <Link
              to={"/tunel-dispositivos"}
              className={styles.nameButton}
              style={activeTab("/tunel-dispositivos")}
            >
              {texts[lang]["NAVBAR_NAME_LINEA_DISPOSITIVOS"]}
              <TunelDevices className={styles.icon} />
            </Link>

            <Link
              to={"/tunel-ajustes"}
              className={styles.nameButton}
              style={activeTab("/tunel-ajustes")}
            >
              {texts[lang]["NAVBAR_NAME_LINEA_AJUSTES"]}
              <Settings className={styles.icon} />
            </Link>

            <Link
              to={"/tunel-refrigeracion"}
              className={styles.nameButton}
              style={activeTab("/tunel-refrigeracion")}
            >
              {texts[lang]["NAVBAR_NAME_TUNEL_REFRIGERACION"]}
              <TunelRefrigeracion className={styles.icon} />
            </Link>

            <Link
              to={"/tunel-posiciones"}
              className={styles.nameButton}
              style={activeTab("/tunel-posiciones")}
            >
              {texts[lang]["NAVBAR_NAME_TUNEL_POSICIONES"]}
              <TunelPosiciones className={styles.icon} />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
