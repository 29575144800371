export const translate_table_pr = {
  actions: 'Ações',
  cancel: 'Cancelar',
  changeFilterMode: 'Alterar o modo de filtro',
  clearFilter: 'Limpar filtro',
  clearSearch: 'Limpar pesquisa',
  clearSort: 'Limpar pedido',
  columnActions: 'Ações de coluna',
  edit: 'Editar',
  expand: 'Expandir',
  expandAll: 'Expandir tudo',
  filterByColumn: 'Filtrar por {column}',
  filterMode: 'Modo de filtro: {filterType}',
  grab: 'Agarrar',
  groupByColumn: 'Grupo por {column}',
  groupedBy: 'Agrupados por',
  hideAll: 'Ocultar tudo',
  hideColumn: 'Ocultar coluna de {column}',
  rowActions: 'Ações de linha',
  pinToLeft: 'Alphile à esquerda',
  pinToRight: 'Alphile right',
  save: 'Salvar',
  search: 'Pesquisa',
  selectedCountOfRowCountRowsSelected:
    '{selectedCount} de {rowCount} fila(s) selecionado',
  showAll: 'Mostrar tudo',
  showAllColumns: 'Mostrar todas as colunas',
  showHideColumns: 'Mostrar/ocultar colunas',
  showHideFilters: 'Filtros alternativos',
  showHideSearch: 'Alternar pesquisa',
  sortByColumnAsc: 'Ordenar por {column} ascendente',
  sortByColumnDesc: 'Ordenar por {column} acessando',
  thenBy: ', ',
  toggleDensity: 'Enchimento denso alternativo',
  toggleFullScreen: 'Alternar tela cheia',
  toggleSelectAll: 'Selecionar tudo',
  toggleSelectRow: 'Selecionar linha',
  ungroupByColumn: 'Desagrupar por {column}',
  unpin: 'Remover o pino',
  unsorted: 'Não classificado',
  move: 'Mover',
  rowsPerPage: 'Linhas por página',
  noRecordsToDisplay: 'Nenhuma informação a ser exibida',
  noResultsFound: 'Nenhum resultado encontrado',
  dropToGroupBy: 'Liberação para o grupo por {column}'
}